import { useParams } from 'react-router-dom';
import { Box } from '@/components/Box/Box';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { Notification } from '@/components/Notification/Notification';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { ReactNode } from 'react';
import { PaymentDetailsContainer, Title, SourceWireHeader, SourceWireDivider } from './styles';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { hasIntermediaryPaymentDetails } from '@/helpers/paymentDetailsHelpers';
import { SourceWirePaymentDetailsIntermediaryBank } from './SourceWirePaymentDetailsIntermediaryBank';
import { AssignedWireType } from '@/types/wireTypes';
import { useUser } from '@/context/userContext';
import { getPaymentDetailsData } from '../PaymentDetailsData/helpers/helpers';
import { getRecipientWireDetailsPath } from '@/bundle/_Recipient/_Details/path/path';
import { getOutboundWireDetailsPath } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/path/path';

type SourceWirePaymentDetailsType = {
  paymentDetails: PaymentDetailsGeneralType;
  notification: ReactNode;
  wire: AssignedWireType;
  children?: ReactNode;
  actionSlot?: ReactNode;
};

export const SourceWirePaymentDetails = ({
  paymentDetails,
  notification,
  wire,
  children,
  actionSlot,
}: SourceWirePaymentDetailsType) => {
  const { isOrganizationAdminRole } = useUser();
  const { transactionId, id } = useParams<{ transactionId?: string; id: string }>();

  const hasIntermediaryBank = hasIntermediaryPaymentDetails(paymentDetails);
  const wireName = wire?.payment_details_source_wire?.name;

  const sourceWireDetailsUrl = isOrganizationAdminRole
    ? getOutboundWireDetailsPath(wire?.payment_details_source_wire?.contract?.id, wire?.payment_details_source_wire?.id)
    : getRecipientWireDetailsPath(wire?.payment_details_source_wire?.id);

  const sourceWireId = isOrganizationAdminRole ? { transactionId, id } : { id };

  const {
    recipientName,
    recipientAddress,
    accountName,
    bankName,
    bankAccountName,
    bankAddress,
    bankPhoneNumber,
    bankCodeLabel,
    bankCodeValue,
    dateCreated,
  } = getPaymentDetailsData(paymentDetails);

  return (
    <DetailsWidget header='Wire Info' action={actionSlot}>
      <Box mb='16px'>
        <Notification variant='warning' fontVariant='semibold'>
          {notification}
        </Notification>
      </Box>

      <PaymentDetailsContainer>
        <DetailsRow name={<Title>Blockchain ID</Title>}>-</DetailsRow>
        <DetailsRow name={<Title>Recipient</Title>}>{recipientName}</DetailsRow>
        <DetailsRow name={<Title>Recipient Address</Title>}>{recipientAddress}</DetailsRow>
        <DetailsRow name={<Title>Account Name</Title>}>{accountName}</DetailsRow>
        <DetailsRow name={<Title>{bankCodeLabel}</Title>}>{bankCodeValue}</DetailsRow>
        <DetailsRow name={<Title>Bank Name</Title>}>{bankName}</DetailsRow>
        <DetailsRow name={<Title>Account Number</Title>}>{bankAccountName}</DetailsRow>
        <DetailsRow name={<Title>Bank Address</Title>}>{bankAddress}</DetailsRow>
        <DetailsRow name={<Title>Bank Phone Number</Title>}>{bankPhoneNumber}</DetailsRow>

        {hasIntermediaryBank && <SourceWirePaymentDetailsIntermediaryBank paymentDetails={paymentDetails} />}
      </PaymentDetailsContainer>

      <SourceWireDivider />
      <Box>
        <SourceWireHeader>Source of Wire Info</SourceWireHeader>
        <DetailsRow name='Date Provided'>{dateCreated}</DetailsRow>
        <DetailsRow name='Provided for'>
          <NavigationLink to={sourceWireDetailsUrl} state={sourceWireId}>
            {wireName}
          </NavigationLink>
        </DetailsRow>
      </Box>
      {children}
    </DetailsWidget>
  );
};
