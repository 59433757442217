import { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { FormikValues } from 'formik/dist/types';
import { useQueryClient } from '@tanstack/react-query';
import { loadBankName } from '../api';

const INITIAL_BANK_VERIFICATION_STATE = {
  isVerifiedBank: false,
  isVerifiedIntermediaryBank: false,
  isDisabledBankName: true,
  isDisabledIntermediaryBankName: true,
};

export type BankVerificationType = typeof INITIAL_BANK_VERIFICATION_STATE;

export const useBankVerification = (
  formik: FormikProps<FormikValues>,
  isOpenIntermediary: boolean,
  isDomesticPayment: boolean
): BankVerificationType => {
  const queryClient = useQueryClient();

  const [bankVerificationState, setBankVerificationState] = useState(INITIAL_BANK_VERIFICATION_STATE);
  const { errors, values, setFieldValue } = formik;

  const updateBankVerificationState = (newState: Partial<BankVerificationType>) => {
    setBankVerificationState((state) => ({ ...state, ...newState }));
  };

  const loadBankNameByRoutingNumber = async (routingNumber: string) => {
    try {
      const response = await queryClient.fetchQuery({
        queryKey: ['load_bank_name', routingNumber],
        queryFn: () => loadBankName(routingNumber),
      });

      return response;
    } catch (error) {
      return null;
    }
  };

  const setVerifiedBankName = (routingNumber: string, isIntermediary: boolean) => {
    const bankNameFieldKey = isIntermediary ? 'intermediary_bank_name' : 'bank_name';
    const disabledBankStateKey = isIntermediary ? 'isDisabledIntermediaryBankName' : 'isDisabledBankName';
    const verifiedBankStateKey = isIntermediary ? 'isVerifiedIntermediaryBank' : 'isVerifiedBank';

    loadBankNameByRoutingNumber(routingNumber)
      .then((response) => {
        if (!response || response.error) {
          return;
        }

        const bankName = response.body?.[0]?.bank_name;

        if (bankName) {
          updateBankVerificationState({ [verifiedBankStateKey]: true });
          setFieldValue(bankNameFieldKey, bankName);
        } else {
          updateBankVerificationState({ [verifiedBankStateKey]: false, [disabledBankStateKey]: false });
        }
      })
      .catch(() => {
        updateBankVerificationState({ [disabledBankStateKey]: false });
      });
  };

  const validateRoutingNumber = (isIntermediary: boolean) => {
    const routingFieldKey = isIntermediary ? 'intermediary_bank_aba_number' : 'bank_aba_number';
    const confirmRoutingFieldKey = isIntermediary ? 'confirm_intermediary_bank_aba_number' : 'confirm_bank_aba_number';

    const hasRoutingNumberValues = !!values[routingFieldKey] && !!values[confirmRoutingFieldKey];
    const hasErrorsRoutingField = errors?.[routingFieldKey] || errors?.[confirmRoutingFieldKey];

    return hasRoutingNumberValues && !hasErrorsRoutingField;
  };

  useEffect(() => {
    if (!isDomesticPayment) {
      updateBankVerificationState({ isVerifiedBank: false, isDisabledBankName: false });

      return;
    }

    updateBankVerificationState({ isDisabledBankName: true });
    const isValidRoutingNumbers = validateRoutingNumber(false);

    if (isValidRoutingNumbers) {
      setVerifiedBankName(values.bank_aba_number, false);
    } else {
      queryClient.cancelQueries({ queryKey: ['load_bank_name'] });
      updateBankVerificationState({ isVerifiedBank: false });
      setFieldValue('bank_name', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors?.bank_aba_number, errors?.confirm_bank_aba_number, isDomesticPayment]);

  useEffect(() => {
    if (!isOpenIntermediary || !isDomesticPayment) {
      updateBankVerificationState({ isVerifiedIntermediaryBank: false, isDisabledIntermediaryBankName: false });

      return;
    }

    updateBankVerificationState({ isDisabledIntermediaryBankName: true });
    const isValidRoutingNumbers = validateRoutingNumber(true);

    if (isValidRoutingNumbers) {
      setVerifiedBankName(values.intermediary_bank_aba_number, true);
    } else {
      queryClient.cancelQueries({ queryKey: ['load_bank_name'] });
      updateBankVerificationState({ isVerifiedIntermediaryBank: false });
      setFieldValue('intermediary_bank_name', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errors?.intermediary_bank_aba_number,
    errors?.confirm_intermediary_bank_aba_number,
    isOpenIntermediary,
    isDomesticPayment,
  ]);

  // Set this fields to formik, since it's required for backend payload
  // Note: can't solve it other way(e.g. format in payload), since it's circular dependency between formik and this hook
  useEffect(() => {
    formik.setFieldValue('is_bank_name_verified', bankVerificationState.isVerifiedBank);
    formik.setFieldValue('is_intermediary_bank_name_verified', bankVerificationState.isVerifiedIntermediaryBank);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankVerificationState]);

  return bankVerificationState;
};
