import { ReactNode } from 'react';
import { Link } from './styles';

type ExternalLinkType = {
  children: ReactNode;
  href: string;
  target?: '_blank' | '_self';
  variant?: 'small';
};

export const ExternalLink = ({ children, href, target = '_self', variant }: ExternalLinkType) => {
  return (
    <Link href={href} target={target} rel='noreferrer' variant={variant}>
      {children}
    </Link>
  );
};
