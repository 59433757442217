import { AuthStep } from '@/bundle/Auth/ui/AuthStep/AuthStep';
import { Resend, ResendWrapper, TwoFALabel } from './styles';
import { Notification } from '@/components/Notification/Notification';
import { CODE_LENGTH, TwoFaCodeInput } from '../TwoFaCodeInput';
import { Timer } from '@/bundle/Auth/LoginFlow/TwoFAPage/ui/Timer/Timer';
import { Button } from '@/components/Button/Button';
import { FormEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { authTokenService } from '@/bundle/shared/services/authTokenService';
import { decodeToken } from '@/helpers/tokenHelpers';
import { AuthTokenType, ResponseErrorType } from '@/types/sharedTypes';
import { formatPhone } from '@/helpers/formatHelpers';
import { useNavigate } from 'react-router-dom';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { Loader } from '@/components/Loader/Loader';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { Box } from '@/components/Box/Box';
import { useTranslation } from 'react-i18next';

const INITIAL_CODE = Array(CODE_LENGTH).fill('');
const REQUEST_TIMER_DELAY = 59;

const convertSecondsToMinutes = (secondsTimer: number) => {
  const minutes = Math.floor(secondsTimer / 60);
  const seconds = secondsTimer % 60;

  return {
    seconds,
    minutes,
  };
};

type TwoFAFormType = {
  isSendCodeLoading: boolean;
  isTwoFALoading: boolean;
  onConfirm: (code: string) => void;
  onResend: () => void;
  twoFAError?: ResponseErrorType;
  sendCodeError?: ResponseErrorType;
};

export const TwoFAForm = forwardRef((props: TwoFAFormType, ref) => {
  const { isSendCodeLoading, isTwoFALoading, twoFAError, sendCodeError, onConfirm, onResend } = props;
  const navigate = useNavigate();
  const [code, setCode] = useState(INITIAL_CODE);
  const [phone, setPhone] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isDisabledSendCode, setIsDisabledSendCode] = useState(false);
  const [codeChanged, setCodeChanged] = useState(false);
  const { t } = useTranslation('login');

  useImperativeHandle(ref, () => {
    return {
      resetTimer(retryAfter: string | null) {
        const timerValue = retryAfter ? Number(retryAfter) : REQUEST_TIMER_DELAY;
        const { seconds: secondsValue, minutes: minutesValues } = convertSecondsToMinutes(timerValue);

        setMinutes(minutesValues);
        setSeconds(secondsValue);
        setIsDisabledSendCode(true);
      },

      resetCodeChanged() {
        setCodeChanged(false);
      },
    };
  }, []);

  const changeCode = (newCode: string[]) => {
    setCodeChanged(true);
    setCode(newCode);
  };

  const navigateToLogin = () => {
    navigate(getLoginPath());
  };

  const setUserPhone = () => {
    const tokens = authTokenService.getTokens();

    if (!tokens) return;

    const { mobile_phone } = decodeToken<AuthTokenType>(tokens.access);

    setPhone(formatPhone(mobile_phone));
  };

  useEffect(() => {
    setUserPhone();
    onResend();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (seconds === 0 && minutes === 0) {
      setIsDisabledSendCode(false);
    }
  }, [minutes, seconds]);

  const startTimer = () => {
    if (minutes === 0 && seconds === 0) {
      onResend();
    }

    setIsDisabledSendCode(true);
  };

  const getFormattedCode = () => {
    return code.join('');
  };

  const confirmTwoFA = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formattedCode = getFormattedCode();

    if (formattedCode.length !== CODE_LENGTH) {
      return;
    }

    onConfirm(formattedCode);
  };

  const isDisabledSubmit = getFormattedCode().length !== CODE_LENGTH;
  const apiError = (twoFAError?.error_content || sendCodeError?.error_content) as string;
  const hasInputError = !!twoFAError?.error_content && !codeChanged;

  return (
    <AuthStep width='508px'>
      <Typography variant='headline4' fontWeight='semibold' color={COLORS.grey[900]} mb='12px'>
        {t('login:2faHeader')}
      </Typography>
      <Typography color={COLORS.grey[950]}>{t('login:2faHeaderMessage')}</Typography>
      <Typography fontWeight='semibold' color={COLORS.grey[950]} mb='12px'>
        {phone}
      </Typography>
      <Typography color={COLORS.grey[600]} mb='14px'>
        {t('login:2faValidPhoneMessage')}
      </Typography>

      {apiError && (
        <Notification variant='error' mt='12px' mb='12px'>
          {apiError}
        </Notification>
      )}

      <form onSubmit={confirmTwoFA}>
        <Box position='relative' mb='64px'>
          <TwoFALabel>{t('login:2faInputLabel')}</TwoFALabel>
          <TwoFaCodeInput code={code} onChange={changeCode} hasError={hasInputError} />
          <ResendWrapper>
            {isDisabledSendCode && (
              <Timer minutes={minutes} setMinutes={setMinutes} seconds={seconds} setSeconds={setSeconds} />
            )}
            {isSendCodeLoading ? (
              <Loader inline background='transparent' />
            ) : (
              <Resend type='button' onClick={startTimer} isDisabled={isDisabledSendCode}>
                {t('login:2faResendButtonLabel')}
              </Resend>
            )}
          </ResendWrapper>
        </Box>
        <Box display='flex' flexDirection='column' rowGap='12px'>
          <Button type='submit' disabled={isDisabledSubmit} isLoading={isTwoFALoading}>
            {t('login:submitButtonLabel')}
          </Button>
          <Button variant='secondary' onClick={navigateToLogin}>
            {t('login:backButtonLabel')}
          </Button>
        </Box>
      </form>
    </AuthStep>
  );
});
