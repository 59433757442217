import { LinkWrapper } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getCreateDepositAccountPath } from '../../../DepositAccounts/_Create/path/path';

export const CreateNewAccountLink = () => {
  const navigate = useNavigate();
  const { transactionId, id } = useParams<{ transactionId: string; id: string }>();

  const navigateTo = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateNewDepositAccountRedirect);

    navigate(getCreateDepositAccountPath(), {
      state: {
        transactionId,
        wireId: id,
      },
    });
  };

  return <LinkWrapper onClick={navigateTo}>Create New Account?</LinkWrapper>;
};
