import { TrustStampResponseStatusType } from '@/api/v1/recipient/getRecipientTrustStampStatusApi';
import { AssignedWireType } from '@/types/wireTypes';
import { ReactNode, useEffect } from 'react';
import {
  isTrustStampFailedStatus,
  isTrustStampInProgressStatus,
  isTrustStampNotInitializedStatus,
  isTrustStampVerified,
  shouldShowDocumentVerification,
} from '../helpers';
import { DocumentVerificationStart } from '../DocumentVerificationStart/DocumentVerificationStart';
import { DocumentVerificationProgress } from '../DocumentVerificationProgress/DocumentVerificationProgress';
import { useMutation } from '@tanstack/react-query';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { initRecipientDocumentVerification } from '../../../api';
import { useTimer } from '@/hooks/useTimer';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { localStorageService } from '@/helpers/storageHelpers';
import { ProvidePaymentDetailsStateType } from '../../RecipientProvidePaymentDetails/helpers';
import { ChangeButton } from '../../RecipientProvidePaymentDetails/styles';
import { useUser } from '@/context/userContext';
import { EmailLink } from '@/components/EmailLink/EmailLink';

const TRUST_STAMP_FAILED_ERROR_HAS_SHOWN = 'TRUST_STAMP_FAILED_ERROR_HAS_SHOWN';

type DocumentVerificationPaymentDetailsGuardType = {
  wire: AssignedWireType;
  trustStampDetails: TrustStampResponseStatusType;
  children: ReactNode;
  isLoading: boolean;
  providePaymentDetailsState: ProvidePaymentDetailsStateType;
  onStartTrustStamp: () => void;
  onResetTrustStampNextAttemptTimer: () => void;
  onChangeProvidePaymentDetailsMode: () => void;
};

export const DocumentVerificationPaymentDetailsGuard = ({
  wire,
  trustStampDetails,
  children,
  isLoading,
  onStartTrustStamp,
  onResetTrustStampNextAttemptTimer,
  onChangeProvidePaymentDetailsMode,
}: DocumentVerificationPaymentDetailsGuardType) => {
  const { user } = useUser();
  const startNewSessionSeconds = trustStampDetails?.start_new_session_enable_after_sec;

  const { seconds } = useTimer(startNewSessionSeconds, onResetTrustStampNextAttemptTimer);

  const { mutate, isPending } = useMutation({
    mutationKey: ['init_document_verification'],
    mutationFn: () => {
      return initRecipientDocumentVerification(wire?.id);
    },
    onSuccess: (initIdVerificationResponse) => {
      if (initIdVerificationResponse?.error) {
        const errorMessage = getResponseError(initIdVerificationResponse?.error);

        showSnackbar(errorMessage, { variant: 'error' });

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.DocumentVerificationStartSuccess);

      onStartTrustStamp();
    },
    onSettled() {
      localStorageService.set(TRUST_STAMP_FAILED_ERROR_HAS_SHOWN, false);
    },
  });

  useEffect(() => {
    const hasErrorShown = localStorageService.get(TRUST_STAMP_FAILED_ERROR_HAS_SHOWN);

    if (hasErrorShown) return;

    // Show custom error when user failed document validation
    if (isTrustStampFailedStatus(trustStampDetails?.status)) {
      const errorMessage = (
        <>
          ID Verification Failed.
          <br />
          Your ID verification failed. Please contact <EmailLink email={wire?.created_by?.email} /> or start
          verification again to provide wire info for this wire.
        </>
      );

      localStorageService.set(TRUST_STAMP_FAILED_ERROR_HAS_SHOWN, true);
      showSnackbar(errorMessage, { variant: 'error', maxWidth: '390px' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustStampDetails?.status]);

  if (!wire) return null;

  const isDocumentVerificationRequired = wire.is_pd_provider_kyc_required;
  const isDocumentVerificationPassed = isTrustStampVerified(trustStampDetails);

  // When documentation verification is disabled or successfully passed - render the children w/o any condition
  if (!isDocumentVerificationRequired || isDocumentVerificationPassed) {
    return <>{children}</>;
  }

  const trustStampStatus = trustStampDetails?.status;
  const isTrustStampInProgress = isTrustStampInProgressStatus(trustStampStatus);
  const userPhone = wire?.assigned_user?.mobile_phone || wire?.assigned_proxy_user?.mobile_phone;
  const showDocumentVerification = shouldShowDocumentVerification(trustStampDetails, wire?.is_payment_details_exist);
  const isProxyUser = user.id === wire?.assigned_proxy_user?.id;
  const hasChangeButton = isProxyUser && isTrustStampNotInitializedStatus(trustStampDetails?.status);

  return (
    <div>
      {showDocumentVerification && (
        <DocumentVerificationStart
          seconds={seconds}
          userPhone={userPhone}
          actionSlot={
            hasChangeButton && <ChangeButton onClick={onChangeProvidePaymentDetailsMode}>Change</ChangeButton>
          }
          isLoading={isLoading || isPending}
          trustStampDetails={trustStampDetails}
          onStartTrustStamp={mutate}
        />
      )}
      {isTrustStampInProgress && (
        <DocumentVerificationProgress
          seconds={seconds}
          userPhone={userPhone}
          isLoading={isLoading || isPending}
          onStartTrustStamp={mutate}
        />
      )}
    </div>
  );
};
