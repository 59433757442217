import { Box } from '@/components/Box/Box';
import UserRoleIcon from './images/UserRoleIcon.svg?react';
import { RoleTitle } from './styles';
import { ReactNode } from 'react';

type UserRoleType = {
  role: string;
  icon?: ReactNode;
};

export const UserRole = ({ role, icon = <UserRoleIcon /> }: UserRoleType) => {
  return (
    <Box display='flex' columnGap='8px'>
      {icon}
      <RoleTitle>{role}</RoleTitle>
    </Box>
  );
};
