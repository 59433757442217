import { ContentLayout } from '../Layouts/ContentLayout/ContentLayout';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { PageBody } from '../../components/Page/PageBody/PageBody';
import { DepositorWiresList } from './ui/DepositorWiresList';
import { NoResults } from '../../components/NoResults/NoResults';
import { PageFooter } from '../../components/Page/PageFooter/PageFooter';
import { Paginator } from '../../components/Paginator/Paginator';
import NoTransactionsIcon from '../../images/NoTransactionsIcon.svg?react';
import { loadDepositorWires } from './api';
import { UpsellBanner } from '@/bundle/shared/components/UpsellBanner/UpsellBanner';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { useHasOrgAdminRole } from '@/bundle/shared/hooks/useHasOrgAdminRole';
import { Box } from '@/components/Box/Box';
import { SearchInput } from '@/bundle/shared/components/SearchInput/SearchInput';
import { NoSearchResults } from '../shared/components/NoSearchResults';
import { useSearch } from '../shared/hooks/useSearch';

export const DepositorWiresPage = () => {
  const { search, setSearch, clearSearch, searchParams, submitSearch } = useSearch();
  const { hasOrgAdminRole } = useHasOrgAdminRole();

  const { data, isPending } = useQuery({
    queryKey: ['load_depositor_wires', searchParams],
    queryFn: () => loadDepositorWires(searchParams),
    placeholderData: keepPreviousData,
  });

  const wires = data?.body?.results;
  const hasWires = !!wires?.length;
  const totalCount = data?.body?.count;
  const hasPaginator = totalCount > searchParams?.limit;
  const noResults = !hasWires && !isPending && !searchParams.name;
  const noSearchResults = !hasWires && !isPending && searchParams.name;

  const sendUpsellRequest = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.UpsellDepositorDashboard);
  };

  const handleSubmitSearch = () => {
    if (!!search.length && searchParams.name !== search) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.DepositorSearchSubmit);
    }

    submitSearch();
  };

  return (
    <ContentLayout
      title='Wires'
      subtitle='Please view the wires you have been assigned to. Select a wire to view more details.'
      banner={hasOrgAdminRole ? null : <UpsellBanner onSent={sendUpsellRequest} />}
      offsetSize='small'
    >
      <PageBody>
        <Box display='flex' alignItems='flex-start' columnGap='20px' flexWrap='wrap'>
          <Box width='360px'>
            <SearchInput
              placeholder='Search by wire name'
              value={search}
              onChange={(value) => setSearch(value)}
              onClear={clearSearch}
              onSubmit={handleSubmitSearch}
            />
          </Box>
        </Box>
        {hasWires && <DepositorWiresList list={wires} />}
        {noResults && <NoResults icon={<NoTransactionsIcon />} message='There is no wire yet.' />}
        {noSearchResults && <NoSearchResults />}
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams?.page} limit={searchParams?.limit} total={totalCount} />
        </PageFooter>
      )}
    </ContentLayout>
  );
};
