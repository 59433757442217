import { useState } from 'react';
import {
  UserDetailsCard,
  UserDetailsRow,
  UserDetailsRowTitle,
  UserDetailsRowValue,
} from '@/bundle/_Opco/shared/styles';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { formatPhone } from '@/helpers/formatHelpers';
import { UserRole } from '@/bundle/_Opco/shared/UserRole/UserRole';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { OpcoUserStatus } from '@/bundle/_Opco/_OpcoUsersPage/ui/OpcoUserStatus/OpcoUserStatus';
import { StatusDetailsRow } from './styles';
import EditIcon from '@/images/EditIcon.svg?react';
import { Drawer } from '@/components/Drawer/Drawer';
import { EditMobilePhoneForm } from '@/bundle/_Opco/ui/OrganizationUsers/Details/ui/OrganizationUserAccountDetails/EditMobilePhoneForm';
import {
  ORGANIZATION_USER_ROLE_ICON_MAP,
  ORGANIZATION_USER_STATUS,
  ORGANIZATION_USER_ROLE_LABEL_MAP,
} from '../../../const/const';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  blockOrganizationUser,
  reinviteOrganizationUser,
  unblockOrganizationUser,
} from '@/bundle/_Opco/ui/OrganizationUsers/Details/api';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import {
  PIN_TOKEN_EVENT_SCOPE,
  PinTokenEventScopeType,
  UPDATE_USER_MOBILE_PHONE_NOTIFICATION,
  UPDATE_USER_ROLE_NOTIFICATION,
} from '@/const/shared';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { IconButton } from '@/components/IconButton/IconButton';
import { EditUserRoleForm } from './EditUserRoleForm';
import { CANNOT_BLOCK_LAST_USER_ERROR_MESSAGE } from './const/const';
import { CannotModifyLastUserErrorModal } from './CannotModifyLastUserModalError';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { formatDateTimeUTC, formatSecondsToMinutes } from '@/helpers/dateHelpers/dateHelpers';
import { ReelAnimateTime } from '@/bundle/_Recipient/_Details/ui/RecipientDocumentVerification/ReelAnimateTime/ReelAnimateTime';
import { useTimer } from '@/hooks/useTimer';

type OrganizationUserAccountDetailsType = {
  user: OrganizationUserType;
  onRefetchUser: () => void;
};

export const OrganizationUserAccountDetails = ({ user, onRefetchUser }: OrganizationUserAccountDetailsType) => {
  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();

  const [isEditUserRoleOpen, setIsEditUserRoleOpen] = useState(false);
  const [isEditMobilePhoneOpen, setIsEditMobilePhoneOpen] = useState(false);
  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [isUnblockOpen, setIsUnblockOpen] = useState(false);
  const [isResendInvitationOpen, setIsResendInvitationOpen] = useState(false);
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);
  const [eventScope, setEventScope] = useState<PinTokenEventScopeType>(null);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  const openResendInvitation = () => setIsResendInvitationOpen(true);
  const closeResendInvitation = () => setIsResendInvitationOpen(false);

  const { mutate: blockUserMutate, isPending: isPendingBlockUser } = useMutation({
    mutationKey: ['block_organization_user', organizationId, userId],
    mutationFn: (pinCode: string) => {
      return blockOrganizationUser(organizationId, userId, pinCode);
    },
    onSuccess: (blockedUserResponse) => {
      if (blockedUserResponse?.error) {
        const error = getResponseError(blockedUserResponse?.error);

        if (error === CANNOT_BLOCK_LAST_USER_ERROR_MESSAGE) {
          setIsOpenErrorModal(true);

          return;
        }

        showSnackbar(error, { variant: 'error' });

        return;
      }

      showSnackbar('User account has been blocked.');
      onRefetchUser();
    },
  });

  const { mutate: unblockUserMutate, isPending: isPendingUnblockUser } = useMutation({
    mutationKey: ['unblock_organization_user', organizationId, userId],
    mutationFn: (pinCode: string) => {
      return unblockOrganizationUser(organizationId, userId, pinCode);
    },
    onSuccess: (unblockUserResponse) => {
      if (unblockUserResponse?.error) {
        const error = getResponseError(unblockUserResponse?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      showSnackbar('User account has been unblocked.');
      onRefetchUser();
    },
  });

  const { mutate: reinviteOrganizationMutate } = useMutation({
    mutationKey: ['reinvite_organization_user', organizationId, userId],
    mutationFn: () => {
      return reinviteOrganizationUser(organizationId, userId);
    },
    onSuccess: (reinvitedExecutorResponse) => {
      if (reinvitedExecutorResponse?.error) {
        const error = getResponseError(reinvitedExecutorResponse?.error);

        showSnackbar(error, { variant: 'error' });
        onRefetchUser();

        return closeResendInvitation();
      }

      onRefetchUser();
      closeResendInvitation();
    },
  });

  const openEditMobilePhone = () => setIsEditMobilePhoneOpen(true);
  const closeEditMobilePhone = () => setIsEditMobilePhoneOpen(false);

  const openBlock = () => setIsBlockOpen(true);
  const closeBlock = () => setIsBlockOpen(false);

  const openUnblock = () => setIsUnblockOpen(true);
  const closeUnblock = () => setIsUnblockOpen(false);

  const closeEditUserRole = () => setIsEditUserRoleOpen(false);

  const openBlockUserPinCode = () => {
    setEventScope(PIN_TOKEN_EVENT_SCOPE.BLOCK_ORG_ADMIN);
    closeBlock();
    setIsPinCodeOpen(true);
  };

  const openUnblockUserPinCode = () => {
    setEventScope(PIN_TOKEN_EVENT_SCOPE.UNBLOCK_ORG_ADMIN);
    closeUnblock();
    setIsPinCodeOpen(true);
  };

  const closePinCode = () => {
    setIsPinCodeOpen(false);
    setEventScope(null);
  };

  const onSuccessEditMobilePhone = () => {
    onRefetchUser();
    closeEditMobilePhone();

    showSnackbar(UPDATE_USER_MOBILE_PHONE_NOTIFICATION, { maxWidth: '375px' });
  };

  const onSuccessEditRole = () => {
    onRefetchUser();
    closeEditUserRole();

    showSnackbar(UPDATE_USER_ROLE_NOTIFICATION, { maxWidth: '375px' });
  };

  const checkPinCodeSuccess = (pinToken: string) => {
    if (!eventScope) return;

    if (eventScope === PIN_TOKEN_EVENT_SCOPE.BLOCK_ORG_ADMIN) {
      blockUserMutate(pinToken);
      closePinCode();

      return;
    }

    if (eventScope === PIN_TOKEN_EVENT_SCOPE.UNBLOCK_ORG_ADMIN) {
      unblockUserMutate(pinToken);
      closePinCode();

      return;
    }
  };

  const { email, mobile_phone, status, role, invitation_data } = user;
  const formattedLastInvitedDate = formatDateTimeUTC(invitation_data?.last_time_invited);
  const formattedMobilePhone = formatPhone(mobile_phone);
  const { seconds } = useTimer(invitation_data?.next_resend_available_in_sec, onRefetchUser);

  const formattedRole = ORGANIZATION_USER_ROLE_LABEL_MAP[role];
  const icon = ORGANIZATION_USER_ROLE_ICON_MAP[role];
  const isInvited = status === ORGANIZATION_USER_STATUS.INVITED;
  const isAvailableToModify = status === ORGANIZATION_USER_STATUS.ACTIVE || isInvited;
  const isAvailableToUnblock = status === ORGANIZATION_USER_STATUS.BLOCKED;

  return (
    <Box flexDirection='row' columnGap='28px'>
      <Box display='flex' width='100%' columnGap='28px' flexShrink='0'>
        <Box width='100%'>
          <UserDetailsCard>
            <UserDetailsRow>
              <UserDetailsRowTitle>Email Address</UserDetailsRowTitle>
              <UserDetailsRowValue>{email}</UserDetailsRowValue>
            </UserDetailsRow>
            <UserDetailsRow>
              <Box flexDirection='row' justifyContent='space-between'>
                <Box>
                  <UserDetailsRowTitle>Mobile Phone Number</UserDetailsRowTitle>
                  <UserDetailsRowValue>{formattedMobilePhone}</UserDetailsRowValue>
                </Box>
                {isInvited && (
                  <IconButton onClick={openEditMobilePhone} ml='12px'>
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
            </UserDetailsRow>
            <UserDetailsRow>
              <Box flexDirection='row' justifyContent='space-between'>
                <Box>
                  <UserDetailsRowTitle>Role</UserDetailsRowTitle>
                  <UserRole role={formattedRole} icon={icon}></UserRole>
                </Box>
              </Box>
            </UserDetailsRow>
            <StatusDetailsRow>
              <Box display='flex' justifyContent='space-between'>
                <Box>
                  <UserDetailsRowTitle>Status</UserDetailsRowTitle>
                  {/* TODO: refactor this to own status component */}
                  <OpcoUserStatus status={status} />
                </Box>

                <Box display='flex' columnGap='12px' alignItems='center'>
                  {isInvited && (
                    <Button
                      width={160}
                      size='medium'
                      variant='secondary'
                      onClick={openResendInvitation}
                      disabled={!!seconds}
                    >
                      {!seconds ? (
                        'Resend Invitation'
                      ) : (
                        <>
                          Resend in &nbsp;
                          <ReelAnimateTime time={formatSecondsToMinutes(seconds)} />
                        </>
                      )}
                    </Button>
                  )}

                  {isAvailableToModify && (
                    <Button width={71} size='medium' variant='secondary' onClick={openBlock}>
                      Block
                    </Button>
                  )}

                  {isAvailableToUnblock && (
                    <Button width={90} size='medium' variant='secondary' onClick={openUnblock}>
                      Unblock
                    </Button>
                  )}
                </Box>
              </Box>

              {isInvited && (
                <Box mt='20px'>
                  <DetailsRow name='Last Invited'>{formattedLastInvitedDate}</DetailsRow>
                  <DetailsRow name='Times Invited'>{invitation_data?.times_invited}</DetailsRow>
                </Box>
              )}
            </StatusDetailsRow>
          </UserDetailsCard>
        </Box>
      </Box>

      <ConfirmModal
        isLoading={isPendingBlockUser}
        isOpen={isBlockOpen}
        header='Block User'
        body='This User will no longer be able to access WireVault. You can choose to reset their account to reenable their access later.'
        onClose={closeBlock}
        onConfirm={openBlockUserPinCode}
      />

      <ConfirmModal
        isLoading={isPendingUnblockUser}
        isOpen={isUnblockOpen}
        header='Unblock User'
        body='The User will be able to manage transactions and wires of this organization again.'
        onClose={closeUnblock}
        onConfirm={openUnblockUserPinCode}
      />

      <ConfirmModal
        isOpen={isResendInvitationOpen}
        header='Resend Invitation'
        body={
          <>
            A new invitation will be sent to the invited user. <br /> The previous one will be still valid.
          </>
        }
        onClose={closeResendInvitation}
        onConfirm={reinviteOrganizationMutate}
      />

      <Drawer
        isOpen={isEditMobilePhoneOpen}
        header='Edit Mobile Phone'
        subHeader='Please edit mobile phone.'
        onClose={closeEditMobilePhone}
      >
        <EditMobilePhoneForm
          onClose={closeEditMobilePhone}
          mobilePhone={mobile_phone}
          onSuccess={onSuccessEditMobilePhone}
        />
      </Drawer>

      <Drawer
        isOpen={isEditUserRoleOpen}
        header='Edit Role'
        subHeader='Please edit user role.'
        onClose={closeEditUserRole}
      >
        <EditUserRoleForm onClose={closeEditUserRole} userRole={role} onSuccess={onSuccessEditRole} />
      </Drawer>

      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          onSuccess={checkPinCodeSuccess}
          onClose={closePinCode}
          eventScope={eventScope}
        />
      )}

      <CannotModifyLastUserErrorModal
        isOpen={isOpenErrorModal}
        onClose={() => setIsOpenErrorModal(false)}
        header='User cannot be blocked'
        subHeader={
          <>
            Please assign another user of this organization <br /> as a Site Admin in order to block this user.
          </>
        }
      />
    </Box>
  );
};
