import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { DetailsDividerRow } from '@/components/DetailsWidget/DetailsDividerRow/DetailsDividerRow';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { Box } from '@/components/Box/Box';
import { getPaymentDetailsData } from '../helpers/helpers';
import { BankName } from '@/bundle/shared/components/PaymentDetailsData/ui/BankName';

type IntermediaryPaymentDetailsType = {
  paymentDetails: PaymentDetailsGeneralType;
};

export const IntermediaryPaymentDetails = ({ paymentDetails }: IntermediaryPaymentDetailsType) => {
  const {
    bankCodeLabel,
    isDataFromBlockchain,
    intermediaryBankName,
    intermediaryAccountName,
    intermediaryBankAddress,
    intermediaryBankPhoneNumber,
    intermediaryBankCodeValue,
  } = getPaymentDetailsData(paymentDetails);

  return (
    <Box>
      <DetailsDividerRow />
      <DetailsRow name='Intermediary Bank' isHeader />
      <DetailsRow name={bankCodeLabel} hasHighlight={isDataFromBlockchain}>
        {intermediaryBankCodeValue}
      </DetailsRow>
      <DetailsRow name='Bank Name'>
        <BankName
          name={intermediaryBankName}
          isVerified={paymentDetails?.internal_payment_details_data?.is_intermediary_bank_name_verified}
        />
      </DetailsRow>
      <DetailsRow name='Account Number' hasHighlight={isDataFromBlockchain}>
        {intermediaryAccountName}
      </DetailsRow>
      <DetailsRow name='Bank Address'>{intermediaryBankAddress}</DetailsRow>
      <DetailsRow name='Bank Phone Number'>{intermediaryBankPhoneNumber}</DetailsRow>
    </Box>
  );
};
