import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sendUpsellRequest } from '@/bundle/shared/api';
import { CSSTransition } from 'react-transition-group';
import {
  GetStartedStep,
  UpsellBannerText,
  UpsellBannerWrapper,
  CloseUpsellBannerWrapper,
  AdvertisingMessageBlock,
  AnimateFadeInUpsellBanner,
  SuccessBlockWrapper,
  GetStartedBlock,
  IconWrapper,
} from './styles';
import LightingIcon from './images/LightingIcon.svg?react';
import SuccessIcon from '@/images/circleSuccessIcon.svg?react';
import BannerArrowIcon from './images/BannerArrowIcon.svg?react';
import CloseBannerIcon from './images/CloseBannerIcon.svg?react';

interface UpsellBannerType {
  isClosable?: boolean;
  onSent?: () => void;
}

export const UpsellBanner = ({ isClosable = true, onSent }: UpsellBannerType) => {
  const [isGetStartedStep, setIsGetStartedStep] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const { mutate } = useMutation({
    mutationKey: ['send_upsell_request'],
    mutationFn: () => {
      return sendUpsellRequest();
    },
    onSuccess: (sendUpsellResponse) => {
      if (sendUpsellResponse?.error) return;

      onSent?.();

      setIsGetStartedStep(false);
    },
  });

  const sendUpsell = () => {
    mutate();
  };

  const closeBanner = () => {
    setIsOpen(false);
  };

  const nodeRef = useRef(null);

  return (
    isOpen && (
      <UpsellBannerWrapper>
        <GetStartedStep isVisible={isGetStartedStep}>
          <IconWrapper>
            <LightingIcon />
          </IconWrapper>
          <AdvertisingMessageBlock>
            See how WireVault can secure your transactions.{' '}
            <GetStartedBlock onClick={sendUpsell}>
              Get started
              <BannerArrowIcon />
            </GetStartedBlock>
          </AdvertisingMessageBlock>
        </GetStartedStep>
        <CSSTransition in={!isGetStartedStep} nodeRef={nodeRef} classNames='fade' timeout={500} unmountOnExit>
          <AnimateFadeInUpsellBanner ref={nodeRef}>
            <SuccessBlockWrapper isVisible={isGetStartedStep}>
              <IconWrapper>
                <SuccessIcon />
              </IconWrapper>
              <UpsellBannerText>
                We are processing your request. Look for onboarding instructions within one business day.
              </UpsellBannerText>
            </SuccessBlockWrapper>
          </AnimateFadeInUpsellBanner>
        </CSSTransition>
        {isClosable && (
          <CloseUpsellBannerWrapper>
            <CloseBannerIcon onClick={closeBanner} />
          </CloseUpsellBannerWrapper>
        )}
      </UpsellBannerWrapper>
    )
  );
};
