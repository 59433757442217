import { Box } from '@/components/Box/Box';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsRowNotes } from '@/components/DetailsWidget/DetailsRowNotes/DetailsRowNotes';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { WireStatus } from '@/bundle/shared/components/WireStatus/WireStatus';
import { formatAmount } from '@/helpers/formatHelpers';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { AssignedWireType, WireStatusType } from '@/types/wireTypes';
import { DetailsEditButton } from '../DetailsEditButton/DetailsEditButton';
import {
  isCancelledWireStatus,
  isCreatedWireStatus,
  isDepositorUnassignedWireStatus,
  isDepositSentWireStatus,
  isExecutedWireStatus,
  isFailedWireStatus,
  isPaymentDetailsProvidedWireStatus,
  isPendingWireStatus,
  isRecipientAssignedWireStatus,
  isRecipientInvitedWireStatus,
  isRecipientProxyAssignedWireStatus,
  isRecipientProxyInvitedWireStatus,
} from '@/helpers/wireHelpers';
import capitalize from 'lodash/capitalize';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { getContactUsPath } from '@/bundle/pages/ContactUsPage/path/path';
import { Notification } from '@/components/Notification/Notification';
import { WaterMark } from '@/bundle/shared/components/WaterMark/WaterMark';
import { useParams } from 'react-router-dom';
import { getOutboundWireDetailsPath } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/path/path';

type OrgAdminWireDetailsType = {
  wire: AssignedWireType;
  onEdit: () => void;
};

const isEditableWire = (wireStatus: WireStatusType) => {
  return (
    isCreatedWireStatus(wireStatus) ||
    isRecipientInvitedWireStatus(wireStatus) ||
    isRecipientAssignedWireStatus(wireStatus) ||
    isRecipientProxyInvitedWireStatus(wireStatus) ||
    isRecipientProxyAssignedWireStatus(wireStatus) ||
    isPaymentDetailsProvidedWireStatus(wireStatus) ||
    isDepositorUnassignedWireStatus(wireStatus)
  );
};

const TOOLTIP_OPTIONS = {
  name: 'status',
  content: 'This wire has been submitted to the WireVault blockchain.',
  options: {
    place: 'top-start',
  },
};

export const OrgAdminWireDetails = ({ wire, onEdit }: OrgAdminWireDetailsType) => {
  const { transactionId } = useParams<{ transactionId: string }>();

  const {
    name,
    status,
    type,
    amount,
    ref_id,
    date_created,
    expected_payment_date,
    created_by,
    date_modified,
    notes,
    contract,
  } = wire;

  const isFailedWire = isFailedWireStatus(status);
  const isPendingWire = isPendingWireStatus(status);
  const isSentWire = isDepositSentWireStatus(status);
  const isCanceledWire = isCancelledWireStatus(status);
  const isExecutedWire = isExecutedWireStatus(status);
  const hasEditWireButton = isEditableWire(status);

  const childWire = wire?.child_wire;
  const parentWire = wire?.parent_wire;
  const hasReplacementWire = childWire || parentWire;
  const showWaterMark = isSentWire || isCanceledWire || isExecutedWire;
  const replacementWireId = childWire ? childWire?.id : parentWire?.id;

  return (
    <Box position='relative'>
      <DetailsWidget>
        <DetailsRow name='Transaction Name' title={contract?.name}>
          {contract?.name || '-'}
        </DetailsRow>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <DetailsRow name='Wire Name' direction='column' title={name}>
            {name}
          </DetailsRow>
          {hasEditWireButton && <DetailsEditButton onEdit={onEdit} />}
        </Box>

        {isFailedWire && (
          <Box mb='12px'>
            <Notification variant='error' fontVariant='semibold'>
              Something went wrong. Please contact{' '}
              <NavigationLink to={getContactUsPath()}>WireVault team</NavigationLink>
            </Notification>
          </Box>
        )}

        <DetailsRow name='Type'>{capitalize(type)}</DetailsRow>
        <DetailsRow name='Status' tooltip={isPendingWire ? TOOLTIP_OPTIONS : null}>
          <WireStatus status={status} />
        </DetailsRow>
        <DetailsRow name='Wire Amount' hasBorder>
          {formatAmount(amount)}
        </DetailsRow>
        <DetailsRow name='Wire ID'>{ref_id || '-'}</DetailsRow>
        <DetailsRow name='Wire Creation Date'>{formatDate(date_created)}</DetailsRow>
        <DetailsRow name='Expected Payment Date'>{formatDate(expected_payment_date)}</DetailsRow>
        <DetailsRow name='Created by' title={created_by?.email}>
          {created_by?.email || '-'}
        </DetailsRow>
        <DetailsRow name='Last Update'>{formatDate(date_modified)}</DetailsRow>
        {hasReplacementWire && (
          <DetailsRow name={childWire ? 'Replaced by' : 'Replacement of'}>
            <NavigationLink to={getOutboundWireDetailsPath(transactionId, replacementWireId)}>{`${
              childWire ? childWire?.name : parentWire?.name
            } `}</NavigationLink>
          </DetailsRow>
        )}
        <DetailsRowNotes name='Notes'>{notes}</DetailsRowNotes>
      </DetailsWidget>
      {showWaterMark && <WaterMark wireStatus={status} />}
    </Box>
  );
};
