import { NoResults } from '@/components/NoResults/NoResults';
import NoSearchResultsIcon from '@/images/NoSearchResultsIcon.svg?react';
import { ReactNode } from 'react';

type NoSearchResultsType = {
  children?: ReactNode;
};

export const NoSearchResults = ({ children = 'No wires found' }: NoSearchResultsType) => {
  return <NoResults icon={<NoSearchResultsIcon />} message={children} />;
};
