import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import shuffle from 'lodash/shuffle';
import { ModalWindow } from '@/components/ModalWindow/ModalWindow';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { RequestLink } from '../RequestLink';
import { RequestResetAccountModal } from '../RequestResetAccountModal';
import { RequestSentModal } from '../RequestSentModal';
import { useQuery } from '@tanstack/react-query';
import { getAuthPinCodeAttempts } from '../../api';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { ResponseErrorType } from '@/types/sharedTypes';
import { PIN_CODE_KEYBOARD, PIN_CODE_LENGTH } from '@/bundle/shared/components/PinCode/const/const';
import { PinCode } from '@/bundle/shared/components/PinCode/PinCode';
import { CheckPinCodeHintBox } from '@/bundle/shared/components/PinCode/ui/CheckPinCodeHintBox';
import { getResetRequestAuthPath } from '@/bundle/Auth/InviteUserFlow/ResetRequestAuthPage/path/path';
import { useTranslation } from 'react-i18next';

type CheckPinCodeFormType = {
  onConfirm: (pinCode: string) => void;
  onReset: () => void;
  isLoading: boolean;
  error?: ResponseErrorType;
};

export const CheckPinCodeForm = forwardRef((props: CheckPinCodeFormType, ref) => {
  const navigate = useNavigate();
  const { t } = useTranslation('login');

  const { onConfirm, onReset, error, isLoading: isCheckPinCodeLoading } = props;
  const [pinCode, setPinCode] = useState<number[]>([]);
  const [shuffledPinCode, setShuffledPinCode] = useState(() => shuffle(PIN_CODE_KEYBOARD));

  useImperativeHandle(ref, () => {
    return {
      resetPinCode() {
        setPinCode([]);
        setShuffledPinCode(shuffle(PIN_CODE_KEYBOARD));
      },
    };
  }, []);

  const onBack = () => navigate(getLoginPath());

  const { data, isFetching } = useQuery({
    queryKey: ['get_attempts_count'],
    queryFn: () => getAuthPinCodeAttempts(),
  });

  const clearPinCode = () => {
    setPinCode([]);
    setShuffledPinCode(shuffle(PIN_CODE_KEYBOARD));
    onReset();
  };

  const confirmPinCode = () => {
    onConfirm(pinCode.join(''));
  };

  const redirectToResetRequest = () => {
    navigate(getResetRequestAuthPath());
  };

  const apiError = getResponseError(error);
  const attemptsCount = data?.body?.remaining_attempts;
  const isDisabled = pinCode.length !== PIN_CODE_LENGTH || !!error;
  const isLoading = isFetching || isCheckPinCodeLoading;

  useEffect(() => {
    if (error && !isLoading && pinCode.length > 0) {
      onReset();
    }
  }, [error, pinCode.length, onReset, isLoading]);

  return (
    <>
      <PinCode
        title={t('login:pinCodeHeader')}
        onSubmit={confirmPinCode}
        onBack={onBack}
        link={<RequestLink onOpen={redirectToResetRequest} />}
        hasShadow
        pinCode={pinCode}
        onSet={setPinCode}
        onClear={clearPinCode}
        pinCodeKeyBoard={shuffledPinCode}
        isDisabled={isDisabled}
        apiError={apiError}
        isLoading={isCheckPinCodeLoading}
        messageBox={<CheckPinCodeHintBox attemptsCount={attemptsCount} error={apiError} isLoading={isLoading} />}
      />
      <ModalWindow isOpen={false} headerText={t('login:pinCodeRequestResetHeader')}>
        <RequestResetAccountModal />
      </ModalWindow>
      <ModalWindow isOpen={false} headerText={t('pinCodeRequestResetSendHeader')}>
        <RequestSentModal />
      </ModalWindow>
    </>
  );
});
