import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { editOrganizationUserMobilePhoneApi } from '@/api/v1/opco/organizations/editOrganizationUserMobilePhoneApi';
import {
  editOrganizationUserApi,
  EditOrganizationUserDetailsPayloadType,
} from '@/api/v1/opco/organizations/editOrganizationUserApi';
import { getAuthTokenHeaders } from '@/helpers/apiHelpers/queryHelper';
import { blockOrganizationUserApi } from '@/api/v1/opco/organizations/blockOrganizationUserApi';
import { unblockOrganizationUserApi } from '@/api/v1/opco/organizations/unblockOrganizationUserApi';
import { getOrganizationUserApi } from '@/api/v1/opco/organizations/getOrganizationUserApi';
import { editOrganizationUserRoleApi } from '@/api/v1/opco/organizations/editOrganizationUserRoleApi';
import { OrgAdminRoleType } from '@/const/user';
import { reinviteOrganizationUserApi } from '@/api/v1/opco/organizations/reinviteOrganizationUserApi';

export const loadOrganizationUser = async (organizationId: string, userId: string) => {
  try {
    const response = await getOrganizationUserApi(organizationId, userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const editOrganizationUserMobilePhone = async (
  organizationId: string,
  userId: string,
  mobilePhone: string,
  pinToken: string
) => {
  const options = getAuthTokenHeaders(pinToken);

  try {
    const payload = {
      mobile_phone: mobilePhone,
    };

    const response = await editOrganizationUserMobilePhoneApi(organizationId, userId, payload, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const editOrganizationUserDetails = async (
  organizationId: string,
  userId: string,
  payload: EditOrganizationUserDetailsPayloadType
) => {
  try {
    const response = await editOrganizationUserApi(organizationId, userId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const blockOrganizationUser = async (organizationId: string, userId: string, pinToken: string) => {
  const options = getAuthTokenHeaders(pinToken);

  try {
    const response = await blockOrganizationUserApi(organizationId, userId, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const unblockOrganizationUser = async (organizationId: string, userId: string, pinToken: string) => {
  const options = getAuthTokenHeaders(pinToken);

  try {
    const response = await unblockOrganizationUserApi(organizationId, userId, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const editOrganizationUserRole = async (
  organizationId: string,
  userId: string,
  role: OrgAdminRoleType,
  pinToken: string
) => {
  const options = getAuthTokenHeaders(pinToken);

  try {
    const payload = {
      role,
    };

    const response = await editOrganizationUserRoleApi(organizationId, userId, payload, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const reinviteOrganizationUser = async (organizationId: string, userId: string) => {
  try {
    const response = await reinviteOrganizationUserApi(organizationId, userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
