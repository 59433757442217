import { useEffect, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { FormikProps, useFormik } from 'formik';
import { ContentLayout } from '../../Layouts/ContentLayout/ContentLayout';
import { ContactUsHeader, ContactUsWrapper } from './styles';
import { postContactMessage } from '@/bundle/pages/ContactUsPage/api';
import {
  CONTACT_US_INITIAL_VALUES,
  CONTACT_US_VALIDATION_SCHEMA,
  ContactUsFormType,
} from '@/bundle/pages/ContactUsPage/const/const';
import { Notification } from '@/components/Notification/Notification';
import { Box } from '@/components/Box/Box';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { useUser } from '@/context/userContext';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { HTTP_TOO_MANY_REQUESTS_STATUS } from '@/api/const';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { FormikTextArea } from '@/components/form/fields/FormikTextArea/FormikTextArea';

export const ContactUsPage = () => {
  const ref = useRef<HTMLDivElement>(null);

  const { user } = useUser();

  const { mutate, isPending, data } = useMutation({
    mutationKey: ['send_message'],
    mutationFn: (formik: FormikProps<ContactUsFormType>) => {
      return postContactMessage(formik.values);
    },
    onSuccess: (createdMessage, formik) => {
      if (createdMessage.error && createdMessage.error?.status_code === HTTP_TOO_MANY_REQUESTS_STATUS) {
        showSnackbar(createdMessage.error?.error_content as string, { variant: 'error' });

        return;
      }

      if (createdMessage.error) return;

      formik.resetForm({
        values: {
          ...CONTACT_US_INITIAL_VALUES,
          sender_email: user.email,
          mobile_phone: user.mobile_phone,
        },
      });
      showSnackbar('Your request was successfully submitted.', { variant: 'success' });
    },
  });

  const formik = useFormik({
    initialValues: CONTACT_US_INITIAL_VALUES,
    validationSchema: CONTACT_US_VALIDATION_SCHEMA,
    onSubmit: () => {
      return mutate(formik);
    },
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    formik.setValues({
      ...formik.values,
      sender_email: user.email,
      mobile_phone: user.mobile_phone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const apiError = data?.error;

  return (
    <ContentLayout title='Contact us'>
      <ContactUsWrapper ref={ref}>
        <ContactUsHeader>Submit a request</ContactUsHeader>
        <Notification fontVariant='medium' mb='24px'>
          Please describe your issue in the form below and we will contact you as soon as possible.
        </Notification>
        <Box justifyContent='center' flexDirection='column'>
          <FormikForm value={formik}>
            <FormikInput
              name='sender_email'
              label='Email Address*'
              placeholder='Enter Email Address'
              apiError={apiError}
            />
            <FormikPhoneInput name='mobile_phone' label='Mobile Phone Number' apiError={apiError} />
            <FormikInput name='subject' label='Subject*' placeholder='Enter Subject' apiError={apiError} />
            <FormikTextArea
              name='message'
              label='Message*'
              placeholder='Please include details of your request (steps to reproduce, device/environment, etc).'
              apiError={apiError}
              rows={10}
            />
            <Box mt='12px'>
              <Button isLoading={isPending} width={160} type='submit' mobileStretch>
                Submit
              </Button>
            </Box>
          </FormikForm>
        </Box>
      </ContactUsWrapper>
    </ContentLayout>
  );
};
