import { useLocation, useParams } from 'react-router-dom';
import {
  LogoutLink,
  LogoutLinkWrapper,
  LogoutWrapper,
  ProfileSectionTitle,
  SidebarFooter,
  SidebarFooterWrapper,
  UserEmail,
  UserInfo,
  UserType,
} from './styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import LogoutIcon from '../../images/LogoutIcon.svg?react';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { useUser } from '@/context/userContext';
import { Box } from '@/components/Box/Box';
import { SwitchRoleButton } from '@/bundle/Sidebar/ui/SwitchRoleButton/SwitchRoleButton';
import { getUserRoleLabel } from '@/helpers/userHelpers';
import { MenuSection, MenuSectionIcon, MenuSectionLink } from '@/bundle/Sidebar/ui/SidebarMenu/styles';
import ProfileIcon from '@/images/ProfileIcon.svg?react';
import { getProfilePath } from '@/bundle/pages/ProfilePage/path/path';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getOpcoUserDetailsPath } from '@/bundle/_Opco/_OpcoUsersPage/Details/path/path';
import { isActiveRoute } from '../../helpers/helpers';
import { useLogout } from '@/bundle/shared/hooks/useLogout';

interface SidebarUserDetailsType {
  onNavigate: () => void;
}

export const SidebarUserDetails = ({ onNavigate }: SidebarUserDetailsType) => {
  const { mutate: logoutMutate } = useLogout();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { user, hasMultipleUserRoles, currentRole, isOpcoRole } = useUser();

  const handleLogout = () => {
    logoutMutate();
  };

  const isActiveProfileRoute = () => {
    const currentPath = getProfilePath();
    const isActivePath = isActiveRoute(pathname, [currentPath]);

    // Since Opco users has different profile url - add additional conditions for them
    return isActivePath || (isOpcoRole && isActiveRoute(pathname, [getOpcoUserDetailsPath()]) && user.id === id);
  };

  const email = user?.email;
  const userRole = getUserRoleLabel(currentRole, user);

  const currentUrl = isOpcoRole ? getOpcoUserDetailsPath(user?.id) : getProfilePath();
  const isActiveProfile = isActiveProfileRoute();

  const navigateToProfile = () => {
    if (currentUrl !== pathname) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileStartRedirect);
    }

    onNavigate();
  };

  return (
    <SidebarFooterWrapper>
      <SidebarFooter>
        <Box flexDirection='column' rowGap='20px' width='100%'>
          <UserInfo>
            <UserEmail data-tooltip-id='email-tooltip'>{email}</UserEmail>
            <Tooltip id='email-tooltip' place='top'>
              {email}
            </Tooltip>
            {userRole && <UserType>{userRole}</UserType>}
          </UserInfo>
          {hasMultipleUserRoles && <SwitchRoleButton role={currentRole} />}
        </Box>
      </SidebarFooter>

      <LogoutWrapper>
        <MenuSection isActive={isActiveProfile}>
          <MenuSectionLink to={currentUrl} onClick={navigateToProfile}>
            <MenuSectionIcon>
              <ProfileIcon />
            </MenuSectionIcon>
            <ProfileSectionTitle isActive={isActiveProfile}>Profile</ProfileSectionTitle>
          </MenuSectionLink>
        </MenuSection>
        <LogoutLinkWrapper onClick={handleLogout}>
          <LogoutIcon />
          <LogoutLink>Log out</LogoutLink>
        </LogoutLinkWrapper>
      </LogoutWrapper>
    </SidebarFooterWrapper>
  );
};
