import { COLORS } from '@/styles/colors';
import { THEME } from '@/styles/theme';
import { OptionProps, Props } from 'react-select';
import { ValueOf } from '@/types/sharedTypes';
import { CSSProperties } from 'react';

const VARIANT = {
  STANDARD: 'standard',
  COMPACT: 'compact',
} as const;

const SIZE = {
  MEDIUM: 'medium',
  SMALL: 'small',
} as const;

type SelectVariantType = ValueOf<typeof VARIANT>;
type SelectSizeType = ValueOf<typeof SIZE>;

type PropsType = {
  hasPortal?: boolean;
  error?: string;
  variant?: SelectVariantType;
  size?: SelectSizeType;
};

const isCompactVariant = (variant: SelectVariantType) => {
  return variant === VARIANT.COMPACT;
};

const isSmallSize = (size: SelectSizeType) => {
  return size === SIZE.SMALL;
};

const getControlBorderStyles = (props: PropsType, isFocused: boolean, menuIsOpen: boolean) => {
  const { variant, error } = props;

  if (isCompactVariant(variant)) return 'none';

  if (error) {
    return `1px solid ${COLORS.red[600]}`;
  }

  if (isFocused) return `1px solid ${COLORS.green[500]}`;

  return menuIsOpen ? `1px solid ${COLORS.green[500]}` : `1px solid ${COLORS.grey[400]}`;
};

const getControlBorderHoverStyles = (variant: SelectVariantType, menuIsOpen: boolean, isDisabled: boolean) => {
  if (isCompactVariant(variant)) return 'none';

  return !menuIsOpen && !isDisabled && `1px solid ${COLORS.grey[850]}`;
};

const getControlBoxShadow = (variant: SelectVariantType, menuIsOpen: boolean) => {
  if (isCompactVariant(variant)) return 'none';

  return menuIsOpen ? '0px 0px 2px rgba(0, 134, 111, 0.60)' : 'none';
};

const getControlHeight = (variant: SelectVariantType, size: SelectSizeType) => {
  return isSmallSize(size) || isCompactVariant(variant) ? '40px' : '50px';
};

const getControlBackgroundStyles = (variant: SelectVariantType, menuIsOpen: boolean) => {
  if (isCompactVariant(variant) && menuIsOpen) return `${COLORS.grey[200]}`;
  if (isCompactVariant(variant) && !menuIsOpen) return `${COLORS.white}`;

  return `${COLORS.grey[250]}`;
};

const getSingleValueStyles = (variant: SelectVariantType) => {
  if (isCompactVariant(variant))
    return {
      fontWeight: 600,
    };

  return null;
};

const getOptionBackgroundColor = (isSelected: boolean, isFocused: boolean) => {
  if (isFocused && isSelected) return COLORS.grey[200];
  if (isSelected) return COLORS.grey[150];
  if (isFocused) return COLORS.grey[200];

  return COLORS.white;
};

const baseStyles = (props?: PropsType): Record<string, unknown> => ({
  control: (base: CSSProperties, { isFocused, selectProps }: { isFocused: boolean; selectProps: Props }) => ({
    ...base,
    outline: 'none',
    transition: 'all 0.2s ease-in-out 0s',
    cursor: 'pointer',
    padding: isCompactVariant(props?.variant) ? '6px 10px' : '13px 16px',
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: isSmallSize(props?.size) ? '14px' : '16px',
    lineHeight: isSmallSize(props?.size) ? '16px' : '24px',
    borderRadius: '10px',
    border: getControlBorderStyles(props, isFocused, selectProps.menuIsOpen),
    boxShadow: getControlBoxShadow(props?.variant, selectProps.menuIsOpen),
    minHeight: 0,
    height: getControlHeight(props?.variant, props?.size),
    flexWrap: 'nowrap',
    backgroundColor: getControlBackgroundStyles(props?.variant, selectProps.menuIsOpen),

    '&:hover': {
      backgroundColor: isCompactVariant(props?.variant) ? `${COLORS.grey[200]}` : `${COLORS.grey[250]}`,
      border: getControlBorderHoverStyles(props?.variant, selectProps.menuIsOpen, selectProps.isDisabled),
    },
  }),
  singleValue: (base: CSSProperties) => ({
    ...base,
    ...getSingleValueStyles(props?.variant),
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    maxHeight: 'none',
    marginTop: '4px',
    zIndex: 2,
    borderRadius: '10px',
    border: `1px solid ${COLORS.grey[850]}`,
    boxShadow: '0px 4px 8px 0px rgba(0, 6, 38, 0.20)',
    overflow: 'hidden',
  }),
  menuList: (base: CSSProperties) => ({
    ...base,
    textAlign: 'left',
    maxHeight: props.hasPortal ? '370px' : '240px',
    scrollPaddingTop: '10px',

    '::-webkit-scrollbar': {
      width: `${THEME.layout.scrollWidth}`,
    },

    '::-webkit-scrollbar-thumb': {
      background: '#d5dee3',
      borderRadius: '10px',
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  menuPortal: (base: CSSProperties) => ({ ...base, zIndex: THEME.select.zIndex }),
  option: (base: CSSProperties, optionProps: OptionProps) => {
    const { isSelected, isDisabled, isFocused } = optionProps;

    return {
      ...base,
      padding: isCompactVariant(props?.variant) ? '10px' : '10px 17px',
      backgroundColor: getOptionBackgroundColor(isSelected, isFocused),
      fontSize: isSmallSize(props?.size) ? '14px' : '16px',
      color: isDisabled ? `${COLORS.grey[600]}` : `${COLORS.black}`,
      cursor: isDisabled ? 'default' : 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontFamily: 'Poppins-Regular, sans-serif',

      '&:hover': {
        backgroundColor: !isDisabled && `${COLORS.grey[200]}`,
      },
    };
  },
  dropdownIndicator: (
    base: CSSProperties,
    { selectProps: { menuIsOpen } }: { selectProps: { menuIsOpen: boolean } }
  ) => ({
    ...base,
    cursor: 'pointer',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    padding: '0',
  }),
  container: () => ({
    position: 'relative',
  }),
  indicatorContainer: () => ({
    display: 'none',
  }),
  indicatorSeparator: (base: CSSProperties) => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: 0,
    overflow: 'visible',
  }),
  input: (base: CSSProperties) => ({
    ...base,
    margin: 0,
  }),
  placeholder: (base: CSSProperties) => ({
    ...base,
    margin: 0,
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: isSmallSize(props?.size) ? '14px' : '16px',
    color: `${COLORS.grey[600]}`,
  }),
});

const multiSelectStyles = (): Record<string, unknown> => ({
  multiValue: (base: CSSProperties) => ({
    ...base,
    maxWidth: '140px',
    borderRadius: '4px',
  }),
  multiValueGeneric: (base: CSSProperties) => ({
    ...base,
  }),
  multiValueRemove: (base: CSSProperties) => ({
    ...base,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    svg: {
      fill: `${COLORS.grey[400]}`,
      '&:hover': {
        fill: `${COLORS.grey[850]}`,
      },
    },
  }),
});

export const customStyles = (props?: PropsType): Record<string, unknown> => {
  return {
    ...baseStyles(props),
    ...multiSelectStyles(),
  };
};
