import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logout } from '../api';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { authTokenService } from '@/bundle/shared/services/authTokenService';
import { sessionStorageService } from '@/helpers/storageHelpers';
import { useNavigate } from 'react-router-dom';
import { CURRENT_ROLE_KEY } from '@/const/shared';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['logout'],
    mutationFn: () => {
      return logout();
    },
    onSuccess(logoutResponse) {
      if (logoutResponse?.error) return;

      amplitudeService.resetUser();
      authTokenService.removeTokens();
      queryClient.removeQueries();
      sessionStorageService.remove(CURRENT_ROLE_KEY);

      navigate(getLoginPath());
    },
  });
};
