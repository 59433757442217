import {
  UserDetailsCard,
  UserDetailsRow,
  UserDetailsRowTitle,
  UserDetailsRowValue,
} from '@/bundle/_Opco/shared/styles';
import UserIcon from '@/bundle/_Opco/_OpcoUsersPage/Details/images/UserIcon.svg?react';
import { Box } from '@/components/Box/Box';
import { UserDetailsEditableRow } from '@/bundle/_Opco/ui/OrganizationUsers/Details/ui/OrganizationUserAccountDetails/styles';
import EditIcon from '@/images/EditIcon.svg?react';
import { UserDetailsHeader } from './UserDetailsHeader/UserDetailsHeader';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { IconButton } from '@/components/IconButton/IconButton';

export interface PersonalDetailsType {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
}

interface UserPersonalInfoType {
  onOpen: (arg: boolean) => void;
  personalDetails: PersonalDetailsType;
  isEdit: boolean;
}

export const UserPersonalInfo = ({ onOpen, personalDetails, isEdit }: UserPersonalInfoType) => {
  const openEdit = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileEditPersonalInfoRedirect);

    onOpen(true);
  };

  const { first_name, middle_name, last_name } = personalDetails || {};

  return (
    <Box flexDirection='column' width='100%'>
      <UserDetailsHeader title='Personal Info' icon={<UserIcon />}></UserDetailsHeader>
      <UserDetailsCard>
        <UserDetailsRow>
          <Box flexDirection='row' justifyContent='space-between'>
            <UserDetailsEditableRow>
              <UserDetailsRowTitle>First Name</UserDetailsRowTitle>
              <UserDetailsRowValue>{first_name || '-'}</UserDetailsRowValue>
            </UserDetailsEditableRow>
            {isEdit && (
              <IconButton ml='12px' onClick={openEdit}>
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserDetailsRowTitle>Middle Name</UserDetailsRowTitle>
          <UserDetailsRowValue>{middle_name || '-'}</UserDetailsRowValue>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserDetailsRowTitle>Last Name</UserDetailsRowTitle>
          <UserDetailsRowValue>{last_name || '-'}</UserDetailsRowValue>
        </UserDetailsRow>
      </UserDetailsCard>
    </Box>
  );
};
