import { useNavigate } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import { authTokenService } from '@/bundle/shared/services/authTokenService';
import { decodeToken } from '@/helpers/tokenHelpers';
import { AuthTokenType } from '@/types/sharedTypes';
import { LayoutSpinner, LayoutWrapper } from '../Layouts/styles';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { AuthStepType, ForgotPasswordStepType } from '../Auth/const/const';

type AuthProtectedRouteType = {
  allowedStep: AuthStepType | ForgotPasswordStepType;
  children: ReactNode;
};

export const AuthProtectedRoute = ({ allowedStep, children }: AuthProtectedRouteType) => {
  const [checking, setChecking] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loginPath = getLoginPath();
    const tokens = authTokenService.getTokens();

    if (!tokens || !tokens?.access) {
      return navigate(loginPath);
    }

    const { step } = decodeToken<AuthTokenType>(tokens?.access);

    if (step !== allowedStep) {
      return navigate(loginPath);
    }

    setChecking(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return checking ? (
    <LayoutWrapper>
      <LayoutSpinner />
    </LayoutWrapper>
  ) : (
    <>{children}</>
  );
};
