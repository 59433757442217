import { COLORS } from '@/styles/colors';
import { resetButton } from '@/styles/common';
import { flex } from '@/styles/layout';
import { body, bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const TwoFALabel = styled.label`
  color: ${COLORS.grey[600]};
  display: block;
  margin-bottom: 6px;
  padding-top: 12px;
  ${poppinsMediumFont};
  ${bodySmall};
`;

export const ResendWrapper = styled.div`
  position: absolute;
  bottom: -32px;
  right: 0;
  column-gap: 8px;
  ${flex({ alignItems: 'center' })};
`;

export const Resend = styled.button<{ isDisabled?: boolean }>`
  ${poppinsMediumFont};
  color: ${({ isDisabled }) => (isDisabled ? COLORS.grey[600] : COLORS.green[500])};
  ${resetButton};
  ${body};
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
`;
