import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { Maybe } from '@/types/sharedTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { addedUserAssignmentStatus, addingOrgAdminRole, createOrgAdminUser, loadAddedUserDetails } from '../../api';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { useParams } from 'react-router-dom';
import { CREATE_ORGANIZATION_USER_VALIDATION_SCHEMA, getCreateOrganizationUserConfig } from './const';
import { useFormik } from 'formik';
import { ADDING_ORG_ADMIN_ROLE_STATUS_MAP } from '@/api/v1/opco/organizations/getUserAddingStatusApi';
import { Notification } from '@/components/Notification/Notification';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Box } from '@/components/Box/Box';
import { CreateOrgAdminAutocomplete } from '../../component/CreateOrgAdminAutocomplete';
import { Button } from '@/components/Button/Button';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import { PIN_TOKEN_EVENT_SCOPE } from '@/const/shared';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { FormValues } from '@/helpers/formHelpers/formHelpers';
import { formatPhone } from '@/helpers/formatHelpers';

interface CreateOrgAdminFormType {
  onClose: () => void;
  onSuccess: () => void;
  usersCount: number;
}

export const CreateOrgAdminForm = ({ onClose, onSuccess, usersCount }: CreateOrgAdminFormType) => {
  const { id } = useParams<{ id: string }>();
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Maybe<AutocompleteOptionType>>(null);

  const closePinCode = () => {
    setIsPinCodeOpen(false);
  };

  const isSelectedOption = selectedUser?.isSelectedOption;
  const selectedOptionValue = selectedUser?.value;
  const validationSchema = isSelectedOption ? null : CREATE_ORGANIZATION_USER_VALIDATION_SCHEMA;

  const { data: addingUserStatusData } = useQuery({
    queryKey: ['adding_user_status', selectedOptionValue],
    queryFn: () => {
      return addedUserAssignmentStatus(selectedOptionValue);
    },
    enabled: !!isSelectedOption,
  });

  const { data: loadUserData } = useQuery({
    queryKey: ['load_user_details', selectedOptionValue],
    queryFn: () => loadAddedUserDetails(selectedOptionValue),
    enabled: !!isSelectedOption && !!addingUserStatusData?.body?.is_available_to_add,
  });

  const isDisabledChangeOrgAdminRole = usersCount === 0;

  const formik = useFormik({
    initialValues: getCreateOrganizationUserConfig(isDisabledChangeOrgAdminRole),
    validationSchema,
    onSubmit: () => {
      if (addingUserStatusData?.body?.code === ADDING_ORG_ADMIN_ROLE_STATUS_MAP.CAN_BE_ADDED) {
        amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminExistingRedirect);
      } else {
        amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminNewRedirect);
      }

      setIsPinCodeOpen(true);
    },
  });

  const {
    mutate: createOrgAdminMutate,
    data: createOrgAdminData,
    isPending: isPendingCreateOrgAdmin,
    reset: resetCreateOrgUser,
  } = useMutation({
    mutationKey: ['create_org_admin', id],
    mutationFn: (pinCode: string) => {
      return createOrgAdminUser(id, formik.values, pinCode);
    },
    onSuccess(response) {
      if (response.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminNewSuccess);
      onSuccess();
    },
  });

  const {
    mutate: addingOrgAdminRoleMutate,
    data: addingOrgAdminRoleData,
    isPending: isPendingAddingOrgAdminRole,
    reset: resetAddingOrgAdminRole,
  } = useMutation({
    mutationKey: ['adding_org_admin_role', id, selectedOptionValue],
    mutationFn: (pinCode: string) => {
      const payload = {
        user_id: selectedOptionValue,
        role: formik.values.role,
      };

      return addingOrgAdminRole(id, payload, pinCode);
    },
    onSuccess(response) {
      if (response.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminExistingSuccess);

      onSuccess();
    },
  });

  const checkPinCodeSuccess = (pinToken: string) => {
    if (isSelectedOption) {
      addingOrgAdminRoleMutate(pinToken);
      closePinCode();

      return;
    }

    createOrgAdminMutate(pinToken);
    closePinCode();
  };

  const setFormValues = (formValues: Partial<FormValues>) => {
    formik.setValues({
      ...formik.values,
      ...formValues,
    });
  };

  const clearForm = () => {
    setFormValues({
      mobile_phone: '',
      first_name: '',
      middle_name: '',
      last_name: '',
    });
  };

  // Reset form when user clears last char in search
  useEffect(() => {
    if (!selectedUser) return;

    if (!formik?.values.email) {
      setFormValues({
        mobile_phone: '',
        first_name: '',
        middle_name: '',
        last_name: '',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.email, selectedUser]);

  useEffect(() => {
    if (isSelectedOption) return;

    resetCreateOrgUser();
    resetAddingOrgAdminRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedOption]);

  useEffect(() => {
    if (!isSelectedOption || !loadUserData?.body) return;

    const { first_name, middle_name, last_name, mobile_phone } = loadUserData.body;

    setFormValues({
      mobile_phone: formatPhone(mobile_phone),
      first_name,
      middle_name,
      last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedOption, loadUserData?.body]);

  useEffect(() => {
    if (!addingUserStatusData?.body?.is_available_to_add) {
      formik.setErrors({});
      clearForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingUserStatusData?.body?.is_available_to_add]);

  const addingUserStatus = addingUserStatusData?.body?.code;
  const apiError = createOrgAdminData?.error || addingOrgAdminRoleData?.error;
  const isLoading = isPendingAddingOrgAdminRole || isPendingCreateOrgAdmin;
  const currentEventScope = isSelectedOption
    ? PIN_TOKEN_EVENT_SCOPE.ADD_ORG_USER_ROLE
    : PIN_TOKEN_EVENT_SCOPE.CREATE_ORG_ADMIN;

  const isNotAvailableToAssignUser = addingUserStatusData?.body && !addingUserStatusData?.body?.is_available_to_add;
  const isDisableForm = (isSelectedOption && !!loadUserData?.body) || isNotAvailableToAssignUser;
  const formError = getResponseError(apiError);

  useEffect(() => {
    if (isNotAvailableToAssignUser) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminFailed);
    }
  }, [isNotAvailableToAssignUser]);

  return (
    <>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}

      <FormikForm value={formik}>
        <CreateOrgAdminAutocomplete
          selectedOption={selectedUser}
          selectedUserStatus={addingUserStatus}
          onSelect={setSelectedUser}
          apiError={apiError}
          error={formik.errors.email}
        />

        <FormikPhoneInput
          name='mobile_phone'
          label='Mobile Phone Number*'
          placeholder='Enter Mobile Phone Number With Country Code'
          apiError={apiError}
          disabled={isDisableForm}
        />

        <FormikInput
          name='first_name'
          label='First Name*'
          placeholder='Enter First Name'
          apiError={apiError}
          disabled={isDisableForm}
        />
        <FormikInput
          name='middle_name'
          label='Middle Name'
          placeholder='Enter Middle Name'
          apiError={apiError}
          disabled={isDisableForm}
        />
        <FormikInput
          name='last_name'
          label='Last Name*'
          placeholder='Enter Last Name'
          apiError={apiError}
          disabled={isDisableForm}
        />

        <Box display='flex' alignItems='center' columnGap='20px' mt='14px'>
          <Button variant='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isLoading} disabled={isNotAvailableToAssignUser}>
            Save
          </Button>
        </Box>
      </FormikForm>
      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          onSuccess={checkPinCodeSuccess}
          onClose={closePinCode}
          eventScope={currentEventScope}
        />
      )}
    </>
  );
};
