import { COLORS } from '@/styles/colors';
import { flexCenter } from '@/styles/layout';
import styled from 'styled-components';
import { BoxWrapper } from '../Box/styles';

export const IconButtonContainer = styled(BoxWrapper)<{ svgPath?: 'fill' | 'stroke' }>`
  ${flexCenter};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${COLORS.grey[300]};
  background-color: ${COLORS.white};
  cursor: pointer;

  &:hover {
    path {
      transition: fill 0.2s ease, stroke 0.2s ease;
      ${({ svgPath }) =>
        svgPath &&
        `
        ${[svgPath]}: ${COLORS.green[500]};
      `}
    }
  }
`;
