import { getExecutorAssignmentStatusApi } from '@/api/v1/organization/getExecutorAssignmentStatusApi';
import { AssignExecutorPayloadType, assignExecutorApi } from '@/api/v1/organization/wires/assignExecutorApi';
import { CreateExecutorPayloadType, createExecutorApi } from '@/api/v1/organization/wires/createExecutorApi';
import { UpdateExecutorPayloadType, updateExecutorApi } from '@/api/v1/organization/wires/updateExecutorApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { reinviteExecutorApi } from '@/api/v1/organization/wires/reinviteExecutorApi';

export const executorAssignmentStatus = async (userId: string) => {
  try {
    const response = await getExecutorAssignmentStatusApi(userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createExecutor = async (wireId: string, payload: CreateExecutorPayloadType) => {
  try {
    const response = await createExecutorApi(wireId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updateExecutor = async (wireId: string, payload: UpdateExecutorPayloadType) => {
  try {
    const response = await updateExecutorApi(wireId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const assignExecutor = async (wireId: string, userId: string) => {
  const payload: AssignExecutorPayloadType = {
    user_id: userId,
  };

  try {
    const response = await assignExecutorApi(wireId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const reinviteExecutor = async (wireId: string) => {
  try {
    const response = await reinviteExecutorApi(wireId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
