import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

type SetAuthPinCodePayloadType = {
  pin: string;
};

export const setAuthPinCodeApi = (data: SetAuthPinCodePayloadType): Promise<ApiResponseType<any>> => {
  const uri = '/users/auth/pin:set/';

  return authApiService.POST(uri, data);
};
