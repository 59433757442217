import styled, { css } from 'styled-components';
import { flex, flexCenter } from '../../../styles/layout';
import { resetButton } from '@/styles/common';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import { media } from '@/styles/responsive';

const buttonSize = css`
  width: 32px;
  height: 32px;
`;

const buttonSizeMobile = css`
  width: 40px;
  height: 40px;
`;

export const PaginatorWrapper = styled.div`
  ${flex()};
`;

export const PagesWrapper = styled.div`
  ${poppinsMediumFont};
  display: inline-flex;
  text-align: center;
  line-height: 16px;
  column-gap: 8px;
  margin: 0 8px;
`;

export const ArrowButton = styled.button`
  ${flexCenter};
  ${resetButton};
  ${buttonSize};
  cursor: pointer;

  ${media.tablet`
    ${buttonSizeMobile};

  `}

  &:hover:enabled {
    background: ${COLORS.grey[200]};
    border-radius: 4px;
  }

  &:disabled {
    path {
      stroke: ${COLORS.grey[400]};
    }
  }
`;

export const PaginatorActiveButton = styled.button`
  ${buttonSize};
  background: ${COLORS.grey[900]};
  border-radius: 4px;
  border: none;
  cursor: pointer;
  padding: 0 5px;
  font-weight: 400;
  ${bodySmall};
  text-align: center;
  color: ${COLORS.white};

  ${media.tablet`
    ${buttonSizeMobile};
  `}
`;

export const PaginatorNextButton = styled(PaginatorActiveButton)`
  background: none;
  box-shadow: none;
  border: none;
  color: ${COLORS.grey[700]};
  transition: 0.3s all linear;

  &:hover {
    background: ${COLORS.grey[200]};
  }
`;

export const PaginatorDots = styled.div`
  ${flex({ alignItems: 'center' })};
`;
