import { Button } from '@/components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { CreatePasswordMessage, ChangedPasswordIcon } from './styles';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { Box } from '@/components/Box/Box';

type PasswordChangedPageType = {
  onRedirect?: () => void;
};

export const PasswordChangedPage = ({ onRedirect }: PasswordChangedPageType) => {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    onRedirect?.();

    return navigate(getLoginPath());
  };

  return (
    <AuthLayout>
      <AuthStep center>
        <ChangedPasswordIcon />
        <CreatePasswordMessage>
          Your password has been <br /> successfully set
        </CreatePasswordMessage>

        <Box width='100%'>
          <Button onClick={redirectToLogin}>Return to Login</Button>
        </Box>
      </AuthStep>
    </AuthLayout>
  );
};
