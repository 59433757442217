import ProxyIcon from '@/images/ProxyIcon.svg?react';
import RecipientIcon from '@/images/RecipientIcon.svg?react';
import AlertIcon from '@/images/AlertOrangeIcon.svg?react';
import RecipientChainProxyIcon from '@/images/RecipientChainProxyIcon.svg?react';
import { ALertIconWrapper, MetaWrapper } from './styles';
import { SecureMetaWrapper } from '@/bundle/shared/components/PaymentDetailsHeaderSlot/styles';

export const PAYMENT_DETAILS_HEADER_SLOT_MAP = {
  CHAIN_PROXY: {
    header: 'This info was provided by the Recipient via Proxy.',
    icon: <RecipientChainProxyIcon />,
    meta: <SecureMetaWrapper>Secure</SecureMetaWrapper>,
  },
  PROXY: {
    header: 'This info was collected outside of WireVault.',
    icon: <ProxyIcon />,
    meta: (
      <ALertIconWrapper>
        <AlertIcon />
      </ALertIconWrapper>
    ),
  },
  RECIPIENT: {
    header: 'This info was provided directly by the Recipient.',
    icon: <RecipientIcon />,
    meta: <MetaWrapper>Secure</MetaWrapper>,
  },
};

export const getPaymentDetailsHeaderSlot = (isChainProxy: boolean, isProxy: boolean) => {
  if (isChainProxy) {
    return PAYMENT_DETAILS_HEADER_SLOT_MAP.CHAIN_PROXY;
  }

  if (isProxy) {
    return PAYMENT_DETAILS_HEADER_SLOT_MAP.PROXY;
  }

  return PAYMENT_DETAILS_HEADER_SLOT_MAP.RECIPIENT;
};
