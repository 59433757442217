import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AssignedWireType } from '@/types/wireTypes';
import { RecipientDetails } from '@/bundle/shared/components/RecipientDetails/RecipientDetails';
import { DocumentVerificationPassedDetails } from '@/bundle/shared/components/DocumentVerificationPassedDetails/DocumentVerificationPassedDetails';
import { getDocumentVerifiedRecipientFullName } from '@/bundle/_Recipient/_Details/ui/RecipientDocumentVerification/helpers';
import { WIRE_STATUS_DB } from '@/const/wire';
import {
  isExecutorInvitedWireStatus,
  isRecipientInvitedWireStatus,
  isRecipientProxy,
  isRecipientProxyInvitedWireStatus,
} from '@/helpers/wireHelpers';
import { getRecipientTrustStampStatusByOrgAdmin } from '../../api';
import { UserDetailsWidget } from '@/bundle/shared/components/UserDetailsWidget/UserDetailsWidget';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';
import { ReInvitationDetails } from '@/bundle/shared/components/UserDetailsWidget/ReInvitationDetails';
import { reinviteExecutor } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/api/executorApi';
import { useState } from 'react';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { useTimer } from '@/hooks/useTimer';

type OutboundWireUserDetailsType = {
  wire: AssignedWireType;
  isShowExecutorForm: boolean;
  isShowEditExecutorForm: boolean;
  isShowEditRecipientForm: boolean;
  onEditExecutorForm: () => void;
  onEditRecipientForm: () => void;
};

export const OutboundWireUserDetails = ({
  wire,
  isShowExecutorForm,
  isShowEditExecutorForm,
  isShowEditRecipientForm,
  onEditExecutorForm,
  onEditRecipientForm,
}: OutboundWireUserDetailsType) => {
  const queryClient = useQueryClient();
  const outboundWireStatus = wire?.status;
  const isProxy = isRecipientProxy(wire);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const refetchOutboundWire = () => {
    queryClient.invalidateQueries({ queryKey: ['load_outbound_wire', wire?.id] });
  };

  const { seconds } = useTimer(
    wire?.assigned_executor?.user?.invitation_data?.next_resend_available_in_sec,
    refetchOutboundWire
  );

  const hasAssignedRecipient = isProxy ? wire?.assigned_proxy_user : wire?.assigned_recipient;
  const isRecipientProxyInvited = isRecipientProxyInvitedWireStatus(outboundWireStatus);
  const isRecipientInvited = isRecipientInvitedWireStatus(outboundWireStatus);

  const shouldShowDocumentVerificationPassedDetails = !!(
    wire?.is_pd_provider_kyc_required &&
    wire?.view_payment_details_event_register &&
    wire?.status !== WIRE_STATUS_DB.RECIPIENT_ASSIGNED &&
    wire?.status !== WIRE_STATUS_DB.RECIPIENT_PROXY_ASSIGNED
  );

  const { data: trustStampStatusData } = useQuery({
    queryKey: ['load_trust_stamp_status_by_org_admin', wire?.id],
    queryFn: () => getRecipientTrustStampStatusByOrgAdmin(wire?.id),
    enabled: shouldShowDocumentVerificationPassedDetails,
  });

  const { mutate: reinviteExecutorMutate } = useMutation({
    mutationKey: ['reinvite_executor', wire?.id],
    mutationFn: () => {
      return reinviteExecutor(wire?.id);
    },
    onSuccess: (reinvitedExecutorResponse) => {
      if (reinvitedExecutorResponse?.error) {
        const error = getResponseError(reinvitedExecutorResponse?.error);

        showSnackbar(error, { variant: 'error' });
        refetchOutboundWire();

        return setIsOpenModal(false);
      }

      refetchOutboundWire();
      setIsOpenModal(false);
    },
  });
  const documentVerifiedRecipientFullName = getDocumentVerifiedRecipientFullName(trustStampStatusData?.body);

  const hasExecutor = wire?.assigned_executor && !isShowExecutorForm && !isShowEditExecutorForm;
  const isExecutorInvited = isExecutorInvitedWireStatus(outboundWireStatus);

  return (
    <>
      {hasAssignedRecipient && !isShowEditRecipientForm && (
        <RecipientDetails
          wire={wire}
          isRecipientEdit={isProxy ? isRecipientProxyInvited : isRecipientInvited}
          onEditRecipient={onEditRecipientForm}
        />
      )}

      {shouldShowDocumentVerificationPassedDetails && (
        <DocumentVerificationPassedDetails fullName={documentVerifiedRecipientFullName} />
      )}

      {hasExecutor && (
        <UserDetailsWidget
          header='Executor'
          user={wire?.assigned_executor?.user}
          action={isExecutorInvited && <DetailsEditButton onEdit={onEditExecutorForm} />}
          hasInvitedNotification={isExecutorInvited}
        >
          {isExecutorInvited && (
            <ReInvitationDetails
              onConfirm={reinviteExecutorMutate}
              invitationDetails={wire?.assigned_executor?.user?.invitation_data}
              onOpen={() => setIsOpenModal(true)}
              onClose={() => setIsOpenModal(false)}
              isOpen={isOpenModal}
              seconds={seconds}
            />
          )}
        </UserDetailsWidget>
      )}
    </>
  );
};
