import { useEffect, useState } from 'react';
import { Notification } from '@/components/Notification/Notification';
import { CancelButton, TermsLink } from './styles';
import { Checkbox } from '@/components/form/fields/Checkbox/Checkbox';
import { Button } from '@/components/Button/Button';
import { AuthStep } from '@/bundle/Auth/ui/AuthStep/AuthStep';
import { authTokenService } from '@/bundle/shared/services/authTokenService';
import { decodeToken } from '@/helpers/tokenHelpers';
import { formatPhone } from '@/helpers/formatHelpers';
import { useNavigate } from 'react-router-dom';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { AuthTokenType, ResponseErrorType } from '@/types/sharedTypes';
import { envConfig } from '@/config/envConfig';
import { Typography } from '@/components/Typography/Typography';
import { Box } from '@/components/Box/Box';
import { COLORS } from '@/styles/colors';

type CompleteRegistrationFormType = {
  onConfirm: () => void;
  isLoading: boolean;
  error?: ResponseErrorType;
};

export const CompleteRegistrationForm = ({ onConfirm, isLoading, error }: CompleteRegistrationFormType) => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState('');
  const [completeRegistrationTerms, setCompleteRegistrationTerms] = useState({
    checkedTerms: false,
    checkedPhone: false,
    checkedResetPassword: false,
  });

  const setUserPhone = () => {
    const tokens = authTokenService.getTokens();

    if (!tokens) return;

    const { mobile_phone } = decodeToken<AuthTokenType>(tokens.access);

    setPhone(formatPhone(mobile_phone));
  };

  useEffect(() => {
    setUserPhone();
  }, []);

  const toggleTerms = () => {
    setCompleteRegistrationTerms((values) => ({ ...values, checkedTerms: !values.checkedTerms }));
  };

  const togglePhone = () => {
    setCompleteRegistrationTerms((values) => ({ ...values, checkedPhone: !values.checkedPhone }));
  };

  const toggleResetPassword = () => {
    setCompleteRegistrationTerms((values) => ({ ...values, checkedResetPassword: !values.checkedResetPassword }));
  };

  const navigateToLogin = () => {
    navigate(getLoginPath());
  };

  const hasCheckedAllTerms = Object.values(completeRegistrationTerms).every(Boolean);
  const termsConditionsUrl = envConfig.TERMS_AND_CONDITIONS_URL;
  const apiError = error?.error_content as string;

  return (
    <AuthStep width='508px'>
      <Typography textAlign='start' variant='headline4' fontWeight='semibold' color={COLORS.grey[900]} mb='12px'>
        Complete Registration
      </Typography>
      <Typography color={COLORS.grey[950]} mb='32px'>
        To finish your account registration with WireVault, please confirm the following:
      </Typography>

      {apiError && (
        <Notification variant='error' mb='24px'>
          {apiError}
        </Notification>
      )}
      <Box display='flex' flexDirection='column' rowGap='28px'>
        <Box display='flex' flexDirection='column' rowGap='20px'>
          <Box as='label' display='flex' alignItems='flex-start' columnGap='18px'>
            <Box mt='4px'>
              <Checkbox onChange={toggleTerms} checked={completeRegistrationTerms.checkedTerms} />
            </Box>
            <Typography color={COLORS.grey[950]}>
              I have read and agree to the WireVault
              <br />
              <TermsLink href={termsConditionsUrl} target='_blank'>
                Terms & Conditions
              </TermsLink>
            </Typography>
          </Box>
          <Box as='label' display='flex' alignItems='flex-start' columnGap='18px'>
            <Box mt='4px'>
              <Checkbox onChange={togglePhone} checked={completeRegistrationTerms.checkedPhone} />
            </Box>
            <Typography color={COLORS.grey[950]}>
              I confirm that I am authorized to use the mobile phone number{' '}
              <Typography as='span' color={COLORS.grey[950]} fontWeight='semibold'>
                {phone}
              </Typography>{' '}
              and agree to receive SMS messages to secure my WireVault account
            </Typography>
          </Box>
          <Box as='label' display='flex' alignItems='flex-start' columnGap='18px'>
            <Box mt='4px'>
              <Checkbox onChange={toggleResetPassword} checked={completeRegistrationTerms.checkedResetPassword} />
            </Box>
            <Typography color={COLORS.grey[950]}>
              I agree to reset and use a strong password with 2-factor authentication for the email provided with
              WireVault.
            </Typography>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' rowGap='12px'>
          <Button isLoading={isLoading} onClick={onConfirm} disabled={!hasCheckedAllTerms}>
            Complete Registration
          </Button>
          <CancelButton onClick={navigateToLogin}>Cancel Registration</CancelButton>
        </Box>
      </Box>
    </AuthStep>
  );
};
