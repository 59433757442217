import { decodeToken } from '@/helpers/tokenHelpers';
import { getTwoFAPath } from '@/bundle/Auth/LoginFlow/TwoFAPage/path/path';
import { getPinCodePath } from '@/bundle/Auth/LoginFlow/CheckPinCodePage/path/path';
import { AuthTokenType, ValueOf } from '@/types/sharedTypes';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { getPersonalDetailsVerificationPath } from '@/bundle/Auth/LoginFlow/PersonalDetailsVerificationPage/path/path';
import {
  LATIN_CHARACTERS,
  MIN_MAX_PASSWORD_LENGTH,
  LOWER_CASE_CHARACTER,
  NO_SPACES,
  ONE_DIGIT,
  SPECIAL_CHARACTERS,
  UPPER_CASE_CHARACTER,
} from '@/const/regExpPatterns';
import { getForgotPasswordTwoFAPath } from '../ForgotPasswordFlow/ForgotPasswordTwoFAPage/path/path';
import { getForgotPasswordSetPasswordPath } from '../ForgotPasswordFlow/ForgotPasswordSetPasswordPage/path/path';
import { getForgotPasswordCompleteRegistrationPath } from '../ForgotPasswordFlow/ForgotPasswordCompleteRegistrationPage/path/path';
import { getForgotPasswordCheckPinCodePath } from '../ForgotPasswordFlow/ForgotPasswordCheckPinCodePage/path/path';
import { getCompleteRegistrationPath } from '../InviteUserFlow/CompleteRegistrationPage/path/path';
import { getCreatePinCodePath } from '../InviteUserFlow/CreatePinCodePage/path/path';

export const AUTH_STEP = {
  TWO_FACTOR_AUTHENTICATION: '2fa_authentication',
  PIN_AUTHENTICATION: 'pin_authentication',
  COMPLETE_REGISTRATION: 'complete_registration',
  SET_PIN: 'set_pin',
  SET_NAME_VERIFICATION: 'name_verification',
} as const;

export const FORGOT_PASSWORD_STEP = {
  COMPLETE_REGISTRATION: 'fp_complete_registration',
  TWO_FACTOR_AUTHENTICATION: 'fp_2fa_authentication',
  PIN_AUTHENTICATION: 'fp_pin_authentication',
  SET_PASSWORD: 'fp_set_password',
} as const;

export type AuthStepType = ValueOf<typeof AUTH_STEP>;
export type ForgotPasswordStepType = ValueOf<typeof FORGOT_PASSWORD_STEP>;

export const AUTH_STEP_URL_MAPPER = {
  [AUTH_STEP.TWO_FACTOR_AUTHENTICATION]: getTwoFAPath(),
  [AUTH_STEP.PIN_AUTHENTICATION]: getPinCodePath(),
  [AUTH_STEP.COMPLETE_REGISTRATION]: getCompleteRegistrationPath(),
  [AUTH_STEP.SET_PIN]: getCreatePinCodePath(),
  [AUTH_STEP.SET_NAME_VERIFICATION]: getPersonalDetailsVerificationPath(),
};

export const FORGOT_PASSWORD_URL_MAPPER = {
  [FORGOT_PASSWORD_STEP.COMPLETE_REGISTRATION]: getForgotPasswordCompleteRegistrationPath(),
  [FORGOT_PASSWORD_STEP.TWO_FACTOR_AUTHENTICATION]: getForgotPasswordTwoFAPath(),
  [FORGOT_PASSWORD_STEP.PIN_AUTHENTICATION]: getForgotPasswordCheckPinCodePath(),
  [FORGOT_PASSWORD_STEP.SET_PASSWORD]: getForgotPasswordSetPasswordPath(),
};

export const getAuthNextStepPath = (authToken: string) => {
  const loginPath = getLoginPath();
  const { step } = decodeToken<AuthTokenType>(authToken);

  return AUTH_STEP_URL_MAPPER[step] || loginPath;
};

export const getForgotPasswordNextStepPath = (authToken: string) => {
  const loginPath = getLoginPath();
  const { step } = decodeToken<AuthTokenType>(authToken);

  return FORGOT_PASSWORD_URL_MAPPER[step] || loginPath;
};

export const validatePassword = (password?: string) => {
  const hasMinMaxLength = password && MIN_MAX_PASSWORD_LENGTH.test(password);
  const hasOneLowerCaseCharacter = password && LOWER_CASE_CHARACTER.test(password);
  const hasOneUpperCaseCharacter = password && UPPER_CASE_CHARACTER.test(password);
  const hasOneDigit = password && ONE_DIGIT.test(password);
  const hasSpecialCharacter = password && SPECIAL_CHARACTERS.test(password);
  const hasNoSpaces = password && NO_SPACES.test(password);
  const hasLatinCharacters = password && LATIN_CHARACTERS.test(password);

  const isValid =
    hasMinMaxLength &&
    hasOneLowerCaseCharacter &&
    hasOneUpperCaseCharacter &&
    hasOneDigit &&
    hasSpecialCharacter &&
    hasNoSpaces &&
    hasLatinCharacters;

  return {
    hasMinMaxLength,
    hasOneLowerCaseCharacter,
    hasOneUpperCaseCharacter,
    hasOneDigit,
    hasSpecialCharacter,
    hasNoSpaces,
    hasLatinCharacters,
    isValid,
  };
};
