import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { LoginHintWrapper } from './styles';
import { login } from './api';
import { useMutation } from '@tanstack/react-query';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { Box } from '@/components/Box/Box';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { LOGIN_VALIDATION_SCHEMA } from './const/const';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { Notification } from '@/components/Notification/Notification';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikPasswordInput } from '@/components/form/fields/FormikPasswordInput/FormikPasswordInput';
import { Button } from '@/components/Button/Button';
import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { AuthStep } from '@/bundle/Auth/ui/AuthStep/AuthStep';
import { useEffect, useRef, useState } from 'react';
import { envConfig } from '@/config/envConfig';
import { useAuthNavigateToStep } from '../../hooks/useAuthNavigateToStep';
import { ExternalLink } from '@/components/ExternalLink/ExternalLink';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { LoginPayload } from '@/api/v1/users/public/loginApi';
import { GoogleReCaptcha, GoogleReCaptchaType } from '@/components/GoogleReCaptcha/GoogleReCaptcha';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { getForgotPasswordCheckEmailPath } from '../../ForgotPasswordFlow/ForgotPasswordCheckEmailPage/path/path';
import { useTranslation } from 'react-i18next';

const ERROR_NOTIFICATION_OFFSET = { mb: '24px' };
const CREATE_ACCOUNT_URL = `${envConfig.LANDING_URL}create-account/free?hasIdVerification=true&billingFrequency=yearly`;

export const LoginPage = () => {
  const { state } = useLocation();
  const navigateToStep = useAuthNavigateToStep();
  const recaptchaRef = useRef<GoogleReCaptchaType>();
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const { t } = useTranslation(['login', 'form']);

  useEffect(() => {
    // Clear expired history state on page reload
    if (state?.sessionExpired) {
      window.history.replaceState(null, '');
    }

    // Clear account blocked state on page reload
    if (state?.accountBlocked) {
      window.history.replaceState(null, '');
    }
  }, [state?.sessionExpired, state?.accountBlocked]);

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['login'],
    mutationFn: (payload: LoginPayload) => {
      return login(payload);
    },
    onSuccess(loginResponse) {
      if (loginResponse?.error) {
        recaptchaRef.current?.resetCaptcha();

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.LogINEmailPwdCheckSuccess);

      return navigateToStep(loginResponse?.body);
    },
    retry: false,
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setCaptchaLoading(true);

      recaptchaRef.current
        ?.executeCaptcha()
        .then((token) => {
          setCaptchaLoading(false);

          const payload = {
            email: values.email,
            password: values.password,
            recaptcha_response: token,
          };

          mutate(payload);
        })
        .catch((error) => {
          console.error('google captcha failed with', error?.message);

          setCaptchaLoading(false);
        });
    },
    enableReinitialize: true,
    validationSchema: LOGIN_VALIDATION_SCHEMA,
  });

  const error = data?.error;
  const formError = getResponseError(data?.error) || getResponseError(data?.error, 'recaptcha_response');

  return (
    <AuthLayout>
      <AuthStep>
        <Typography variant='headline4' fontWeight='semibold' color={COLORS.grey[900]} mb='28px'>
          {t('login:loginHeader')}
        </Typography>

        {state?.sessionExpired && !error && (
          <Notification variant='error' {...ERROR_NOTIFICATION_OFFSET}>
            {t('login:sessionExpiredNotification')}
          </Notification>
        )}

        {state?.accountBlocked && !error && (
          <Notification variant='error' {...ERROR_NOTIFICATION_OFFSET}>
            {t('login:forbiddenAccessNotification')}
          </Notification>
        )}

        {formError && (
          <Notification variant='error' {...ERROR_NOTIFICATION_OFFSET}>
            {formError}
          </Notification>
        )}
        <FormikForm value={formik}>
          <FormikInput
            label={t('form:label.email')}
            placeholder={t('form:placeholder.email')}
            name='email'
            apiError={error}
          />
          <FormikPasswordInput
            label={t('form:label.password')}
            placeholder={t('form:placeholder.password')}
            name='password'
            apiError={error}
            hasToggleEye
            labelHint={
              <NavigationLink
                to={getForgotPasswordCheckEmailPath()}
                onClick={() => amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordStartRedirect)}
              >
                {t('login:forgotPasswordLink')}
              </NavigationLink>
            }
          />
          <GoogleReCaptcha ref={recaptchaRef} />
          <Box mt='12px' mb='24px'>
            <Button isLoading={isPending || captchaLoading} type='submit' onClick={formik.handleSubmit}>
              {t('login:loginButtonLabel')}
            </Button>
          </Box>
        </FormikForm>
        <LoginHintWrapper>
          <Typography variant='bodySmall'>{t('login:newToWireVaultText')}</Typography>
          <ExternalLink href={CREATE_ACCOUNT_URL} target='_blank' variant='small'>
            {t('login:createFreeAccountLink')}
          </ExternalLink>
        </LoginHintWrapper>
      </AuthStep>
    </AuthLayout>
  );
};
