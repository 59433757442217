import { getRecipientAssignmentStatusApi } from '@/api/v1/organization/getRecipientAssignmentStatusApi';
import { AssignRecipientPayloadType, assignRecipientApi } from '@/api/v1/organization/wires/assignRecipientApi';
import { CreateRecipientPayloadType, createRecipientApi } from '@/api/v1/organization/wires/createRecipientApi';
import { UpdateRecipientPayloadType, updateRecipientApi } from '@/api/v1/organization/wires/updateRecipientApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { reinviteRecipientApi } from '@/api/v1/organization/wires/reinviteRecipientApi';
import { reinviteRecipientProxyApi } from '@/api/v1/organization/wires/reinviteRecipientProxyApi';

export const createRecipient = async (wireId: string, payload: CreateRecipientPayloadType) => {
  try {
    const response = await createRecipientApi(wireId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updateRecipient = async (wireId: string, userId: string, payload: UpdateRecipientPayloadType) => {
  try {
    const response = await updateRecipientApi(wireId, userId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const assignRecipient = async (wireId: string, payload: AssignRecipientPayloadType) => {
  try {
    const response = await assignRecipientApi(wireId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const recipientAssignmentStatus = async (userId: string) => {
  try {
    const response = await getRecipientAssignmentStatusApi(userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const reinviteRecipient = async (wireId: string) => {
  try {
    const response = await reinviteRecipientApi(wireId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const reinviteRecipientProxy = async (wireId: string) => {
  try {
    const response = await reinviteRecipientProxyApi(wireId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
