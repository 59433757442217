import { Box } from '@/components/Box/Box';
import LogoSmallIcon from '@/images/LogoSmall.svg?react';
import {
  UserDetailsCard,
  UserDetailsRow,
  UserDetailsRowTitle,
  UserDetailsRowValue,
} from '@/bundle/_Opco/shared/styles';
import { SelfUserDetailsType } from '@/api/v1/users/getSelfUserDetailsApi';
import { formatPhone } from '@/helpers/formatHelpers';
import { UserDetailsHeader } from './UserDetailsHeader/UserDetailsHeader';
import { UserRoleList } from './UserRoleList/UserRoleList';
import { UserRoleType } from '@/const/user';

interface UserGeneralDetailsType {
  user: SelfUserDetailsType;
  currentRole: UserRoleType;
}

export const UserGeneralDetails = ({ user, currentRole }: UserGeneralDetailsType) => {
  const formattedMobilePhone = formatPhone(user?.mobile_phone);

  return (
    <Box flexDirection='column' width='100%'>
      <UserDetailsHeader title='WireVault Account Info' icon={<LogoSmallIcon />} />
      <UserDetailsCard>
        <UserDetailsRow>
          <UserDetailsRowTitle>Email Address</UserDetailsRowTitle>
          <UserDetailsRowValue>{user?.email}</UserDetailsRowValue>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserDetailsRowTitle>Mobile Phone Number</UserDetailsRowTitle>
          <UserDetailsRowValue>{formattedMobilePhone}</UserDetailsRowValue>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserRoleList activeRole={currentRole} user={user} />
        </UserDetailsRow>
      </UserDetailsCard>
    </Box>
  );
};
