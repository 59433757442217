import { useNavigate, useParams } from 'react-router-dom';
import { EmptyTableResults, Table, TableBody, TableCell, TableRow } from '@/components/Table/styles';
import { ORGANIZATIONS_USERS_TABLE_CONFIG } from '../const/const';
import { TableType } from '@/components/Table/types/types';
import { TableHeaderCell } from '@/components/Table/TableHeaderCell/TableHeaderCell';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { UtcTooltip } from '@/bundle/_Opco/ui/UtcTooltip/UtcTooltip';
import { NoResults } from '@/components/NoResults/NoResults';
import CreateUserIcon from './images/CreateUserIcon.svg?react';
import { TableHeader } from '@/components/Table/TableHeader/TableHeader';
import { getOrganizationUserDetailsPath } from '../Details/path/path';

export const OrganizationUsersTable = ({ items = [], isLoading }: TableType<OrganizationUserType>) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const hasItems = !!items.length;

  const navigateToUserDetails = (userId: string) => {
    const url = getOrganizationUserDetailsPath(id, userId);

    navigate(url);
  };

  const noUsers = !hasItems && !isLoading;

  if (noUsers) {
    return (
      <EmptyTableResults>
        <NoResults marginTop={0} icon={<CreateUserIcon />} message='Please create your first user.' />
      </EmptyTableResults>
    );
  }

  return (
    hasItems && (
      <Table>
        <TableHeader>
          {ORGANIZATIONS_USERS_TABLE_CONFIG.map(({ key, name, width, hasSort, header, hasTooltip }) => {
            return (
              <TableHeaderCell key={key} name={name} width={width} hasSort={hasItems && hasSort}>
                {hasTooltip && <UtcTooltip message='Last login is recorded in UTC' />}
                {header}
              </TableHeaderCell>
            );
          })}
        </TableHeader>
        <TableBody>
          {items.map((item: OrganizationUserType) => {
            return (
              <TableRow key={item.id} onClick={() => navigateToUserDetails(item.id)} isClickableRow>
                {ORGANIZATIONS_USERS_TABLE_CONFIG.map(({ key, width, getValue }) => {
                  const { value, title } = getValue(item);

                  return (
                    <TableCell key={key} width={width} title={title}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    )
  );
};
