import { COLORS } from '@/styles/colors';
import styled, { css, keyframes } from 'styled-components';

export const ERROR_ANIMATION_DELAY = 500;

const errorShake = keyframes`
  0% {
    transform: translateX(0);
    background-color: ${COLORS.red[500]};
  }
  35% {
    transform: translateX(-7px);
    background-color: ${COLORS.red[500]};
  }
  70% {
    transform: translateX(7px);
    background-color: ${COLORS.red[500]};
  }
  100% {
    transform: translateX(0);
    background-color: ${COLORS.red[500]};
  }
`;

export const Dot = styled.div<{ hasValue: boolean; hasError: boolean; success?: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ hasValue }) => (hasValue ? `${COLORS.black}` : `${COLORS.grey[400]}`)};
  transition: transform 0.2s ease;

  ${({ hasValue }) =>
    hasValue &&
    `
      transform: scale(1.15);
    `};

  ${({ hasError }) =>
    hasError &&
    css`
      transform: scale(1.15);
      animation: ${ERROR_ANIMATION_DELAY}ms ${errorShake} ease;
    `};
`;
