import { Box } from '@/components/Box/Box';
import { NoResults } from '@/components/NoResults/NoResults';
import BoxIcon from './images/BoxIcon.svg?react';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { ResponseErrorType } from '@/types/sharedTypes';
import { Button } from '@/components/Button/Button';

type NoContentLayoutType = {
  apiError?: ResponseErrorType;
  backButton?: string;
  onBack?: () => void;
};

export const NoContentLayout = ({ apiError, onBack, backButton }: NoContentLayoutType) => {
  return (
    <Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
      <NoResults icon={<BoxIcon />} message={getResponseError(apiError)} marginTop={0} />
      <Box display='flex' justifyContent='center'>
        <Button width='auto' onClick={onBack}>
          {backButton}
        </Button>
      </Box>
    </Box>
  );
};
