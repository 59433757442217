import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';

export const LoginHintWrapper = styled.div`
  ${flex({ alignItems: 'center' })};
  column-gap: 5px;

  ${media.mobile`
    flex-direction: column;
    gap: 6px;
  `}
`;
