import { Button } from '@/components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { AuthMessage } from '../../ui/styles';
import { AuthTitle } from '../../ui/AuthTitle/AuthTitle';
import { Icon } from './styles';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { envConfig } from '@/config/envConfig';
import { ExternalLink } from '@/components/ExternalLink/ExternalLink';
import { Box } from '@/components/Box/Box';

type SetPasswordLinkExpiredPageType = {
  onRedirect?: () => void;
};

export const SetPasswordLinkExpiredPage = ({ onRedirect }: SetPasswordLinkExpiredPageType) => {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    onRedirect?.();

    return navigate(getLoginPath());
  };

  const contactUsUrl = `${envConfig.LANDING_URL}?contactUs=true`;

  return (
    <AuthLayout>
      <AuthStep>
        <Box alignItems='center' flexDirection='column'>
          <Icon />
          <AuthTitle>The link has been expired</AuthTitle>
          <Box mb='32px'>
            <AuthMessage>
              Please login with the set password or create new password via "Forgot password?" link or{' '}
              <ExternalLink href={contactUsUrl} target='_blank'>
                contact us
              </ExternalLink>
              .
            </AuthMessage>
          </Box>
          <Button onClick={redirectToLogin}>Return to Login</Button>
        </Box>
      </AuthStep>
    </AuthLayout>
  );
};
