import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createDepositAccount } from './api';
import { FormValues, isValidForm } from '@/helpers/formHelpers/formHelpers';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { CreateDepositAccountForm, CreateDepositAccountFormButton, CreateDepositAccountWrapper } from './styles';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { ConfirmModal, ConfirmModalHint } from '@/components/ConfirmModal/ConfirmModal';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import {
  CREATE_DEPOSIT_ACCOUNT_REDIRECT,
  CREATE_DEPOSIT_ACCOUNT_SUCCESS,
  getDepositAccountAmplitudeEvent,
} from '@/bundle/_OrgAdmin/pages/DepositAccounts/_Create/const/amplitudeEventsMap';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { PaymentType } from '@/types/paymentDetailsTypes';
import { PAYMENT_TYPE_DB } from '@/const/shared';
import { isDomesticPaymentType, isInternationalPaymentType } from '@/helpers/paymentDetailsHelpers';
import {
  CREATE_DEPOSIT_ACCOUNT_INITIAL_VALUES,
  CreteDepositAccountFormType,
  getCreateDepositAccountSchema,
} from './const/const';
import {
  getPaymentDetailsPayloadValues,
  INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_TOUCHED,
  INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
} from '@/bundle/shared/components/ProvidePaymentDetailsForm/const/const';
import { ProvidePaymentDetailsForm } from '@/bundle/shared/components/ProvidePaymentDetailsForm/ProvidePaymentDetailsForm';
import { useBankVerification } from './hooks/useBankVerification';
import { getDepositAccountsPath } from '../path/path';
import { getDepositAccountDetailsPath } from '../_Details/path/path';

export const CreateDepositAccountPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isOpenIntermediaryBank, setIsOpenIntermediaryBank] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>(PAYMENT_TYPE_DB.DOMESTIC);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDomesticPayment = isDomesticPaymentType(selectedPaymentType);
  const isInternationalPayment = isInternationalPaymentType(selectedPaymentType);

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['create_deposit_account'],
    mutationFn: (payload: FormValues) => {
      return createDepositAccount(payload, isDomesticPayment);
    },
    onSuccess(createdDepositAccount) {
      if (createdDepositAccount.error) return;

      const currentEvent = getDepositAccountAmplitudeEvent(
        isDomesticPayment,
        isInternationalPayment,
        isOpenIntermediaryBank
      );

      amplitudeService.logEvent(CREATE_DEPOSIT_ACCOUNT_SUCCESS[currentEvent]);

      const { state } = location;
      const newState = state?.transactionId ? { transactionId: state?.transactionId, wireId: state?.wireId } : state;
      const id = createdDepositAccount?.body?.id;

      queryClient.invalidateQueries({ queryKey: ['load_deposit_account', id] });
      navigate(getDepositAccountDetailsPath(id), { state: newState });
    },
    onSettled() {
      setIsModalOpen(false);
    },
  });

  const formik = useFormik<CreteDepositAccountFormType>({
    initialValues: CREATE_DEPOSIT_ACCOUNT_INITIAL_VALUES,
    validationSchema: getCreateDepositAccountSchema(selectedPaymentType, isOpenIntermediaryBank),
    onSubmit: async (values) => {
      const payload = getPaymentDetailsPayloadValues(values, selectedPaymentType, isOpenIntermediaryBank);

      await mutate(payload);
    },
  });

  const bankVerification = useBankVerification(formik, isOpenIntermediaryBank, isDomesticPayment);

  const submitHandler = () => {
    formik.handleSubmit();
  };

  const navigateToDepositAccounts = () => {
    navigate(getDepositAccountsPath());
  };

  const openConfirmationModal = async () => {
    const isValid = await isValidForm(formik);

    if (!isValid) return;

    const currentEvent = getDepositAccountAmplitudeEvent(
      isDomesticPayment,
      isInternationalPayment,
      isOpenIntermediaryBank
    );

    amplitudeService.logEvent(CREATE_DEPOSIT_ACCOUNT_REDIRECT[currentEvent]);

    setIsModalOpen(true);
  };

  const selectPaymentType = (paymentType: PaymentType) => {
    formik.resetForm({
      values: {
        ...CREATE_DEPOSIT_ACCOUNT_INITIAL_VALUES,
        name: formik.values.name,
      },
    });

    setSelectedPaymentType(paymentType);
  };

  const toggleIntermediaryForm = (value: boolean) => {
    setIsOpenIntermediaryBank(value);

    formik.setValues({
      ...formik.values,
      ...INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
    });

    formik.setTouched({
      ...formik.touched,
      ...INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_TOUCHED,
    });
  };

  return (
    <ContentLayout
      title='Create Deposit Account'
      backButton='Back to Deposit Accounts'
      onBack={navigateToDepositAccounts}
    >
      <CreateDepositAccountWrapper>
        <CreateDepositAccountForm>
          <FormikForm value={formik}>
            <FormikInput
              name='name'
              label='Deposit Account Name*'
              placeholder='Enter Deposit Account Name'
              autoComplete='new-field'
              apiError={data?.error}
            />

            <ProvidePaymentDetailsForm
              apiError={data?.error}
              selectedPaymentType={selectedPaymentType}
              onSelectPaymentType={selectPaymentType}
              isOpenIntermediaryBank={isOpenIntermediaryBank}
              onOpenIntermediaryBank={toggleIntermediaryForm}
              bankVerification={bankVerification}
            />
          </FormikForm>

          <CreateDepositAccountFormButton>
            <Button width={160} size='medium' onClick={openConfirmationModal}>
              Submit
            </Button>
          </CreateDepositAccountFormButton>
        </CreateDepositAccountForm>
      </CreateDepositAccountWrapper>

      <ConfirmModal
        isOpen={isModalOpen}
        header='Confirm Deposit Account'
        isLoading={isPending}
        body={
          <>
            <ConfirmModalHint>You are certifying the validity of your deposit account information.</ConfirmModalHint>
            <ConfirmModalHint>
              This information will be committed to the WireVault blockchain and cannot be changed.
            </ConfirmModalHint>
            <ConfirmModalHint>
              You can deactivate this deposit account at anytime and create a new one.
            </ConfirmModalHint>
          </>
        }
        onClose={() => setIsModalOpen(false)}
        onConfirm={submitHandler}
      />
    </ContentLayout>
  );
};
