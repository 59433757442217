import { addSeconds, format, parseISO } from 'date-fns';
import { isNullOrUndefined } from '../objectHelpers';

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_TIME_FORMAT = 'MM/dd/yyyy - H:mm';
export const REVERSED_DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const FULL_DATE_TIME_FORMAT = 'LLL dd, yyyy, H:mm:ss';

type DateFormatType = typeof DATE_TIME_FORMAT | typeof FULL_DATE_TIME_FORMAT;

export const formatPayloadDate = (date: string | Date, dateFormat: string = REVERSED_DEFAULT_DATE_FORMAT) => {
  if (!date) return null;

  return format(new Date(date), dateFormat);
};

// TODO: Not working in case when provided seconds > 60 minutes
export const formatSecondsToMinutes = (seconds: number) => {
  if (isNullOrUndefined(seconds)) return;

  const helperDate = addSeconds(new Date(0), seconds);

  return format(helperDate, 'mm:ss');
};

export const formatDateTimeUTC = (date: string | Date, dateFormat: DateFormatType = DATE_TIME_FORMAT) => {
  if (!date) return '-';

  const value = date instanceof Date ? date : Date.parse(date?.replace('Z', ''));
  const result = format(value, dateFormat);

  return result;
};

export const formatDate = (date: string | Date, dateFormat = DEFAULT_DATE_FORMAT) => {
  if (!date) return '-';

  const value = date instanceof Date ? date : parseISO(date);

  return format(value, dateFormat);
};
