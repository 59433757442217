import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { OpcoUserAccountDetails } from './ui/OpcoUserAccountDetails';
import { loadOpcoUser } from './api';
import { UserPersonalInfo } from '@/bundle/shared/components/UserDetails/UserPersonalInfo';
import { useUser } from '@/context/userContext';
import { useState } from 'react';
import { Drawer } from '@/components/Drawer/Drawer';
import { EditOpcoPersonalDetailsForm } from '@/bundle/_Opco/_OpcoUsersPage/Details/ui/EditOpcoPersonalDetailsForm';
import { isBlockedOpcoUserStatus } from '@/bundle/_Opco/_OpcoUsersPage/helpers/opcoUserHelpers';
import { Wrapper } from '@/bundle/_Opco/_OpcoUsersPage/Details/styles';
import { getOpcoUsersPath } from '../path/path';

export const OpcoUserDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { user: currentUser } = useUser();

  const [openForm, setOpenForm] = useState(false);

  const { data } = useQuery({
    queryKey: ['load_opco_user', id],
    queryFn: () => loadOpcoUser(id),
  });

  const opcoUser = data?.body;
  const isBlockedOpco = isBlockedOpcoUserStatus(opcoUser?.user?.status);

  const isEditableForm = currentUser?.id === id && !isBlockedOpco;

  const personalDetails = {
    first_name: opcoUser?.user?.first_name,
    middle_name: opcoUser?.user?.middle_name,
    last_name: opcoUser?.user?.last_name,
  };

  const refetchUserInfo = () => {
    queryClient.invalidateQueries({ queryKey: ['load_opco_user'] });
  };

  const closeEditForm = () => setOpenForm(false);

  return (
    <ContentLayout
      title='opco user details'
      backButton='Back to Opco Users'
      onBack={() => navigate(getOpcoUsersPath())}
    >
      <PageBody>
        {opcoUser && (
          <Wrapper>
            <OpcoUserAccountDetails userDetails={opcoUser} onSuccess={refetchUserInfo} />
            <UserPersonalInfo onOpen={setOpenForm} personalDetails={personalDetails} isEdit={isEditableForm} />
          </Wrapper>
        )}
        <Drawer
          isOpen={openForm}
          header='Edit Personal Info'
          subHeader='Please edit your information.'
          onClose={closeEditForm}
        >
          <EditOpcoPersonalDetailsForm
            personalDetails={personalDetails}
            onSuccess={refetchUserInfo}
            onClose={closeEditForm}
          />
        </Drawer>
      </PageBody>
    </ContentLayout>
  );
};
