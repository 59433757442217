import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { reinviteRecipientByProxyApi } from '@/api/v1/recipient/reinviteRecipientByProxyApi';

export const reinviteRecipientByProxy = async (wireId: string) => {
  try {
    const response = await reinviteRecipientByProxyApi(wireId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};