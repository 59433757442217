import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import loginEn from '../src/locales/en/login.json';
import formEn from '../src/locales/en/form.json';

const DEFAULT_LANGUAGE = 'en';

export const i18nConfig = i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: DEFAULT_LANGUAGE,
  resources: {
    en: {
      login: loginEn,
      form: formEn,
    },
  },
});
