import { OpcoUserStatus } from '../ui/OpcoUserStatus/OpcoUserStatus';
import { formatDateTimeUTC, FULL_DATE_TIME_FORMAT } from '@/helpers/dateHelpers/dateHelpers';
import { UserRole } from '@/bundle/_Opco/shared/UserRole/UserRole';
import { OpcoUserType } from '../../types/types';
import { TableConfigItemsListType } from '@/components/Table/types/types';

export const OPCO_USERS_TABLE_CONFIG: TableConfigItemsListType<OpcoUserType> = [
  {
    key: 1,
    name: 'user__email,-user__date_created',
    header: 'EMAIL ADDRESS',
    width: '30%',
    hasSort: true,
    getValue({ user }) {
      return { value: user.email, title: user.email };
    },
  },
  {
    key: 2,
    name: 'user__first_name,-user__date_created',
    header: 'FIRST NAME',
    width: '14%',
    hasSort: true,
    getValue({ user }) {
      return { value: user?.first_name || '-', title: user?.first_name };
    },
  },
  {
    key: 3,
    name: 'user__last_name,-user__date_created',
    header: 'LAST NAME',
    width: '14%',
    hasSort: true,
    getValue({ user }) {
      return { value: user?.last_name || '-', title: user?.last_name };
    },
  },
  {
    key: 4,
    name: 'role',
    header: 'ROLE',
    width: '12%',
    getValue({ role }) {
      return {
        value: <UserRole role={role} />,
      };
    },
  },
  {
    key: 5,
    name: 'user__status,-user__date_created',
    header: 'STATUS',
    width: '12%',
    hasSort: true,
    getValue({ user }) {
      return {
        value: <OpcoUserStatus status={user?.status} />,
      };
    },
  },
  {
    key: 6,
    name: 'user__last_login,-user__date_created',
    header: 'LAST LOGIN',
    width: '18%',
    hasSort: true,
    hasTooltip: true,
    getValue({ user }) {
      const formattedDate = formatDateTimeUTC(user?.last_login, FULL_DATE_TIME_FORMAT);

      return { value: formattedDate, title: formattedDate };
    },
  },
];
