import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { IntermediaryDetailsHeader, PaymentDetailsDivider, Title } from './styles';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { getPaymentDetailsData } from '../PaymentDetailsData/helpers/helpers';

type SourceWirePaymentDetailsIntermediaryBankType = {
  paymentDetails: PaymentDetailsGeneralType;
};

export const SourceWirePaymentDetailsIntermediaryBank = ({
  paymentDetails,
}: SourceWirePaymentDetailsIntermediaryBankType) => {
  const {
    bankCodeLabel,
    bankCodeValue,
    intermediaryBankName,
    intermediaryAccountName,
    intermediaryBankAddress,
    intermediaryBankPhoneNumber,
  } = getPaymentDetailsData(paymentDetails);

  return (
    <>
      <PaymentDetailsDivider />
      <IntermediaryDetailsHeader>Intermediary Bank</IntermediaryDetailsHeader>
      <DetailsRow name={<Title>{bankCodeLabel}</Title>}>{bankCodeValue}</DetailsRow>
      <DetailsRow name={<Title>Bank Name</Title>}>{intermediaryBankName}</DetailsRow>
      <DetailsRow name={<Title>Account Number</Title>}>{intermediaryAccountName}</DetailsRow>
      <DetailsRow name={<Title>Bank Address</Title>}>{intermediaryBankAddress}</DetailsRow>
      <DetailsRow name={<Title>Bank Phone Number</Title>}>{intermediaryBankPhoneNumber}</DetailsRow>
    </>
  );
};
