import { ReactNode } from 'react';
import {
  IconWrapper,
  NotificationContent,
  NotificationWrapper,
  NotificationWrapperPropsType,
  VARIANT_MAP,
} from './styles';
import BlueAlertIcon from '../../images/BlueAlertIcon.svg?react';
import SuccessIcon from '../../images/circleSuccessIcon.svg?react';
import AlertIcon from '../../images/Alert2Icon.svg?react';
import WarningIcon from '../../images/CircleWarningIcon.svg?react';

const ICON_MAP = {
  [VARIANT_MAP.INFO]: <BlueAlertIcon />,
  [VARIANT_MAP.INFO_SECONDARY]: <BlueAlertIcon />,
  [VARIANT_MAP.WARNING]: <WarningIcon />,
  [VARIANT_MAP.SUCCESS]: <SuccessIcon />,
  [VARIANT_MAP.ERROR]: <AlertIcon />,
};

type NotificationType = NotificationWrapperPropsType & {
  icon?: ReactNode;
  children: ReactNode;
};

export const Notification = (props: NotificationType) => {
  const { children, icon, variant = 'info', ...restProps } = props;
  const notificationIcon = icon === null ? '' : icon || ICON_MAP[variant];

  return (
    <NotificationWrapper variant={variant} {...restProps}>
      {notificationIcon && <IconWrapper>{notificationIcon}</IconWrapper>}
      <NotificationContent>{children}</NotificationContent>
    </NotificationWrapper>
  );
};
