import { CloseButton, Wrapper, CustomButton, ButtonWrapper } from './styles';
import ArrowBendUpLeft from '../../images/ArrowBendUpLeft.svg?react';
import CrossIcon from './images/CrossIcon.svg?react';
import { useLocation, useNavigate } from 'react-router-dom';

type RedirectButtonType = {
  onNavigate: () => void;
};

export const RedirectButton = ({ onNavigate }: RedirectButtonType) => {
  const location = useLocation();
  const navigate = useNavigate();

  const clearLocationState = () => navigate(location.pathname, { replace: true });

  return (
    <Wrapper>
      <ButtonWrapper>
        <CustomButton onClick={onNavigate}>
          <ArrowBendUpLeft /> Return To Wire
        </CustomButton>
        <CloseButton onClick={clearLocationState}>
          <CrossIcon />
        </CloseButton>
      </ButtonWrapper>
    </Wrapper>
  );
};
