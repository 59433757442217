import styled, { createGlobalStyle } from 'styled-components';
import { flex, flexCenter } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { bodySmall, caption, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';

export const DatePickerWrapperStyles = createGlobalStyle`
  .wv-datepicker.react-datepicker {
    border-radius: 24px;
    border: 1px solid ${COLORS.grey[850]};
    background: ${COLORS.white};
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgb(0 6 38 / 20%);
  }

  .react-datepicker-popper {
    z-index: 99;
    padding-top: 10px;
  }

  /* Styles to date picker wrapper */
  .wv-datepicker {
    .react-datepicker__month-container {
      width: 375px;
      padding: 20px;
    }

    .react-datepicker__header {
      padding-top: 0;
      background: ${COLORS.white};
      border: none;
    }

    .react-datepicker__day-names {
      ${flex({ alignItems: 'center', justifyContent: 'center' })};
      height: 40px;
      padding: 5px 0;
      flex: 1 0 0;
      color: ${COLORS.black};
      text-align: center;
      ${poppinsRegularFont};
      ${caption};
      text-transform: uppercase;
      border-top: 1px solid ${COLORS.grey[300]};
      margin-top: -15px;
    }

    .react-datepicker__day-name {
      flex-grow: 1;
    }

    .react-datepicker__month {
      color: ${COLORS.black};
      ${poppinsMediumFont};
      ${bodySmall};
      text-align: center;
      text-transform: uppercase;
    }

    .react-datepicker__week {
      ${flex()};
    }

    .react-datepicker__day {
      ${flexCenter};
      margin: 1px 0;
      height: 40px;
      flex-grow: 1;
      position: relative;

      &:hover {
        background-color: transparent;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          inset: 0;
          border: 1px solid ${COLORS.black};
          border-radius: 12px;
          z-index: 1;
        }
      }
    }

    .react-datepicker__day--outside-month {
      color: ${COLORS.grey[400]};
    }

    .react-datepicker__day.react-datepicker__day--selected {
      border-radius: 12px;
      background-color: ${COLORS.grey[950]};
      color: ${COLORS.white};
    }

    .react-datepicker__day.react-datepicker__day--in-selecting-range {
      background-color: transparent;
      color: ${COLORS.grey[950]};
    }

    .react-datepicker__day.react-datepicker__day--in-range {
      background-color: ${COLORS.grey[200]};
      color: ${COLORS.black};
      position: relative;
      border-radius: 12px;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        background-color: ${COLORS.grey[200]};
        z-index: -1;
      }

      &.react-datepicker__day--range-start,
      &:first-child {
        &::before {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
      }

      &.react-datepicker__day--range-end,
      &:last-child {
        &::before {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }

    .react-datepicker__day.react-datepicker__day--range-start,
    .react-datepicker__day.react-datepicker__day--range-end {
      background-color: ${COLORS.grey[950]};
      color: ${COLORS.white};
      border-radius: 12px;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${COLORS.white};
    }

    .react-datepicker__day--disabled {
      cursor: not-allowed;
    }
  }
`;

export const Wrapper = styled.div`
  ${flex({ flexDirection: 'column' })};
`;

export const ButtonWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  border-radius: 6px;

  &:hover {
    background-color: ${COLORS.grey[200]};
  }
`;
