import { IconButton } from '@/components/IconButton/IconButton';
import EditIcon from '@/images/EditIcon.svg?react';

type DetailsEditButtonType = {
  onEdit: () => void;
};

export const DetailsEditButton = ({ onEdit }: DetailsEditButtonType) => {
  return (
    <IconButton onClick={onEdit}>
      <EditIcon />
    </IconButton>
  );
};
