import { Box } from '@/components/Box/Box';
import SuccessIcon from '../images/VerificationSuccessIcon.svg?react';
import RejectFullViewIcon from '../images/VerificationFailedFullViewIcon.svg?react';
import RejectBlurImage from '../images/VerificationFailedBlurIMageIcon.svg?react';
import { StepTitle, TakePhotoStepWrapper } from './styles';
import { RecordVideoStepRow } from '../ui/RecordVideoStepRow';

export const TakePhotoStep = () => {
  return (
    <Box flexDirection='column' rowGap='8px'>
      <StepTitle>Step 3: Take photo of your ID Document</StepTitle>
      <TakePhotoStepWrapper>
        <RecordVideoStepRow icon={<SuccessIcon />} title='Clarity' description='Ensure the image is sharp and clear.' />
        <RecordVideoStepRow
          icon={<RejectFullViewIcon />}
          title='Full View'
          description='Include the entire ID; avoid cropping.'
        />
        <RecordVideoStepRow
          icon={<RejectBlurImage />}
          title='No Blurs or Glare'
          description='Hold steady and avoid reflections.'
        />
      </TakePhotoStepWrapper>
    </Box>
  );
};
