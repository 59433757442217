import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { DocumentVerificationContainer, VerifiedLabel } from './styles';
import DocumentAuthentityIcon from './images/DocumentAuthentityIcon.svg?react';
import IdentityVerificationIcon from './images/IdentityVerificationIcon.svg?react';
import LivenessProofIcon from './images/LivenessProofIcon.svg?react';
import FullNameIcon from './images/FullNameIcon.svg?react';
import { DocumentVerificationRule } from './ui/DocumentVerificationRule';
import { DocumentVerificationList } from './ui/styles';
import {
  TrustStampLink,
  TrustStampMessage,
} from '@/bundle/_Recipient/_Details/ui/RecipientDocumentVerification/TrustStampDetails/styles';
import { TrustStampDetails } from '../../../_Recipient/_Details/ui/RecipientDocumentVerification/TrustStampDetails/TrustStampDetails';
import { TRUST_STAMP_URL } from '../../../_Recipient/_Details/ui/RecipientDocumentVerification/helpers';

const VERIFICATION_LIST = [
  {
    id: 1,
    rule: 'Document authentity',
    icon: <DocumentAuthentityIcon />,
    requiredCheck: true,
  },
  {
    id: 2,
    rule: 'Identity verification',
    icon: <IdentityVerificationIcon />,
    requiredCheck: true,
  },
  {
    id: 3,
    rule: 'Proof of liveness',
    icon: <LivenessProofIcon />,
    requiredCheck: true,
  },
  {
    id: 4,
    rule: 'Name from document',
    icon: <FullNameIcon />,
    requiredCheck: false,
  },
];

type DocumentVerificationPassedDetailsType = {
  fullName: string;
};

export const DocumentVerificationPassedDetails = ({ fullName }: DocumentVerificationPassedDetailsType) => {
  return (
    <DocumentVerificationContainer>
      <DetailsWidget header='ID Verification' headerOffset={16} action={<VerifiedLabel>Verified</VerifiedLabel>}>
        <>
          <DocumentVerificationList>
            {VERIFICATION_LIST.map((item) => {
              const value = item.requiredCheck ? null : fullName;

              return <DocumentVerificationRule key={item?.id} {...item} value={value}></DocumentVerificationRule>;
            })}
          </DocumentVerificationList>
          <TrustStampDetails>
            <TrustStampMessage>
              The verification process was conducted using the Trust Stamp app. Visit{' '}
              <TrustStampLink to={TRUST_STAMP_URL} target='_blank' variant='primary'>
                truststamp.ai
              </TrustStampLink>{' '}
              for more info.
            </TrustStampMessage>
          </TrustStampDetails>
        </>
      </DetailsWidget>
    </DocumentVerificationContainer>
  );
};
