import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import shuffle from 'lodash/shuffle';
import { CheckPinCodeOverlay, CheckPinCodeWrapper } from './styles';
import { RequestLink } from '@/bundle/Auth/LoginFlow/CheckPinCodePage/ui/RequestLink';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { checkPinCode, loadPinCodeAttempts, resetRequest } from './api';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { ResetRequest } from '@/bundle/shared/components/ResetRequest/ResetRequest';
import { PinTokenEventScopeType } from '@/const/shared';
import { ModalWindow } from '@/components/ModalWindow/ModalWindow';
import { PIN_CODE_KEYBOARD, PIN_CODE_LENGTH } from '../PinCode/const/const';
import { PinCode } from '../PinCode/PinCode';
import { CheckPinCodeHintBox } from '../PinCode/ui/CheckPinCodeHintBox';

type CheckPinCodeModalType = {
  isOpen: boolean;
  onSuccess: (pinToken: string) => void;
  onClose: () => void;
  onError?: () => void;
  eventScope: PinTokenEventScopeType;
};

type CheckPinCodeType = Omit<CheckPinCodeModalType, 'isOpen'>;

const CheckPinCode = ({ eventScope, onClose, onSuccess, onError }: CheckPinCodeType) => {
  const queryClient = useQueryClient();

  const [pinCode, setPinCode] = useState<number[]>([]);
  const [pinCodeKeyboard, setPinCodeKeyboard] = useState(() => shuffle(PIN_CODE_KEYBOARD));
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);
  const [isOpenPinCode, setIsOpenPinCode] = useState(true);
  const [confirmationStep, setIsConfirmationStep] = useState(true);

  const { data: pinCodeAttemptsData, isFetching: isPinCodeAttemptsFetching } = useQuery({
    queryKey: ['pin_code_attempts'],
    queryFn: () => loadPinCodeAttempts(),
  });

  const {
    mutate: checkPinCodeMutate,
    data: checkPinCodeData,
    isPending: isCheckPinCodePending,
    reset,
  } = useMutation({
    mutationKey: ['check_pin_code'],
    mutationFn: (formattedPinCode: string) => {
      return checkPinCode(formattedPinCode, eventScope);
    },
    onSuccess(checkPinCodeResponse) {
      if (checkPinCodeResponse?.error) {
        queryClient.invalidateQueries({ queryKey: ['pin_code_attempts'] });

        setPinCode([]);
        setPinCodeKeyboard(shuffle(PIN_CODE_KEYBOARD));
        onError?.();

        return;
      }

      const pinToken = checkPinCodeResponse?.body?.auth_confirmation_token;

      setPinCode([]);
      onSuccess(pinToken);
    },
  });

  const {
    mutate: resetRequestMutate,
    data: resetRequestData,
    isPending: isResetRequestPending,
  } = useMutation({
    mutationKey: ['reset_request'],
    mutationFn: () => {
      return resetRequest();
    },
    onSuccess(resetRequestResponse) {
      if (resetRequestResponse?.error) return;

      setIsConfirmationStep(false);
    },
  });

  const submitPinCode = () => {
    const formattedPinCode = pinCode.join('');

    checkPinCodeMutate(formattedPinCode);
  };

  const clearPinCode = () => {
    setPinCode([]);
    setPinCodeKeyboard(shuffle(PIN_CODE_KEYBOARD));
  };

  const closePinCode = () => {
    setPinCode([]);
    reset();
    onClose();
  };

  const attemptsCount = pinCodeAttemptsData?.body?.remaining_attempts;
  const apiError = getResponseError(checkPinCodeData?.error);
  const resetRequestError = getResponseError(resetRequestData?.error);
  const isDisabled = pinCode.length !== PIN_CODE_LENGTH;
  const isLoading = isPinCodeAttemptsFetching || isCheckPinCodePending;

  useEffect(() => {
    if (apiError && !isLoading && pinCode.length > 0) {
      reset();
    }
  }, [apiError, pinCode, reset, isLoading]);

  const openRequestModal = () => {
    setIsOpenPinCode(!isOpenPinCode);
    setIsOpenRequestModal(!isOpenRequestModal);
  };

  return (
    <CheckPinCodeWrapper>
      {isOpenPinCode && (
        <PinCode
          title='Enter Pin Code'
          link={<RequestLink onOpen={openRequestModal} />}
          hasShadow
          apiError={apiError}
          pinCode={pinCode}
          onSet={setPinCode}
          onSubmit={submitPinCode}
          onBack={closePinCode}
          onClear={clearPinCode}
          pinCodeKeyBoard={pinCodeKeyboard}
          isDisabled={isDisabled}
          isLoading={isCheckPinCodePending}
          backButtonLabel='Cancel'
          messageBox={<CheckPinCodeHintBox attemptsCount={attemptsCount} error={apiError} isLoading={isLoading} />}
        />
      )}
      <ModalWindow isOpen={isOpenRequestModal}>
        <ResetRequest
          confirmationStep={confirmationStep}
          isLoading={isResetRequestPending}
          error={resetRequestError}
          onCancel={openRequestModal}
          onSuccess={closePinCode}
          onConfirm={resetRequestMutate}
        />
      </ModalWindow>
    </CheckPinCodeWrapper>
  );
};

export const CheckPinCodeModal = ({ isOpen, eventScope, onClose, onSuccess, onError }: CheckPinCodeModalType) => {
  if (!isOpen) return;

  return createPortal(
    <CheckPinCodeOverlay>
      <CheckPinCode eventScope={eventScope} onClose={onClose} onSuccess={onSuccess} onError={onError} />
    </CheckPinCodeOverlay>,
    document.getElementById('pin-code')
  );
};
