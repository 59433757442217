import * as Yup from 'yup';
import { REQUIRED_EMAIL_VALIDATOR, REQUIRED_TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';
import { USER_ROLE_MAP, UserRoleType } from '@/const/user';
import { getExecutorWiresPath } from '@/bundle/_Executor/path/path';
import { getDepositorWiresPath } from '@/bundle/_Depositor/path/path';
import { getRecipientWiresPath } from '@/bundle/_Recipient/path/path';
import { getTransactionsPath } from '@/bundle/_OrgAdmin/pages/Transactions/path/path';
import { getOpcoUsersPath } from '@/bundle/_Opco/_OpcoUsersPage/path/path';

type LoginValidationSchemaType = {
  email: string;
  password: string;
};

export const LOGIN_VALIDATION_SCHEMA: Yup.AnySchema<LoginValidationSchemaType> = Yup.object().shape({
  email: REQUIRED_EMAIL_VALIDATOR,
  password: REQUIRED_TEXT_FIELD_VALIDATOR,
});

export const DASHBOARD_REDIRECT_MAP = {
  [USER_ROLE_MAP.OPCO]: getOpcoUsersPath(),
  [USER_ROLE_MAP.ORGANIZATION]: getTransactionsPath(),
  [USER_ROLE_MAP.RECIPIENT]: getRecipientWiresPath(),
  [USER_ROLE_MAP.EXECUTOR]: getExecutorWiresPath(),
  [USER_ROLE_MAP.DEPOSITOR]: getDepositorWiresPath(),
};

export const getDashboardRedirectPath = (currentRole: UserRoleType) => {
  if (!currentRole) return '';

  return DASHBOARD_REDIRECT_MAP[currentRole];
};
