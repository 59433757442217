export const isNullOrUndefined = (value: unknown): boolean => {
  return value === null || value === undefined;
};

export const isNumber = (value: unknown): boolean => {
  return typeof value === 'number';
};

export const isEmptyObject = (value: object = {}): boolean => {
  return Object.keys(value).length === 0;
};
