import { Divider } from '@/components/DetailsWidget/DetailsDividerRow/styles';
import { Typography } from '@/components/Typography/Typography';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { formatDateTimeUTC, formatSecondsToMinutes } from '@/helpers/dateHelpers/dateHelpers';
import { ReelAnimateTime } from '@/bundle/_Recipient/_Details/ui/RecipientDocumentVerification/ReelAnimateTime/ReelAnimateTime';
import { InvitationDataType } from '@/types/wireTypes';
import { RESEND_INVITATION_NOTIFICATION } from '@/const/shared';
import { Notification } from '@/components/Notification/Notification';

type ReInvitationDetailsType = {
  onConfirm: () => void;
  invitationDetails?: InvitationDataType;
  onOpen?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
  seconds?: number;
};

export const ReInvitationDetails = ({
  onConfirm,
  invitationDetails,
  onOpen,
  onClose,
  isOpen,
  seconds,
}: ReInvitationDetailsType) => {
  const { times_invited, last_time_invited } = invitationDetails;

  const formattedLastInvitedDate = formatDateTimeUTC(last_time_invited);

  return (
    <>
      <Divider />
      <Typography fontWeight='medium' variant='subTitleSmall'>
        Invitation details
      </Typography>
      <Notification mb='12px' mt='12px'>
        {RESEND_INVITATION_NOTIFICATION}
      </Notification>
      <DetailsRow name='Last Invited'>{formattedLastInvitedDate}</DetailsRow>
      <DetailsRow name='Times Invited'>{times_invited}</DetailsRow>
      <Box width='100%' display='flex' justifyContent='end'>
        <Button width='172px' size='large' onClick={onOpen} disabled={!!seconds}>
          {!seconds ? 'Resend Invitation' : <ReelAnimateTime time={formatSecondsToMinutes(seconds)} />}
        </Button>
      </Box>
      <ConfirmModal
        header='Resend Invitation'
        isOpen={isOpen}
        body={
          <>
            A new invitation will be sent to the invited user. <br /> The previous one will be still valid.
          </>
        }
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </>
  );
};
