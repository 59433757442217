import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@/components/Card/Card';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { TransactionListItemType } from '@/api/v1/organization/contracts/getTransactionsApi';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';
import { SearchParamsType } from '@/bundle/shared/hooks/useSearch';
import { FiltersSearchParams } from '@/bundle/_OrgAdmin/hooks/useFilterSearch';
import { createSearchParams } from '@/hooks/useReplaceSearchParams';
import { getTransactionDetailsPath } from '../_Details/path/path';

type TransactionsListType = {
  list: TransactionListItemType[];
};

export const TransactionsList = ({ list }: TransactionsListType) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = useGetSearchParams<SearchParamsType & FiltersSearchParams>();

  const navigateToDetails = (item) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.TransactionViewDetails);

    const hasFilterSearch = !!searchParams.name && searchParams.types?.length > 0;
    const searchFilterParams = hasFilterSearch
      ? createSearchParams({ name: searchParams.name, types: searchParams.types })
      : null;
    const stateNavigateFilterSearch = hasFilterSearch ? { pathname, search } : null;

    // Note: pass filters search params, so redirected page will have the same filter search params.
    // Note2: save original location in state from previous page, so user can navigate back to previous location.
    navigate(
      { pathname: getTransactionDetailsPath(item.id), search: searchFilterParams },
      { state: stateNavigateFilterSearch }
    );
  };

  return (
    <>
      {list.map((item) => {
        const { name, date_created, expected_close_date, created_by, number_of_wires, id } = item;
        const { email } = created_by;

        return (
          <Card key={id} title={name} header={name} onClick={() => navigateToDetails(item)}>
            <CardContent name='Creation Date'>{formatDate(date_created)}</CardContent>
            <CardContent name='Expected Closing Date'>{formatDate(expected_close_date)}</CardContent>
            <CardContent name='Created by' ratio={2}>
              {email}
            </CardContent>
            <CardContent name='Number of wires'>{number_of_wires}</CardContent>
          </Card>
        );
      })}
    </>
  );
};
