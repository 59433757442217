import { useEffect, useState } from 'react';
import shuffle from 'lodash/shuffle';
import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import {
  confirmPinCodeNotification,
  createPinCodeNotification,
  validatePinCode,
  validateConfirmPinCode,
  STEP_MAP,
} from './const/const';
import { useNavigate } from 'react-router-dom';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { useMutation } from '@tanstack/react-query';
import { setAuthPinCode } from './api';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { PIN_CODE_KEYBOARD, PIN_CODE_LENGTH } from '@/bundle/shared/components/PinCode/const/const';
import { PinCodeWithInstructions } from '@/bundle/shared/components/PinCode/ui/PinCodeWithInstructions/PinCodeWithInstructions';
import { useAuthNavigateToStep } from '@/bundle/Auth/hooks/useAuthNavigateToStep';

const getFormattedPinCode = (pinCode: number[]) => pinCode.join('');

export const CreatePinCodePage = () => {
  const navigate = useNavigate();
  const navigateToStep = useAuthNavigateToStep();

  const [pinCode, setPinCode] = useState<number[]>([]);
  const [confirmedPinCode, setConfirmedPinCode] = useState<number[]>([]);
  const [step, setStep] = useState(STEP_MAP.CREATE_PIN_CODE);
  const [keyboard, setKeyboard] = useState(shuffle(PIN_CODE_KEYBOARD));
  const [pinCodeError, setPinCodeError] = useState<string>('');

  const isCreatePinCodeStep = step === STEP_MAP.CREATE_PIN_CODE;
  const currentTitle = isCreatePinCodeStep ? 'Create Pin Code' : 'Confirm Pin Code';
  const currentHint = isCreatePinCodeStep ? 'Enter Pin Code' : 'Confirm Pin Code';

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['set_pin_code'],
    mutationFn: () => {
      const formattedPinCode = getFormattedPinCode(pinCode);

      return setAuthPinCode(formattedPinCode);
    },
    onSuccess(setPinCodeResponse) {
      if (setPinCodeResponse?.error) {
        setKeyboard(shuffle(PIN_CODE_KEYBOARD));

        return;
      }

      navigateToStep(setPinCodeResponse?.body);
    },
  });

  const setCurrentStepPinCode = (value) => {
    return isCreatePinCodeStep ? setPinCode(value) : setConfirmedPinCode(value);
  };

  const navigateToConfirmPinCode = () => {
    setStep(STEP_MAP.CONFIRM_PIN_CODE);
    setKeyboard(shuffle(PIN_CODE_KEYBOARD));
  };

  const setUserPinCode = () => {
    mutate();
  };

  const onSubmit = () => {
    return isCreatePinCodeStep ? navigateToConfirmPinCode() : setUserPinCode();
  };

  const redirectToLogin = () => {
    navigate(getLoginPath());
  };

  const clearPinCode = () => {
    setCurrentStepPinCode([]);
    setPinCodeError('');
    setKeyboard(shuffle(PIN_CODE_KEYBOARD));
  };

  const currentPinCode = isCreatePinCodeStep ? pinCode : confirmedPinCode;
  const currentNotification = isCreatePinCodeStep ? createPinCodeNotification : confirmPinCodeNotification;

  const hasConfirmedPinCode = validateConfirmPinCode(
    getFormattedPinCode(pinCode),
    getFormattedPinCode(confirmedPinCode)
  );
  const hasValidPinCode = validatePinCode(getFormattedPinCode(pinCode));

  const isDisabled = isCreatePinCodeStep
    ? pinCode.length !== PIN_CODE_LENGTH || !!hasValidPinCode
    : confirmedPinCode.length !== PIN_CODE_LENGTH || !!hasConfirmedPinCode;

  const error = isCreatePinCodeStep ? hasValidPinCode : hasConfirmedPinCode;
  const apiError = getResponseError(data?.error, 'pin');

  useEffect(() => {
    if (isCreatePinCodeStep && error) {
      setPinCodeError(error);
      setPinCode([]);
    }
  }, [error, isCreatePinCodeStep]);

  useEffect(() => {
    if (!isCreatePinCodeStep && error) {
      setPinCodeError(error);
      setConfirmedPinCode([]);
    }
  }, [error, isCreatePinCodeStep]);

  useEffect(() => {
    if (isCreatePinCodeStep && pinCodeError && pinCode.length > 0) {
      setPinCodeError('');
    }
  }, [pinCodeError, pinCode, isCreatePinCodeStep]);

  useEffect(() => {
    if (!isCreatePinCodeStep && pinCodeError && confirmedPinCode.length > 0) {
      setPinCodeError('');
    }
  }, [pinCodeError, confirmedPinCode, isCreatePinCodeStep]);

  return (
    <AuthLayout>
      <PinCodeWithInstructions
        pinCode={currentPinCode}
        onSetPinCode={setCurrentStepPinCode}
        pinCodeNotification={currentNotification}
        onSubmit={onSubmit}
        title={currentTitle}
        pinCodeKeyBoard={keyboard}
        isDisabled={isDisabled}
        isLoading={isPending}
        error={pinCodeError}
        apiError={apiError}
        currentHint={currentHint}
        onBack={redirectToLogin}
        onClear={clearPinCode}
      />
    </AuthLayout>
  );
};
