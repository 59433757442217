import { useEffect, useLayoutEffect } from 'react';
import { Maybe } from '@/types/sharedTypes';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';

type TimerType = {
  seconds: Maybe<number>;
  minutes: Maybe<number>;
  setSeconds: (seconds: number) => void;
  setMinutes: (minutes: number) => void;
};

export const Timer = ({ seconds, minutes, setSeconds, setMinutes }: TimerType) => {
  useLayoutEffect(() => {
    if (seconds && minutes) {
      setSeconds(seconds);
      setMinutes(minutes);
    }
  }, [minutes, seconds, setMinutes, setSeconds]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSeconds(seconds - 1);

      if (seconds === 0) {
        setSeconds(59);
        setMinutes(minutes - 1);
      }
    }, 1000);

    if (minutes === 0 && seconds === 0) clearTimeout(timer);
  }, [seconds, minutes, setSeconds, setMinutes]);

  const viewSeconds = seconds < 10 ? `${0}${seconds}` : seconds;
  const viewMinutes = minutes < 10 && minutes !== 0 ? `${0}${minutes}` : minutes;

  return (
    <Typography fontWeight='medium' color={COLORS.grey[600]}>
      {viewMinutes}:{viewSeconds}
    </Typography>
  );
};
