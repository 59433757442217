import { ReactNode } from 'react';
import { Box } from '@/components/Box/Box';
import { ModalWindow } from '@/components/ModalWindow/ModalWindow';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@/components/Button/Button';
import { getOpcoOrganizationUsersPath } from '../../../path/path';

type CannotModifyLastUserErrorModalType = {
  isOpen: boolean;
  onClose: () => void;
  header: ReactNode;
  subHeader: ReactNode;
};

export const CannotModifyLastUserErrorModal = ({
  isOpen,
  onClose,
  header,
  subHeader,
}: CannotModifyLastUserErrorModalType) => {
  const { organizationId } = useParams<{ organizationId: string; userId: string }>();
  const navigate = useNavigate();

  const navigateToOrganizationUsers = () => {
    navigate(getOpcoOrganizationUsersPath(organizationId));
  };

  return (
    <ModalWindow isOpen={isOpen}>
      <Box display='flex' flexDirection='column' rowGap='28px'>
        <Box display='flex' flexDirection='column' rowGap='12px' alignItems='center'>
          <Typography variant='headline4' fontWeight='semibold' color={COLORS.black}>
            {header}
          </Typography>
          <Typography color={COLORS.grey[950]} textAlign='center'>
            {subHeader}
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' columnGap='20px'>
          <Button variant='secondary' width={200} onClick={navigateToOrganizationUsers}>
            Go to Users List
          </Button>
          <Button width={200} onClick={onClose}>
            Ok
          </Button>
        </Box>
      </Box>
    </ModalWindow>
  );
};
