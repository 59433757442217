import { Route } from 'react-router-dom';
import { getDepositorWiresPath } from '../path/path';
import { DepositorWiresPage } from '../DepositorWiresPage';
import { DepositorWireDetailsPage } from '../_Details/DepositorWireDetailsPage';
import { getDepositorWireDetailsPath } from '../_Details/path/path';

export const DepositorRoutes = [
  <Route key={getDepositorWiresPath()} path={getDepositorWiresPath()} element={<DepositorWiresPage />} />,
  <Route
    key={getDepositorWireDetailsPath()}
    path={getDepositorWireDetailsPath()}
    element={<DepositorWireDetailsPage />}
  />,
];
