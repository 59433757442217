import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { WireStatus } from '../../../shared/components/WireStatus/WireStatus';
import { formatAmount } from '@/helpers/formatHelpers';
import { AssignedWireType } from '@/types/wireTypes';
import { Notification } from '@/components/Notification/Notification';
import { getContactUsPath } from '../../../pages/ContactUsPage/path/path';
import { DetailsRowNotes } from '@/components/DetailsWidget/DetailsRowNotes/DetailsRowNotes';
import { isFailedWireStatus, isPendingWireStatus } from '@/helpers/wireHelpers';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';

type WireDetailsType = {
  wire: AssignedWireType;
};

const TOOLTIP_DATA = { name: 'blockchain wire', content: 'This wire has been submitted to the WireVault blockchain.' };

export const WireDetails = ({ wire }: WireDetailsType) => {
  const {
    name,
    contract,
    status,
    ref_id,
    date_created,
    expected_payment_date,
    created_by,
    date_modified,
    amount,
    notes,
  } = wire;
  const tooltip = isPendingWireStatus(status) ? TOOLTIP_DATA : null;
  const isFailedWire = isFailedWireStatus(status);

  return (
    <DetailsWidget>
      <DetailsRow name='Transaction Name' title={contract?.name}>
        {contract?.name || '-'}
      </DetailsRow>
      <DetailsRow name='Wire Name' direction='column'>
        {name}
      </DetailsRow>
      {isFailedWire && (
        <Notification variant='error' fontVariant='semibold' verticalAlign='center' mb='12px' inline>
          Something went wrong. Please contact&nbsp;
          <NavigationLink to={getContactUsPath()}>WireVault team</NavigationLink>
        </Notification>
      )}

      <DetailsRow name='Status' tooltip={tooltip}>
        <WireStatus status={status} />
      </DetailsRow>
      <DetailsRow name='Wire Amount' hasBorder>
        {formatAmount(amount)}
      </DetailsRow>
      <DetailsRow name='Wire ID'>{ref_id || '-'}</DetailsRow>
      <DetailsRow name='Wire Creation Date'>{formatDate(date_created)}</DetailsRow>
      <DetailsRow name='Expected Payment Date'>{formatDate(expected_payment_date)}</DetailsRow>
      <DetailsRow name='Created by' title={created_by?.email}>
        {created_by?.email || '-'}
      </DetailsRow>
      <DetailsRow name='Last Update'>{formatDate(date_modified)}</DetailsRow>
      <DetailsRowNotes name='Notes'>{notes}</DetailsRowNotes>
    </DetailsWidget>
  );
};
