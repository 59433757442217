import { Route } from 'react-router-dom';
import { PasswordChangedPage } from '../ForgotPasswordFlow/PasswordChangedPage/PasswordChangedPage';
import { LoginPage } from '@/bundle/Auth/LoginFlow/LoginPage/LoginPage';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { TwoFAPage } from '@/bundle/Auth/LoginFlow/TwoFAPage/TwoFAPage';
import { getTwoFAPath } from '@/bundle/Auth/LoginFlow/TwoFAPage/path/path';
import { ForgotPasswordEmailSentPage } from '../ForgotPasswordFlow/ForgotPasswordEmailSentPage/ForgotPasswordEmailSentPage';
import { getCompleteRegistrationPath } from '@/bundle/Auth/InviteUserFlow/CompleteRegistrationPage/path/path';
import { CompleteRegistrationPage } from '@/bundle/Auth/InviteUserFlow/CompleteRegistrationPage/CompleteRegistrationPage';
import { getPinCodePath } from '@/bundle/Auth/LoginFlow/CheckPinCodePage/path/path';
import { CreatePinCodePage } from '@/bundle/Auth/InviteUserFlow/CreatePinCodePage/CreatePinCodePage';
import { getCreatePinCodePath } from '@/bundle/Auth/InviteUserFlow/CreatePinCodePage/path/path';
import { CheckPinCodePage } from '@/bundle/Auth/LoginFlow/CheckPinCodePage/CheckPinCodePage';
import { ForgotPasswordCheckEmailPage } from '../ForgotPasswordFlow/ForgotPasswordCheckEmailPage/ForgotPasswordCheckEmailPage';
import { ForgotPasswordTwoFAPage } from '../ForgotPasswordFlow/ForgotPasswordTwoFAPage/ForgotPasswordTwoFAPage';
import { ForgotPasswordCompleteRegistrationPage } from '../ForgotPasswordFlow/ForgotPasswordCompleteRegistrationPage/ForgotPasswordCompleteRegistrationPage';
import { getForgotPasswordCheckPinCodePath } from '../ForgotPasswordFlow/ForgotPasswordCheckPinCodePage/path/path';
import { ForgotPasswordCheckPinCodePage } from '../ForgotPasswordFlow/ForgotPasswordCheckPinCodePage/ForgotPasswordCheckPinCodePage';
import { AUTH_STEP, FORGOT_PASSWORD_STEP } from '../const/const';
import { AuthProtectedRoute } from '@/bundle/Routes/AuthProtectedRoute';
import { getResetRequestFromEmailPath } from '../InviteUserFlow/ResetRequestFromEmailPage/path/path';
import { ResetRequestFromEmailPage } from '../InviteUserFlow/ResetRequestFromEmailPage/ResetRequestFromEmailPage';
import { getResetRequestAuthPath } from '../InviteUserFlow/ResetRequestAuthPage/path/path';
import { ResetRequestAuthPage } from '../InviteUserFlow/ResetRequestAuthPage/ResetRequestAuthPage';
import { getSetPasswordLinkExpiredPath } from '../InviteUserFlow/SetPasswordLinkExpiredPage/path/path';
import { SetPasswordLinkExpiredPage } from '../InviteUserFlow/SetPasswordLinkExpiredPage/SetPasswordLinkExpiredPage';
import { getLinkExpiredPath } from '../pages/LinkExpiredPage/path/path';
import { LinkExpiredPage } from '../pages/LinkExpiredPage/LinkExpiredPage';
import { getSelectRolePath } from '@/bundle/Auth/LoginFlow/SelectRolePage/path/path';
import { SelectRolePage } from '../LoginFlow/SelectRolePage/SelectRolePage';
import { ForgotPasswordSetPasswordPage } from '../ForgotPasswordFlow/ForgotPasswordSetPasswordPage/ForgotPasswordSetPasswordPage';
import { ForgotPasswordValidateTotpPage } from '../ForgotPasswordFlow/ForgotPasswordValidateTotpPage/ForgotPasswordValidateTotpPage';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { SetInitialPasswordValidateTotpPage } from '../InviteUserFlow/SetInitialPasswordValidateTotpPage/SetInitialPasswordValidateTotpPage';
import { getSetInitialPasswordPath } from '@/bundle/Auth/InviteUserFlow/SetPasswordPage/path/path';
import { SetInitialPasswordPage } from '@/bundle/Auth/InviteUserFlow/SetPasswordPage/SetInitialPasswordPage';
import { getPersonalDetailsVerificationPath } from '@/bundle/Auth/LoginFlow/PersonalDetailsVerificationPage/path/path';
import { PersonalDetailsVerificationPage } from '../LoginFlow/PersonalDetailsVerificationPage/PersonalDetailsVerificationPage';
import { getForgotPasswordCheckEmailPath } from '../ForgotPasswordFlow/ForgotPasswordCheckEmailPage/path/path';
import { getForgotPasswordEmailSentPath } from '../ForgotPasswordFlow/ForgotPasswordEmailSentPage/path/path';
import { getPasswordChangedPath } from '../ForgotPasswordFlow/PasswordChangedPage/path/path';
import { getForgotPasswordLinkExpiredPath, getForgotPasswordPasswordChangedPath } from '../ForgotPasswordFlow/path';
import { getForgotPasswordTwoFAPath } from '../ForgotPasswordFlow/ForgotPasswordTwoFAPage/path/path';
import { getForgotPasswordValidateTotpPath } from '../ForgotPasswordFlow/ForgotPasswordValidateTotpPage/path/path';
import { getForgotPasswordCompleteRegistrationPath } from '../ForgotPasswordFlow/ForgotPasswordCompleteRegistrationPage/path/path';
import { getForgotPasswordSetPasswordPath } from '../ForgotPasswordFlow/ForgotPasswordSetPasswordPage/path/path';
import { getSetInitialPasswordValidateTotpPath } from '../InviteUserFlow/SetInitialPasswordValidateTotpPage/path/path';

export const AuthRoutes = [
  // Login auth flow
  <Route key={getLoginPath()} path={getLoginPath()} element={<LoginPage />} />,
  <Route
    key={getTwoFAPath()}
    path={getTwoFAPath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.TWO_FACTOR_AUTHENTICATION}>
        <TwoFAPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getPinCodePath()}
    path={getPinCodePath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.PIN_AUTHENTICATION}>
        <CheckPinCodePage />
      </AuthProtectedRoute>
    }
  />,
  <Route key={getSelectRolePath()} path={getSelectRolePath()} element={<SelectRolePage />} />,
  <Route
    key={getPersonalDetailsVerificationPath()}
    path={getPersonalDetailsVerificationPath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.SET_NAME_VERIFICATION}>
        <PersonalDetailsVerificationPage />
      </AuthProtectedRoute>
    }
  />,
  // End of Login auth flow

  // Invite user auth flow
  <Route
    key={getSetInitialPasswordValidateTotpPath()}
    path={getSetInitialPasswordValidateTotpPath()}
    element={<SetInitialPasswordValidateTotpPage />}
  />,
  <Route key={getSetInitialPasswordPath()} path={getSetInitialPasswordPath()} element={<SetInitialPasswordPage />} />,
  <Route
    key={getResetRequestFromEmailPath()}
    path={getResetRequestFromEmailPath()}
    element={<ResetRequestFromEmailPage />}
  />,
  <Route key={getResetRequestAuthPath()} path={getResetRequestAuthPath()} element={<ResetRequestAuthPage />} />,
  <Route
    key={getCompleteRegistrationPath()}
    path={getCompleteRegistrationPath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.COMPLETE_REGISTRATION}>
        <CompleteRegistrationPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getCreatePinCodePath()}
    path={getCreatePinCodePath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.SET_PIN}>
        <CreatePinCodePage />
      </AuthProtectedRoute>
    }
  />,
  // End of Invite user auth flow

  // Forgot password flow
  <Route
    key={getForgotPasswordCheckEmailPath()}
    path={getForgotPasswordCheckEmailPath()}
    element={<ForgotPasswordCheckEmailPage />}
  />,
  <Route
    key={getForgotPasswordValidateTotpPath()}
    path={getForgotPasswordValidateTotpPath()}
    element={<ForgotPasswordValidateTotpPage />}
  />,
  <Route
    key={getForgotPasswordTwoFAPath()}
    path={getForgotPasswordTwoFAPath()}
    element={
      <AuthProtectedRoute allowedStep={FORGOT_PASSWORD_STEP.TWO_FACTOR_AUTHENTICATION}>
        <ForgotPasswordTwoFAPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getForgotPasswordCompleteRegistrationPath()}
    path={getForgotPasswordCompleteRegistrationPath()}
    element={
      <AuthProtectedRoute allowedStep={FORGOT_PASSWORD_STEP.COMPLETE_REGISTRATION}>
        <ForgotPasswordCompleteRegistrationPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getForgotPasswordCheckPinCodePath()}
    path={getForgotPasswordCheckPinCodePath()}
    element={
      <AuthProtectedRoute allowedStep={FORGOT_PASSWORD_STEP.PIN_AUTHENTICATION}>
        <ForgotPasswordCheckPinCodePage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getForgotPasswordSetPasswordPath()}
    path={getForgotPasswordSetPasswordPath()}
    element={
      <AuthProtectedRoute allowedStep={FORGOT_PASSWORD_STEP.SET_PASSWORD}>
        <ForgotPasswordSetPasswordPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getForgotPasswordEmailSentPath()}
    path={getForgotPasswordEmailSentPath()}
    element={<ForgotPasswordEmailSentPage />}
  />,
  <Route
    key={getForgotPasswordLinkExpiredPath()}
    path={getForgotPasswordLinkExpiredPath()}
    element={
      <SetPasswordLinkExpiredPage
        onRedirect={() => amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordLinkExpired)}
      />
    }
  />,
  <Route
    key={getForgotPasswordPasswordChangedPath()}
    path={getForgotPasswordPasswordChangedPath()}
    element={
      <PasswordChangedPage
        onRedirect={() => amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordSetPasswordSuccessLoginRedirect)}
      />
    }
  />,
  // End of Forgot password flow

  // Informational pages routes
  <Route
    key={getPasswordChangedPath()}
    path={getPasswordChangedPath()}
    element={
      <PasswordChangedPage onRedirect={() => amplitudeService.logEvent(AMPLITUDE_EVENTS.SetPasswordRedirectSignIn)} />
    }
  />,
  <Route
    key={getSetPasswordLinkExpiredPath()}
    path={getSetPasswordLinkExpiredPath()}
    element={<SetPasswordLinkExpiredPage />}
  />,
  <Route key={getLinkExpiredPath()} path={getLinkExpiredPath()} element={<LinkExpiredPage />} />,

  // End of Informational pages routes
];
