import capitalize from 'lodash/capitalize';
import EnabledVerificationIcon from '@/images/CheckSmallIcon.svg?react';
import DisabledVerificationIcon from '@/images/MinusSmallIcon.svg?react';
import { TableConfigItemsListType } from '@/components/Table/types/types';
import { BilledEventsListType } from '../helpers/helpers';
import { formatDateTimeUTC } from '@/helpers/dateHelpers/dateHelpers';

export const BILLED_EVENTS_TABLE_CONFIG: TableConfigItemsListType<BilledEventsListType> = [
  {
    key: 1,
    name: 'organization__name,-date_created',
    header: 'ORGANIZATION NAME',
    width: '15%',
    hasSort: true,
    getValue(item) {
      return { value: item.org_name, title: item.org_name };
    },
  },
  {
    key: 2,
    name: 'date_created',
    header: 'DATE OF VIEW',
    width: '15%',
    hasSort: true,
    hasTooltip: true,
    getValue({ date_viewed }) {
      return { value: formatDateTimeUTC(date_viewed) };
    },
  },
  {
    key: 3,
    name: 'wire__name,-date_created',
    header: 'WIRE NAME',
    width: '10%',
    hasSort: true,
    getValue({ wire_name }) {
      return { value: wire_name, title: wire_name };
    },
  },
  {
    key: 4,
    name: 'wire_ref_id',
    header: 'WIRE ID',
    width: '10%',
    getValue({ wire_ref_id }) {
      return { value: wire_ref_id, title: wire_ref_id };
    },
  },
  {
    key: 5,
    name: 'id_verification,-date_created',
    header: 'ID VERIFICATION',
    width: '10%',
    hasSort: true,
    getValue({ id_verification }) {
      return { value: id_verification ? <EnabledVerificationIcon /> : <DisabledVerificationIcon /> };
    },
  },
  {
    key: 6,
    name: 'wire__payment_details_collection_type,-date_created',
    header: 'COLLECTION TYPE',
    width: '10%',
    hasSort: true,
    getValue({ payment_details_collection_type }) {
      const paymentDetailsCollectionType = capitalize(payment_details_collection_type);

      return { value: paymentDetailsCollectionType };
    },
  },
  {
    key: 7,
    name: 'user__email,-date_created',
    header: 'USER VIEWED',
    width: '15%',
    hasSort: true,
    getValue({ user_viewed }) {
      return { value: user_viewed, title: user_viewed };
    },
  },
  {
    key: 8,
    name: 'wire__contract__name,-date_created',
    header: 'TRANSACTION NAME',
    width: '15%',
    hasSort: true,
    getValue({ transaction_name }) {
      return { value: transaction_name, title: transaction_name };
    },
  },
];
