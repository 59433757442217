import { ApiResponseType, BaseResponseType } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export const reinviteOrganizationUserApi = (
  organizationId: string,
  userId: string
): Promise<ApiResponseType<BaseResponseType>> => {
  const uri = `/opco/organizations/${organizationId}/users/${userId}/reinvite/`;

  return authApiService.POST(uri, {});
};
