import { ReactNode } from 'react';
import { AuthTitleWrapper } from './styles';

type AuthTitleType = {
  children: ReactNode;
  marginBottom?: number;
};

export const AuthTitle = ({ children, marginBottom }: AuthTitleType) => {
  return <AuthTitleWrapper marginBottom={marginBottom}>{children}</AuthTitleWrapper>;
};
