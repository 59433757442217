import { isRecipientInvitedWireStatus } from '@/helpers/wireHelpers';
import { UserDetailsWidget } from '@/bundle/shared/components/UserDetailsWidget/UserDetailsWidget';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';
import { ReInvitationDetails } from '@/bundle/shared/components/UserDetailsWidget/ReInvitationDetails';
import { AssignedWireType } from '@/types/wireTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { reinviteRecipientByProxy } from '@/bundle/shared/components/RecipientDetails/api';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { useState } from 'react';
import { useTimer } from '@/hooks/useTimer';

type RecipientChainProxyDetailsType = {
  wire: AssignedWireType;
  isProxyEditRecipient?: boolean;
  onProxyEditRecipient?: () => void;
  refetchOutboundWire?: () => void;
};

export const RecipientChainProxyDetails = ({
  wire,
  isProxyEditRecipient,
  onProxyEditRecipient,
}: RecipientChainProxyDetailsType) => {
  const queryClient = useQueryClient();
  const proxyRecipientDetails = wire?.assigned_proxy_user;
  const recipientDetails = wire?.assigned_user;
  const isDocumentVerificationRequired = !!wire?.is_pd_provider_kyc_required;
  const isRecipientInvited = isRecipientInvitedWireStatus(wire?.status);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const refetchRecipientWire = () => {
    queryClient.invalidateQueries({ queryKey: ['load_recipient_wire', wire?.id] });
  };

  const { seconds } = useTimer(recipientDetails?.invitation_data?.next_resend_available_in_sec, refetchRecipientWire);

  const { mutate: reinviteRecipientByProxyMutate } = useMutation({
    mutationKey: ['reinvite_recipient_by_proxy', wire?.id],
    mutationFn: () => {
      return reinviteRecipientByProxy(wire?.id);
    },
    onSuccess: (reinviteResponse) => {
      if (reinviteResponse.error) {
        const error = getResponseError(reinviteResponse.error);

        showSnackbar(error, { variant: 'error' });
        refetchRecipientWire();

        return setIsOpenModal(false);
      }

      refetchRecipientWire();
      setIsOpenModal(false);
    },
  });

  return (
    <>
      <UserDetailsWidget header='Recipient Proxy' user={proxyRecipientDetails} />
      {!isProxyEditRecipient && (
        <UserDetailsWidget
          header='Recipient'
          user={recipientDetails}
          action={isRecipientInvited && <DetailsEditButton onEdit={onProxyEditRecipient} />}
          marker={isDocumentVerificationRequired && 'ID VERIFICATION'}
          hasInvitedNotification={isRecipientInvited}
        >
          {isRecipientInvited && (
            <ReInvitationDetails
              onConfirm={reinviteRecipientByProxyMutate}
              invitationDetails={recipientDetails?.invitation_data}
              onOpen={() => setIsOpenModal(true)}
              onClose={() => setIsOpenModal(false)}
              isOpen={isOpenModal}
              seconds={seconds}
            />
          )}
        </UserDetailsWidget>
      )}
    </>
  );
};
