import { useNavigate } from 'react-router-dom';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { AuthTitle } from '../../ui/AuthTitle/AuthTitle';
import { Notification } from '@/components/Notification/Notification';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { useFormik } from 'formik';
import { passwordValidationSchema } from './const/const';
import { useMutation } from '@tanstack/react-query';
import { forgotPasswordSetPassword } from './api';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormikPasswordInput } from '@/components/form/fields/FormikPasswordInput/FormikPasswordInput';
import { BulletValidation } from '../../InviteUserFlow/SetPasswordPage/ui/BulletValidation/BulletValidation';
import { Button } from '@/components/Button/Button';
import { Box } from '@/components/Box/Box';
import { validatePassword } from '../../const/const';
import { getForgotPasswordPasswordChangedPath } from '../path';

type PasswordConfirmationFormType = {
  password: string;
  passwordConfirmation: string;
};

export const ForgotPasswordSetPasswordPage = () => {
  const navigate = useNavigate();

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['set_password'],
    mutationFn: (password: string) => {
      const payload = {
        password,
      };

      return forgotPasswordSetPassword(payload);
    },
    onSuccess(setPasswordResponse) {
      if (setPasswordResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordSetPasswordSuccess);

      navigate(getForgotPasswordPasswordChangedPath());
    },
  });

  const formik = useFormik<PasswordConfirmationFormType>({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: (values) => {
      mutate(values.password);
    },
    validationSchema: passwordValidationSchema,
  });

  const navigateToLogin = () => {
    navigate(getLoginPath());
  };

  const { isValid } = validatePassword(formik.values?.password);
  const isTouchedPasswordField = formik.touched.password;
  const isFormValid = isValid && formik.isValid;
  const error = getResponseError(data?.error);

  return (
    <AuthLayout>
      <AuthStep>
        <AuthTitle>Set Your Password</AuthTitle>

        {error && (
          <Notification variant='error' mb='24px'>
            {error}
          </Notification>
        )}

        <FormikForm value={formik}>
          <FormikPasswordInput
            label='New Password*'
            placeholder='Enter Your New Password'
            name='password'
            apiError={data?.error}
            maxLength={30}
            hasToggleEye
          />
          <BulletValidation password={formik?.values?.password} isTouchedPasswordField={isTouchedPasswordField} />
          <FormikPasswordInput
            label='Confirm New Password*'
            placeholder='Confirm Your Password'
            name='passwordConfirmation'
            apiError={data?.error}
            maxLength={30}
            isCopyPasteDisabled
            isConfirmTicks
            hasToggleEye
          />
          <Box display='flex' flexDirection='column' rowGap='12px' mt='12px'>
            <Button isLoading={isPending} onClick={formik.handleSubmit} type='submit' disabled={!isFormValid}>
              Submit
            </Button>
            <Button variant='secondary' onClick={navigateToLogin}>
              Return to Login
            </Button>
          </Box>
        </FormikForm>
      </AuthStep>
    </AuthLayout>
  );
};
