import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { poppinsMediumFont } from '@/styles/typography';
import { flexCenterColumn } from '@/styles/layout';
import DarkInfoIcon from '../../../../../images/darkInfoIcon.svg?react';

export const TimerWrapper = styled.div`
  width: 60px;
  padding: 2px 6px;
  color: ${COLORS.grey[850]};
  ${poppinsMediumFont};
  background-color: ${COLORS.blue[250]};
  border-radius: 4px;
  text-align: center;
`;

export const TooltipContentWrapper = styled.div`
  max-width: 332px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  ${flexCenterColumn};
  align-items: start;
  row-gap: 10px;
`;

export const InfoIcon = styled(DarkInfoIcon)`
  cursor: pointer;
`;
