import styled from 'styled-components';
import Logo from '@/images/Logo.svg?react';
import { flex } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import OrgIcon from '../../images/OrganizationIcon.svg?react';
import { media } from '@/styles/responsive';
import { THEME } from '@/styles/theme';

export const LogoWrapper = styled(Logo)`
  cursor: pointer;
`;

export const MenuHeader = styled.div`
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })};
  box-shadow: 1px 0 24px rgb(25 44 61 / 10%);
  padding: 32px 20px;
  border-bottom: 1px solid ${COLORS.grey[850]};

  ${media.tablet`
    display: none;
  `}
`;

export const OrganizationDetailsWrapper = styled.div`
  ${flex()};
  column-gap: 12px;
  padding: 16px 32px;
  border-bottom: 1px solid ${COLORS.grey[850]};

  ${media.mobile`
    padding: ${THEME.offset.mobile} 28px;  

  `}
`;

export const OrganizationName = styled.div`
  color: ${COLORS.grey[600]};
  text-transform: uppercase;
  word-break: break-word;
  ${bodySmall};
  ${poppinsMediumFont};
`;

export const OrganizationIcon = styled(OrgIcon)`
  flex-shrink: 0;
`;
