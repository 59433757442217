import { USER_ROLE_MAP, UserRoleType } from '@/const/user';
import DepositorRoleIcon from '../images/DepositorRoleIcon.svg?react';
import ExecutorRoleIcon from '../images/ExecutorRoleIcon.svg?react';
import RecipientRoleIcon from '../images/RecipientRoleIcon.svg?react';
import OrgAdminRoleIcon from '../images/OrgAdminRoleIcon.svg?react';
import { ReactNode } from 'react';

export type SortedActiveRoleType = {
  title: string;
  description: string;
  value: UserRoleType;
  icon: ReactNode;
};

export const SWITCH_ROLE_ITEM_MAP = {
  [USER_ROLE_MAP.ORGANIZATION]: {
    title: 'login:roleAdmin',
    description: 'login:roleAdminDescription',
    value: USER_ROLE_MAP.ORGANIZATION,
    icon: <OrgAdminRoleIcon />,
  },
  [USER_ROLE_MAP.RECIPIENT]: {
    title: 'login:roleRecipient',
    description: 'login:roleRecipientDescription',
    value: USER_ROLE_MAP.RECIPIENT,
    icon: <RecipientRoleIcon />,
  },
  [USER_ROLE_MAP.EXECUTOR]: {
    title: 'login:roleExecutor',
    description: 'login:roleExecutorDescription',
    value: USER_ROLE_MAP.EXECUTOR,
    icon: <ExecutorRoleIcon />,
  },
  [USER_ROLE_MAP.DEPOSITOR]: {
    title: 'login:roleDepositor',
    description: 'login:roleDepositorDescription',
    value: USER_ROLE_MAP.DEPOSITOR,
    icon: <DepositorRoleIcon />,
  },
};
