import { ReactNode, useState } from 'react';
import { Box } from '../Box/Box';
import { AccordionBody, AccordionBodyContent, AccordionButtonWrapper, AccordionHeader } from './styles';
import AccordionArrow from './images/AccordionArrow.svg?react';
import { isNullOrUndefined } from '@/helpers/objectHelpers';

type AccordionType = {
  header: (iconWrapper: ReactNode) => ReactNode;
  children?: ReactNode;
  isOpen?: boolean;
  onChange?: (isOpen: boolean) => void;
  as?: keyof HTMLElementTagNameMap;
};

export const Accordion = ({ header, children, isOpen, onChange, as = 'div' }: AccordionType) => {
  const [opened, setOpened] = useState(!!isOpen);
  // When <isOpen> props is not passed - component is uncontrollable and operates only with local state state,
  // otherwise - controllable component with outside state.
  const openState = isNullOrUndefined(isOpen) ? opened : !!isOpen;

  const toggleVisibility = () => {
    setOpened(!opened);
    onChange?.(!opened);
  };

  return (
    <Box width='100%' as={as}>
      <AccordionHeader onClick={toggleVisibility}>
        {header(
          <AccordionButtonWrapper isOpen={openState}>
            <AccordionArrow />
          </AccordionButtonWrapper>
        )}
      </AccordionHeader>
      <AccordionBody isOpen={openState}>
        <AccordionBodyContent>{children}</AccordionBodyContent>
      </AccordionBody>
    </Box>
  );
};
