import { ForgotPinCodeMessage, RequestButton } from '@/bundle/shared/components/PinCode/styles';
import { Box } from '@/components/Box/Box';
import { useTranslation } from 'react-i18next';

type RequestLinkType = {
  onOpen?: () => void;
};

export const RequestLink = ({ onOpen }: RequestLinkType) => {
  const { t } = useTranslation('login');

  return (
    <Box alignItems='center' justifyContent='center' flexDirection='row'>
      <ForgotPinCodeMessage>{t('login:forgotPinCodeMessage')}</ForgotPinCodeMessage>
      <RequestButton onClick={onOpen}>{t('login:requestButtonLabel')}</RequestButton>
    </Box>
  );
};
