import { css, RuleSet } from 'styled-components';

type LiteralsType = (literals: TemplateStringsArray, ...placeholders: any[]) => RuleSet<object>;
type BreakPointsType = Record<'mobile' | 'tablet' | 'tabletOnly', LiteralsType>;

export const BREAKPOINTS = {
  xs: 375,
  md: 768,
  xl: 1400,
};

// Add all responsive mixins to single object
export const media = {} as BreakPointsType;

// <mobile> -> from 375px to 768px
media.mobile = (literals: TemplateStringsArray, ...placeholders: any[]) => {
  return css`
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      ${css(literals, ...placeholders)};
    }
  `;
};

// <tablet> -> from 769px to 1400px
media.tablet = (literals: TemplateStringsArray, ...placeholders: any[]) => {
  return css`
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
      ${css(literals, ...placeholders)};
    }
  `;
};

// <tablet only> -> between 769px and 1400px
media.tabletOnly = (literals: TemplateStringsArray, ...placeholders: any[]) => {
  return css`
    @media screen and (min-width: ${BREAKPOINTS.md}px) and (max-width: ${BREAKPOINTS.xl}px) {
      ${css(literals, ...placeholders)};
    }
  `;
};
