import { useNavigate } from 'react-router-dom';
import { getDepositorWireDetailsPath } from '../_Details/path/path';
import { Card, CardContent } from '@/components/Card/Card';
import { formatAmount } from '@/helpers/formatHelpers';
import { WireStatus } from '../../shared/components/WireStatus/WireStatus';
import { AssignedWireType } from '@/types/wireTypes';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';

type DepositorWiresListType = {
  list: AssignedWireType[];
};

export const DepositorWiresList = ({ list }: DepositorWiresListType) => {
  const navigate = useNavigate();

  const navigateToDetails = (wire) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ViewWireDetailsDepositor, { status: wire?.status });

    navigate(getDepositorWireDetailsPath(wire.id));
  };

  return (
    <>
      {list.map((wire) => {
        const { name, status, date_created, expected_payment_date, amount, contract, id } = wire;

        return (
          <Card
            key={id}
            title={name}
            header={name}
            meta={<WireStatus status={status} />}
            onClick={() => navigateToDetails(wire)}
          >
            <CardContent name='Creation Date'>{formatDate(date_created)}</CardContent>
            <CardContent name='Expected Payment Date'>{formatDate(expected_payment_date)}</CardContent>
            <CardContent name='Amount'>{formatAmount(amount)}</CardContent>
            <CardContent name='Transaction Name' ratio={2}>
              {contract?.name || '-'}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};
