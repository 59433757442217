import { Notification } from '@/components/Notification/Notification';
import {
  PinCodeWrapper,
  InstructionsWrapper,
  InstructionHeader,
  InstructionSubHeader,
  InstructionBody,
  InstructionRow,
  HeaderIcon,
  RulesList,
  RulesListItem,
} from './styles';
import { PinCode } from '../../PinCode';
import ShieldIcon from './images/ShieldIcon.svg?react';
import LockIcon from './images/LockIcon.svg?react';
import RulesIcon from './images/RulesIcon.svg?react';
import { Box } from '@/components/Box/Box';
import { CreatePinCodeHintBox } from './ui/CreatePinCodeHintBox';

interface PinCodeWithInstructionsType {
  pinCode: number[];
  onSetPinCode: (value: unknown) => void;
  pinCodeNotification: string;
  onSubmit: () => void;
  title: string;
  pinCodeKeyBoard: number[];
  isDisabled: boolean;
  isLoading: boolean;
  error?: string;
  apiError?: string;
  currentHint: string;
  onBack: () => void;
  onClear: () => void;
}

export const PinCodeWithInstructions = ({
  pinCode,
  onSetPinCode,
  pinCodeNotification,
  onSubmit,
  title,
  pinCodeKeyBoard,
  isDisabled,
  isLoading,
  error,
  apiError,
  currentHint,
  onBack,
  onClear,
}: PinCodeWithInstructionsType) => {
  return (
    <PinCodeWrapper>
      <InstructionsWrapper>
        <Notification>{pinCodeNotification}</Notification>
        <Box mt='28px' display='flex' flexDirection='column' rowGap='28px'>
          <InstructionRow>
            <InstructionHeader>
              <HeaderIcon>
                <RulesIcon />
              </HeaderIcon>
              PIN Code Rules
            </InstructionHeader>
            <InstructionBody>
              <InstructionSubHeader>While creating your PIN code:</InstructionSubHeader>
              <RulesList>
                <RulesListItem>Use your mouse, or tap the screen, to select the digits</RulesListItem>
                <RulesListItem>Do not use your keyboard</RulesListItem>
                <RulesListItem>NO identical digits (e.g. 111111)</RulesListItem>
                <RulesListItem>NO sequential digits (e.g. 123456 or 654321)</RulesListItem>
              </RulesList>
            </InstructionBody>
          </InstructionRow>

          <InstructionRow>
            <InstructionHeader>
              <HeaderIcon>
                <ShieldIcon />
              </HeaderIcon>
              No Chance to Change
            </InstructionHeader>
            <InstructionBody>
              Once your PIN code is created, it cannot be changed. Please be sure your PIN is memorable and secure.
            </InstructionBody>
          </InstructionRow>

          <InstructionRow>
            <InstructionHeader>
              <HeaderIcon>
                <LockIcon />
              </HeaderIcon>
              Store Safe and Accessible
            </InstructionHeader>
            <InstructionBody>
              You must keep your PIN code confidential and not share it with anyone. Your PIN code is unique to you and
              acts as another factor of authentication for your account.
            </InstructionBody>
          </InstructionRow>
        </Box>
      </InstructionsWrapper>
      <PinCode
        title={title}
        onSubmit={onSubmit}
        onBack={onBack}
        pinCode={pinCode}
        onSet={onSetPinCode}
        onClear={onClear}
        pinCodeKeyBoard={pinCodeKeyBoard}
        isDisabled={isDisabled}
        isLoading={isLoading}
        apiError={apiError}
        error={error}
        messageBox={<CreatePinCodeHintBox error={error || apiError} currentHint={currentHint} />}
      />
    </PinCodeWrapper>
  );
};
