import { useEffect } from 'react';
import { addDays, isBefore } from 'date-fns';
import { useFormik } from 'formik';
import { FilterListClearAll } from './styles';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { RangeDatePicker } from './RangeDatePicker/RangeDatePicker';
import { Box } from '@/components/Box/Box';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { BilledEventsFiltersType } from '../../hooks/useBilledEventsFilters/useBilledEventsFilters';
import { ChipsList } from './ChipsList/ChipsList';
import { CHIP_TYPE_MAP, FiltersChipType } from './ChipsList/helpers';
import { FormikMultiSelect } from '@/components/form/fields/FormikMultiSelect/FormikMultiSelect';
import { OptionType } from '@/components/form/fields/Select/Select';

type FilterListPropsType = {
  filters: BilledEventsFiltersType;
  onApplyFilters: (filters: BilledEventsFiltersType) => void;
  onClearFilters: () => void;
  organizationsOptions: OptionType[];
};

export const FilterList = ({ filters, onApplyFilters, onClearFilters, organizationsOptions }: FilterListPropsType) => {
  const formik = useFormik<BilledEventsFiltersType>({
    initialValues: filters,
    enableReinitialize: true,
    onSubmit: onApplyFilters,
  });

  const removeChip = (chip: FiltersChipType) => {
    if (chip.type === CHIP_TYPE_MAP.transaction) {
      onApplyFilters({ ...filters, transactionName: '' });

      return;
    }

    if (chip.type === CHIP_TYPE_MAP.organization) {
      const remainingOrganizations = filters.organizations.filter(({ value }) => value !== chip.id);

      onApplyFilters({
        ...filters,
        organizations: remainingOrganizations,
      });
    }
  };

  useEffect(() => {
    const isStartDateBeforeEndDate = isBefore(formik.values.dateStart, formik.values.dateEnd);

    if (isStartDateBeforeEndDate) return;

    // Add one day to start date when start date was selected after end date
    const endDate = addDays(formik.values.dateStart, 1);

    formik.setFieldValue('dateEnd', endDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.dateStart]);

  return (
    <Box>
      <FormikForm value={formik}>
        <Box justifyContent='space-between' alignItems='flex-start' flexWrap='wrap'>
          <Box display='flex' columnGap={8} rowGap={8} flexWrap='wrap'>
            <Box width={260}>
              <FormikMultiSelect
                name='organizations'
                options={organizationsOptions}
                placeholder='Organization Name'
                placeholderValue='Organization Name'
                size='small'
              />
            </Box>

            <RangeDatePicker
              nameStart='dateStart'
              nameEnd='dateEnd'
              dateStart={formik.values.dateStart}
              dateEnd={formik.values.dateEnd}
            />
            <Box width={200}>
              <FormikInput name='transactionName' placeholder='Transaction Name' variant='small' />
            </Box>
          </Box>

          <Box alignItems='center' columnGap={10}>
            <FilterListClearAll type='button' onClick={onClearFilters}>
              Clear all
            </FilterListClearAll>
            <Button width={140} size='medium' type='submit' variant='secondary'>
              Apply
            </Button>
          </Box>
        </Box>
      </FormikForm>
      <Box mb='8px'>
        <ChipsList filters={filters} onRemoveChip={removeChip} />
      </Box>
    </Box>
  );
};
