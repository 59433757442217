import { InputClearButtonWrapper } from './styles';
import ClearIcon from './ClearIcon.svg?react';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { MouseEventHandler, ReactNode, useId } from 'react';

type InputClearButtonType = {
  tooltip?: ReactNode;
  onClear: MouseEventHandler<HTMLDivElement>;
};

export const InputClearButton = ({ tooltip, onClear }: InputClearButtonType) => {
  const id = useId();

  return (
    <InputClearButtonWrapper data-tooltip-id={id} onClick={onClear}>
      {!!tooltip && (
        <Tooltip type='hint' id={id}>
          {tooltip}
        </Tooltip>
      )}
      {<ClearIcon />}
    </InputClearButtonWrapper>
  );
};
