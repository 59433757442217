import { Box } from '@/components/Box/Box';
import VerifiedIcon from '@/images/VerifiedIcon.svg?react';
import { Tooltip } from '@/components/Tooltip/Tooltip';

type BankNameType = {
  name: string;
  isVerified: boolean;
};

export const BankName = ({ name, isVerified }: BankNameType) => {
  return (
    <Box display='flex' alignItems='center' columnGap='8px'>
      {isVerified && (
        <>
          {/* Note: since we have “overflow: hidden” in <DetailsRow> component, it’s cuts off the sides of outline(focus state) for svg icons (fixed with margin for now) */}
          <Box m='1px'>
            <VerifiedIcon data-tooltip-id='verified-bank-tooltip' />
          </Box>

          <Tooltip id='verified-bank-tooltip'>Bank Verified by WireVault</Tooltip>
        </>
      )}
      {name}
    </Box>
  );
};
