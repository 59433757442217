import { FilterParamsType } from '@/api/helpers/queryHelpers';
import { formatPayloadDate } from '@/helpers/dateHelpers/dateHelpers';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { getInitialFilters, getQueryFilters, getInitialRangeDate, getString, getArray } from './helpers';
import { NavigateOptions, useLocation } from 'react-router-dom';
import { OrganizationListItemType } from '@/bundle/_Opco/types/types';
import { OptionType } from '@/components/form/fields/Select/Select';
import { useReplaceSearchParams } from '@/hooks/useReplaceSearchParams';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';

export type BilledEventsFiltersType = {
  organizations: OptionType[];
  transactionName: string;
  dateStart: Date;
  dateEnd: Date;
};

export type BilledEventsFilterParamsType = {
  filters?: string;
  page?: string;
};

export const useBilledEventsFilters = (organizations: OrganizationListItemType[]) => {
  const { replaceSearchParams, getSearchParams } = useReplaceSearchParams();
  const location = useLocation();
  const queryParams = useGetSearchParams<BilledEventsFilterParamsType>();

  const filtersParams: FilterParamsType = queryParams?.filters === undefined ? null : JSON.parse(queryParams.filters);
  const queryFilters = getQueryFilters(filtersParams);

  const [filters, setFilters] = useState<BilledEventsFiltersType>(null);

  const applyFilters = (newFilters: BilledEventsFiltersType, navigateOptions?: NavigateOptions) => {
    const formattedFiltersParams: FilterParamsType = {
      wire__contract__name: getString(newFilters?.transactionName),
      organization__id: getArray(newFilters?.organizations?.map((item) => item.value as string)),
      date_created_after: formatPayloadDate(newFilters?.dateStart),
      date_created_before: formatPayloadDate(newFilters?.dateEnd),
    };
    const searchParams = getSearchParams<BilledEventsFilterParamsType>();
    const stringifiedFiltersParams = JSON.stringify(formattedFiltersParams);

    // Note: skip apply same filters params
    if (searchParams.filters === stringifiedFiltersParams) {
      return;
    }

    const newQueryParams = {
      filters: stringifiedFiltersParams,
      page: null,
    };

    replaceSearchParams(newQueryParams, navigateOptions);
    setFilters(newFilters);
  };

  const clearFilters = () => {
    const dateRange = getInitialRangeDate();

    applyFilters({
      transactionName: '',
      organizations: [],
      dateStart: parseISO(dateRange.startDateFormatted),
      dateEnd: parseISO(dateRange.endDateFormatted),
    });
  };

  useEffect(() => {
    if (!organizations) return;

    const initialFilters = getInitialFilters(queryFilters, organizations);

    if (!queryParams.filters) {
      // Note: use replace on first load, so the browser back button behavior works properly
      applyFilters(initialFilters, { replace: true });
    }

    if (initialFilters) {
      setFilters(initialFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, organizations]);

  return { filters, queryFilters, applyFilters, clearFilters };
};
