import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { useFormik } from 'formik';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { Button } from '@/components/Button/Button';
import { Box } from '@/components/Box/Box';
import { AuthTitle } from '../../ui/AuthTitle/AuthTitle';
import { AuthMessage } from '../../ui/styles';
import { useMutation } from '@tanstack/react-query';
import { personalDetailsVerification } from '@/bundle/Auth/LoginFlow/PersonalDetailsVerificationPage/api';
import { useAuthNavigateToStep } from '@/bundle/Auth/hooks/useAuthNavigateToStep';
import { PersonalDetailsVerificationPayload } from '@/api/v1/users/sendPersonalDetailsVerificationApi';
import { authTokenService } from '@/bundle/shared/services/authTokenService';
import { AuthTokenType } from '@/types/sharedTypes';
import { decodeToken } from '@/helpers/tokenHelpers';
import { Notification } from '@/components/Notification/Notification';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { PERSONAL_DETAILS_INITIAL_VALUES, PERSONAL_DETAILS_VALIDATION_SCHEMA } from '@/const/formConfigs';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PersonalDetailsVerificationPage = () => {
  const navigate = useNavigate();
  const navigateToStep = useAuthNavigateToStep();
  const { t } = useTranslation(['login', 'form']);

  const token = authTokenService?.getTokens();

  const { first_name, middle_name, last_name } = decodeToken<AuthTokenType>(token?.access);

  const { data, mutate, isPending } = useMutation({
    mutationKey: ['personal_data_verification'],
    mutationFn: (payload: PersonalDetailsVerificationPayload) => {
      return personalDetailsVerification(payload as PersonalDetailsVerificationPayload);
    },
    onSuccess: (personalDataResponse) => {
      if (personalDataResponse?.error) return;

      navigateToStep(personalDataResponse?.body);
    },
  });

  const authMessage = t('login:personalDetailsAuthMessage');

  const formik = useFormik({
    initialValues: PERSONAL_DETAILS_INITIAL_VALUES,
    validationSchema: PERSONAL_DETAILS_VALIDATION_SCHEMA,
    onSubmit: async (values: PersonalDetailsVerificationPayload) => {
      mutate(values);
    },
  });

  useEffect(() => {
    formik.setValues({
      first_name,
      middle_name,
      last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first_name, middle_name, last_name]);

  const navigateToLogin = () => {
    navigate(getLoginPath());
  };

  const apiError = data?.error;
  const formError = getResponseError(apiError);

  return (
    <AuthLayout>
      <AuthStep>
        <AuthTitle>{t('login:personalDetailsHeader')}</AuthTitle>
        <Box mb='36px'>
          <AuthMessage>{authMessage}</AuthMessage>
        </Box>
        {formError && (
          <Notification variant='error' mb='24px'>
            {formError}
          </Notification>
        )}

        <FormikForm value={formik}>
          <FormikInput
            name='first_name'
            label={t('form:label.firstName')}
            placeholder={t('form:placeholder.firstName')}
            apiError={apiError}
          />
          <FormikInput
            name='middle_name'
            label={t('form:label.middleName')}
            placeholder={t('form:placeholder.middleName')}
            apiError={apiError}
          />
          <FormikInput
            name='last_name'
            label={t('form:label.lastName')}
            placeholder={t('form:placeholder.lastName')}
            apiError={apiError}
          />

          <Box display='flex' flexDirection='column' rowGap='12px' mt='14px'>
            <Button type='submit'>{t('login:submitButtonLabel')}</Button>
            <Button variant='secondary' onClick={navigateToLogin} isLoading={isPending}>
              {t('login:returnToLoginButtonLabel')}
            </Button>
          </Box>
        </FormikForm>
      </AuthStep>
    </AuthLayout>
  );
};
