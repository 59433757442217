import { FunctionComponent, SVGProps } from 'react';
import OpcoUsersIcon from '../images/OpcoUsersIcon.svg?react';
import BagIcon from '../images/BagIcon.svg?react';
import BillingIcon from '../images/BillingIcon.svg?react';
import DepositAccountsIcon from '../images/DepositAccountsIcon.svg?react';
import ContactUsIcon from '../images/ContactUsIcon.svg?react';
import { getOpcoOrganizationsPath } from '../../_Opco/path/path';
import { getTransactionsPath } from '../../_OrgAdmin/pages/Transactions/path/path';
import { getRecipientWiresPath } from '../../_Recipient/path/path';
import { getContactUsPath } from '../../pages/ContactUsPage/path/path';
import { getExecutorWiresPath } from '../../_Executor/path/path';
import { getTransactionDetailsPath } from '../../_OrgAdmin/pages/Transactions/_Details/path/path';
import { getOutboundWireDetailsPath } from '../../_OrgAdmin/pages/OutboundWire/_Details/path/path';
import { getRecipientWireDetailsPath } from '../../_Recipient/_Details/path/path';
import { getExecutorWireDetailsPath } from '../../_Executor/_Details/path/path';
import { getDepositAccountsPath } from '../../_OrgAdmin/pages/DepositAccounts/path/path';
import { getBilledEventsPath } from '../../_Opco/_BillingPage/_BilledEventsPage/path/path';
import { getCreateDepositAccountPath } from '../../_OrgAdmin/pages/DepositAccounts/_Create/path/path';
import { getDepositAccountDetailsPath } from '../../_OrgAdmin/pages/DepositAccounts/_Details/path/path';
import { getInboundWireDetailsPath } from '../../_OrgAdmin/pages/InboundWire/path/path';
import { USER_ROLE_MAP } from '@/const/user';
import { getOpcoUsersPath } from '@/bundle/_Opco/_OpcoUsersPage/path/path';
import { getOpcoUserDetailsPath } from '@/bundle/_Opco/_OpcoUsersPage/Details/path/path';
import { getOpcoOrganizationUsersPath } from '@/bundle/_Opco/ui/OrganizationUsers/path/path';
import { getOrganizationUserDetailsPath } from '@/bundle/_Opco/ui/OrganizationUsers/Details/path/path';
import { useUser } from '@/context/userContext';
import { getDepositorWireDetailsPath } from '@/bundle/_Depositor/_Details/path/path';
import { getDepositorWiresPath } from '@/bundle/_Depositor/path/path';

type NestedMenuType = {
  title: string;
  url: string;
  paths?: string[];
};

export type MenuType = {
  title: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  url: string;
  subMenu?: NestedMenuType[];
  paths?: string[];
  disabled?: boolean;
};

type SideBarType = {
  [key: string]: {
    menu: MenuType[];
  };
};

const CONTACT_US = {
  title: 'Contact Us',
  icon: ContactUsIcon,
  url: getContactUsPath(),
  paths: [getContactUsPath()],
};

const OPCO_MENU = {
  menu: [
    {
      title: 'OpCo Users',
      icon: OpcoUsersIcon,
      url: getOpcoUsersPath(),
      paths: [getOpcoUsersPath(), getOpcoUserDetailsPath()],
    },
    {
      title: 'Org Management',
      icon: BagIcon,
      url: getOpcoOrganizationsPath(),
      paths: [getOpcoOrganizationsPath(), getOpcoOrganizationUsersPath(), getOrganizationUserDetailsPath()],
    },
    {
      title: 'Billing',
      icon: BillingIcon,
      url: getBilledEventsPath(),
      paths: [getBilledEventsPath()],
    },
  ],
};

const ORG_ADMIN_MENU = {
  menu: [
    {
      title: 'Transactions',
      icon: BagIcon,
      url: getTransactionsPath(),
      paths: [
        getTransactionsPath(),
        getTransactionDetailsPath(),
        getOutboundWireDetailsPath(),
        getInboundWireDetailsPath(),
      ],
    },
    {
      title: 'Deposit Accounts',
      icon: DepositAccountsIcon,
      url: getDepositAccountsPath(),
      paths: [getDepositAccountsPath(), getCreateDepositAccountPath(), getDepositAccountDetailsPath()],
    },
    {
      ...CONTACT_US,
    },
  ],
};

const RECIPIENT_MENU = {
  menu: [
    {
      title: 'Wires',
      icon: BagIcon,
      url: getRecipientWiresPath(),
      paths: [getRecipientWiresPath(), getRecipientWireDetailsPath()],
    },
    {
      ...CONTACT_US,
    },
  ],
};

const EXECUTOR_MENU = {
  menu: [
    {
      title: 'Wires',
      icon: BagIcon,
      url: getExecutorWiresPath(),
      paths: [getExecutorWiresPath(), getExecutorWireDetailsPath()],
    },
    {
      ...CONTACT_US,
    },
  ],
};

const DEPOSITOR_MENU = {
  menu: [
    {
      title: 'Wires',
      icon: BagIcon,
      url: getDepositorWiresPath(),
      paths: [getDepositorWiresPath(), getDepositorWireDetailsPath()],
    },
    {
      ...CONTACT_US,
    },
  ],
};

const USER_SIDEBAR_MENU_MAP: SideBarType = {
  [USER_ROLE_MAP.OPCO]: OPCO_MENU,
  [USER_ROLE_MAP.ORGANIZATION]: ORG_ADMIN_MENU,
  [USER_ROLE_MAP.RECIPIENT]: RECIPIENT_MENU,
  [USER_ROLE_MAP.EXECUTOR]: EXECUTOR_MENU,
  [USER_ROLE_MAP.DEPOSITOR]: DEPOSITOR_MENU,
};

export const useSidebar = () => {
  const { currentRole } = useUser();

  if (!currentRole) return { menu: [] };

  return USER_SIDEBAR_MENU_MAP[currentRole] || { menu: [] };
};
