import { ReactNode } from 'react';
import { LabelWrapper, UserDetailsHeaderWrapper, Wrapper, WrapperHeader } from './styles';
import OwnUserIcon from './images/OwnUserIcon.svg?react';
import { UserDetailsIcon, UserDetailsTitle, UserDetailsSubtitle } from '@/bundle/_Opco/shared/styles';

type OpcoUserInfoHeaderType = {
  title: string;
  icon: ReactNode;
  subTitle?: string;
  isOwnAccount?: boolean;
};

export const UserDetailsHeader = ({ title, icon, subTitle, isOwnAccount }: OpcoUserInfoHeaderType) => {
  return (
    <UserDetailsHeaderWrapper>
      <>
        <Wrapper>
          <WrapperHeader>
            <UserDetailsIcon>{icon}</UserDetailsIcon>
            {title && <UserDetailsTitle>{title}</UserDetailsTitle>}
          </WrapperHeader>
          {isOwnAccount && (
            <LabelWrapper>
              <OwnUserIcon />
              Your account
            </LabelWrapper>
          )}
        </Wrapper>
        {subTitle && <UserDetailsSubtitle>{subTitle}</UserDetailsSubtitle>}
      </>
    </UserDetailsHeaderWrapper>
  );
};
