import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { AssignedWireType } from '@/types/wireTypes';
import { getRecipientWireDetailsPath } from '../path/path';

type ReplacementWireRowType = {
  wire: AssignedWireType;
};

export const ReplacementWireRow = ({ wire }: ReplacementWireRowType) => {
  const { child_wire, parent_wire } = wire;
  const wireId = child_wire ? child_wire?.id : parent_wire?.id;
  const recipientWireDetailsUrl = getRecipientWireDetailsPath(wireId);
  const name = `${child_wire ? 'Replaced by' : 'Replacement of'}`;
  const value = child_wire ? child_wire.name : parent_wire.name;

  return (
    <DetailsRow name={name}>
      <NavigationLink to={recipientWireDetailsUrl}>{value}</NavigationLink>
    </DetailsRow>
  );
};
