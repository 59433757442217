import { BilledEventType } from '@/api/v1/opco/events/getBilledEventsApi';
import { Maybe } from '@/types/sharedTypes';
import { DEFAULT_FILTER_PARAMS, FilterParamsType, QueryParamsType } from '@/api/helpers/queryHelpers';
import { ApiParams, prepareBaseListApiParams } from '@/api/helpers/urlApiHelpers';

export type BilledEventsListType = {
  id: string;
  org_name: Maybe<string>;
  date_viewed: Maybe<string>;
  wire_name: Maybe<string>;
  wire_ref_id: Maybe<string>;
  user_viewed: Maybe<string>;
  transaction_name: Maybe<string>;
  id_verification: boolean;
  payment_details_collection_type: BilledEventType['wire']['payment_details_collection_type'];
};

export const getBilledEventsList = (billedEvents: BilledEventType[] = []): BilledEventsListType[] => {
  return billedEvents.map(({ id, date_created, wire, user, organization, id_verification }) => {
    return {
      id,
      org_name: organization?.name,
      date_viewed: date_created,
      wire_name: wire?.name,
      id_verification,
      wire_ref_id: wire?.ref_id,
      user_viewed: user?.email,
      transaction_name: wire?.contract?.name,
      payment_details_collection_type: wire?.payment_details_collection_type,
    };
  });
};

export const prepareBillingSearchFiltersListApiParams = (queryParams?: QueryParamsType): ApiParams => {
  const baseParams = prepareBaseListApiParams(queryParams);

  const params = { ...baseParams };

  if (queryParams.filters) {
    const filterValues: FilterParamsType = queryParams.filters;
    const filterNames = Object.keys(DEFAULT_FILTER_PARAMS);

    filterNames.forEach((filterName) => {
      const value = filterValues[filterName];

      if (value) {
        params[filterName] = Array.isArray(value) ? value.map((item) => item?.value || item).join(',') : value;
      }
    });
  }

  if (queryParams.search) {
    params.search = queryParams.search;
  }

  return params;
};
