import { useNavigate } from 'react-router-dom';
import { getForgotPasswordNextStepPath } from '../../const/const';
import { ForgotPasswordResponseType } from '@/types/sharedTypes';
import { authTokenService } from '@/bundle/shared/services/authTokenService';
import { getForgotPasswordEmailSentPath } from '../ForgotPasswordEmailSentPage/path/path';

export const useForgotPasswordNavigateToStep = () => {
  const navigate = useNavigate();

  const navigateToStep = (body: ForgotPasswordResponseType) => {
    if (body?.is_completed) {
      authTokenService.removeTokens();

      return navigate(getForgotPasswordEmailSentPath());
    }

    if (body?.auth_token) {
      authTokenService.setTokens({ access: body?.auth_token });

      const nextPath = getForgotPasswordNextStepPath(body?.auth_token);

      return navigate(nextPath);
    }
  };

  return navigateToStep;
};
