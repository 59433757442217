import { Route } from 'react-router-dom';
import { ExecutorWiresPage } from '../ExecutorWiresPage';
import { getExecutorWiresPath } from '../path/path';
import { ExecutorWireDetailsPage } from '../_Details/ExecutorWireDetailsPage';
import { getExecutorWireDetailsPath } from '../_Details/path/path';

export const ExecutorRoutes = [
  <Route key={getExecutorWiresPath()} path={getExecutorWiresPath()} element={<ExecutorWiresPage />} />,
  <Route
    key={getExecutorWireDetailsPath()}
    path={getExecutorWireDetailsPath()}
    element={<ExecutorWireDetailsPage />}
  />,
];
