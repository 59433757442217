import DarkInfoIcon from '../../../../images/darkInfoIcon.svg?react';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Box } from '@/components/Box/Box';
import { TooltipIconWrapper } from './styles';

type UtcTooltipType = {
  message: string;
};

export const UtcTooltip = ({ message }: UtcTooltipType) => {
  return (
    <Box mr='6px'>
      <Tooltip id='header-tooltip' offset={2}>
        {message}
      </Tooltip>
      <TooltipIconWrapper>
        <DarkInfoIcon data-tooltip-id='header-tooltip' />
      </TooltipIconWrapper>
    </Box>
  );
};
