import { Id, ToastContainerProps, ToastContent, ToastOptions, cssTransition, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloseButtonWrapper, StyledSnackbar } from './styles';
import SuccessIcon from './images/SuccessIcon.svg?react';
import ErrorIcon from './images/ErrorIcon.svg?react';
import CloseIcon from './images/CloseIcon.svg?react';
import { ValueOf } from '@/types/sharedTypes';

const SNACKBAR_VARIANT = {
  success: 'success',
  error: 'error',
} as const;

const visibilityAnimation = cssTransition({
  enter: 'slideIn',
  exit: 'slideOut',
});

type SnackbarContainerType = ToastContainerProps;

type SnackbarOptionsType = {
  maxWidth?: string;
  variant?: ValueOf<typeof SNACKBAR_VARIANT>;
} & ToastOptions;

type SnackbarContentType = ToastContent;

const prepareOptions = (options: SnackbarOptionsType = {}) => {
  const formattedOptions = { variant: 'success', ...options };

  if (formattedOptions.maxWidth) {
    formattedOptions.style = { maxWidth: formattedOptions.maxWidth };
  }

  return formattedOptions;
};

const CloseButton = ({ closeToast }) => {
  return (
    <CloseButtonWrapper onClick={closeToast} aria-label='close'>
      <CloseIcon />
    </CloseButtonWrapper>
  );
};

export const Snackbar = (props: SnackbarContainerType) => {
  return (
    <StyledSnackbar
      theme='dark'
      position='top-right'
      pauseOnHover
      closeButton={CloseButton}
      autoClose={5000}
      draggable={false}
      transition={visibilityAnimation}
      {...props}
    />
  );
};

export const showSnackbar = (content: SnackbarContentType, options: SnackbarOptionsType = {}) => {
  const { variant, ...restOptions } = prepareOptions(options);

  if (variant === SNACKBAR_VARIANT.success) return toast.success(content, { icon: <SuccessIcon />, ...restOptions });

  if (variant === SNACKBAR_VARIANT.error) return toast.error(content, { icon: <ErrorIcon />, ...restOptions });

  return toast(content, restOptions);
};

export const removeSnackbarById = (snackbarId: Id) => {
  toast.dismiss(snackbarId);
};

export const removeAllSnackbars = () => {
  toast.dismiss();
};
