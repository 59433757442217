import { InboundWrapper, OutboundWrapper } from './styles';
import InboundArrowIcon from './images/InboundArrowIcon.svg?react';
import OutboundArrowIcon from './images/OutboundArrowIcon.svg?react';

export const WireInboundTypeIconComponent = () => {
  return (
    <InboundWrapper>
      <InboundArrowIcon />
    </InboundWrapper>
  );
};

export const WireOutboundTypeIconComponent = () => {
  return (
    <OutboundWrapper>
      <OutboundArrowIcon />
    </OutboundWrapper>
  );
};
