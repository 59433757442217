import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { ResetRequest } from '@/bundle/shared/components/ResetRequest/ResetRequest';
import { useState } from 'react';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { resetRequestAuth } from './api';

export const ResetRequestAuthPage = () => {
  const navigate = useNavigate();

  const [confirmationStep, setConfirmationStep] = useState(true);

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['reset_request_auth'],
    mutationFn: () => {
      return resetRequestAuth();
    },
    onSuccess(resetRequestResponse) {
      if (resetRequestResponse?.error) return;

      setConfirmationStep(false);
    },
  });

  const error = getResponseError(data?.error);

  const redirectToLogin = () => {
    navigate(getLoginPath());
  };

  return (
    <AuthLayout>
      <AuthStep>
        <ResetRequest
          confirmationStep={confirmationStep}
          isLoading={isPending}
          error={error}
          onConfirm={mutate}
          onSuccess={redirectToLogin}
          onCancel={redirectToLogin}
        />
      </AuthStep>
    </AuthLayout>
  );
};
