import {
  SwitchRoleDescription,
  SwitchRoleIcon,
  SwitchRoleItemWrapper,
  SwitchRoleTitle,
} from '@/bundle/pages/SwitchRolePage/ui/styles';
import { Box } from '@/components/Box/Box';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface SwitchRoleItemType {
  role: {
    title: string;
    value: string;
    description: string;
    icon: ReactNode;
  };
  isSelected: boolean;
  onChange: (string) => void;
}

export const SwitchRoleItem = ({ role, onChange, isSelected }: SwitchRoleItemType) => {
  const { title, value, description, icon } = role;
  const { t } = useTranslation('login');

  return (
    <SwitchRoleItemWrapper onClick={() => onChange(value)} isSelected={isSelected}>
      <Box flexDirection='row'>
        <SwitchRoleIcon isSelected={isSelected}>{icon}</SwitchRoleIcon>
        <Box flexDirection='column' justifyContent='space-between'>
          <SwitchRoleTitle>{t(title)}</SwitchRoleTitle>
          <SwitchRoleDescription>{t(description)}</SwitchRoleDescription>
        </Box>
      </Box>
    </SwitchRoleItemWrapper>
  );
};
