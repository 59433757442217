import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { COLORS } from '@/styles/colors';
import { Typography } from '@/components/Typography/Typography';
import { useTranslation } from 'react-i18next';

export const RequestSentModal = () => {
  const { t } = useTranslation('login');

  return (
    <Box width='420px' rowGap='32px' alignItems='center' flexDirection='column' justifyContent='column'>
      <Typography color={COLORS.grey[600]} textAlign='center'>
        {t('login:requestSendModalMessage')}
      </Typography>
      <Button variant='primary'>{t('login:okButtonLabel')}</Button>
    </Box>
  );
};
