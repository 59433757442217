import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { ApiResponseType, RequestOptions } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export type CreateOrganizationUserType = {
  email: string;
  mobile_phone: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
};

export const createOrganizationUserApi = async (
  organizationId: string,
  data: CreateOrganizationUserType,
  params?: ApiParams,
  options?: RequestOptions
): Promise<ApiResponseType<CreateOrganizationUserType>> => {
  const uri = `/opco/organizations/${organizationId}/users/`;

  return authApiService.POST(uri, data, params, options);
};
