import { COLORS } from '@/styles/colors';
import { body, bodySmall, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';
import { flex } from '@/styles/layout';

export type InputSizeVariant = 'small' | 'medium';

type InputType = {
  variant?: InputSizeVariant;
  hasAfter?: boolean;
  hasBefore?: boolean;
};

type InputWrapperType = InputType & {
  hasError?: boolean;
  disabled?: boolean;
};

export const INPUT_SIZE_PADDING: { [key in InputSizeVariant]: number } = {
  small: 12,
  medium: 16,
};

const getPaddingInlineWrapper = ({ hasAfter, hasBefore, variant }: InputType) => {
  if (hasAfter && hasBefore) {
    return `${INPUT_SIZE_PADDING[variant]}px`;
  }

  if (hasAfter) {
    return `0 ${INPUT_SIZE_PADDING[variant]}px`;
  }

  if (hasBefore) {
    return `${INPUT_SIZE_PADDING[variant]}px 0`;
  }
};

export const CustomInput = styled.input<InputType>`
  width: 100%;
  min-width: 0;
  flex: 1;
  outline: none;
  display: block;
  color: ${COLORS.black};
  ${poppinsRegularFont};
  border: none;
  ${body};
  padding: 0 ${INPUT_SIZE_PADDING.medium}px;
  background-color: transparent;

  &[type='password'] {
    font-size: 22px;
    letter-spacing: 1px;
  }

  &:placeholder-shown {
    ${body};
  }

  ::placeholder {
    ${body};
    color: ${COLORS.grey[600]};
    font-family: ${poppinsRegularFont};
  }

  ${({ variant }) =>
    variant === 'small' &&
    `
    ${bodySmall};
    padding: 0 ${INPUT_SIZE_PADDING.small}px;

    &:placeholder-shown {
      ${bodySmall};
    }

    ::placeholder {
      ${bodySmall};
    }
  `};

  ${({ hasAfter }) =>
    hasAfter &&
    `
      padding-right: 10px;
    `}

  ${({ hasBefore }) =>
    hasBefore &&
    `
      padding-left: 10px;
    `}
`;

export const BeforeAfterWrapper = styled.div`
  ${flex({ alignItems: 'center' })};
`;

export const InputWrapper = styled.div<InputWrapperType>`
  ${flex()};
  width: 100%;
  height: 50px;
  border: ${({ hasError }) => (hasError ? `1px solid ${COLORS.red[600]}` : `1px solid ${COLORS.grey[400]}`)};
  padding: 0;
  border-radius: 10px;
  background: ${COLORS.grey[250]};
  overflow: hidden;
  padding-inline: ${({ hasAfter, hasBefore, variant }) => getPaddingInlineWrapper({ hasAfter, hasBefore, variant })};

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    border: 1px solid ${COLORS.grey[300]};
    background-color: ${COLORS.grey[200]};
  `}

  ${({ variant }) =>
    variant === 'small' &&
    `
      height: 40px;
  `}

  &:focus-within {
    border: 1px solid ${COLORS.green[500]};
    box-shadow: 0 0 2px rgb(0 134 111 / 60%);
    background: ${COLORS.white};
  }

  &:hover:not(:focus-within) {
    border: 1px solid ${COLORS.grey[700]};
    background: ${COLORS.grey[250]};
  }
`;
