import { SwitchRoleButtonWrapper } from '@/bundle/Sidebar/ui/SwitchRoleButton/styles';
import { useNavigate } from 'react-router-dom';
import { getSwitchRolePath } from '@/bundle/pages/SwitchRolePage/path/path';
import { AMPLITUDE_USER_ROLE_LABEL, amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { UserRoleType } from '@/const/user';

interface SwitchRoleButtonType {
  role: UserRoleType;
}

export const SwitchRoleButton = ({ role }: SwitchRoleButtonType) => {
  const navigate = useNavigate();

  const navigateToSwitchRole = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.SwitchRoleStartRedirect, { role: AMPLITUDE_USER_ROLE_LABEL[role] });

    navigate(getSwitchRolePath());
  };

  return <SwitchRoleButtonWrapper onClick={navigateToSwitchRole}>Switch Role</SwitchRoleButtonWrapper>;
};
