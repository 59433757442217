import { useUser } from '@/context/userContext';
import { getAccessDeniedPath } from '@/bundle/pages/AccessDeniedPage/path/path';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { USER_ROLE_MAP, UserRoleType } from '@/const/user';

export const ALLOWED_ROLES: UserRoleType[] = [
  USER_ROLE_MAP.ORGANIZATION,
  USER_ROLE_MAP.EXECUTOR,
  USER_ROLE_MAP.DEPOSITOR,
  USER_ROLE_MAP.RECIPIENT,
];

export const ContactUsProtectedRoute = () => {
  const location = useLocation();
  const { currentRole } = useUser();
  const accessDeniedUrl = getAccessDeniedPath();
  const hasAccess = ALLOWED_ROLES.includes(currentRole);

  if (hasAccess) {
    return <Outlet />;
  } else {
    return <Navigate to={accessDeniedUrl} state={{ from: location }} replace />;
  }
};
