import { Route } from 'react-router-dom';
import { getDepositAccountsPath } from '../path/path';
import { DepositAccountsPage } from '../DepositAccountsPage';
import { CreateDepositAccountPage } from '../_Create/CreateDepositAccountPage';
import { getCreateDepositAccountPath } from '../_Create/path/path';
import { DepositAccountDetailsPage } from '../_Details/DepositAccountDetailsPage';
import { getDepositAccountDetailsPath } from '../_Details/path/path';

export const DepositAccountsRoutes = [
  <Route key={getDepositAccountsPath()} path={getDepositAccountsPath()} element={<DepositAccountsPage />} />,
  <Route
    key={getDepositAccountDetailsPath()}
    path={getDepositAccountDetailsPath()}
    element={<DepositAccountDetailsPage />}
  />,
  <Route
    key={getCreateDepositAccountPath()}
    path={getCreateDepositAccountPath()}
    element={<CreateDepositAccountPage />}
  />,
];
