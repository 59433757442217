import { useNavigate } from 'react-router-dom';
import RejectIcon from '../../../../../../images/circleRejectIcon.svg?react';
import SuccessIcon from '../../../../../../images/circleSuccessIcon.svg?react';
import { DepositAccountLinkWrapper, DepositAccountTitle, StatusBlock, Title } from './styles';
import { DEPOSIT_ACCOUNT_STATUS } from '@/const/shared';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getDepositAccountDetailsPath } from '../../../DepositAccounts/_Details/path/path';

type DepositAccountLinkType = {
  depositAccount?: {
    id: string;
    name: string;
    status: string;
  };
};

export const DepositAccountLink = ({ depositAccount }: DepositAccountLinkType) => {
  const navigate = useNavigate();

  const navigateToDepositAccount = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ReferenceDepositAccountRedirect);

    navigate(getDepositAccountDetailsPath(depositAccount?.id));
  };

  const currentStatusIcon = depositAccount.status === DEPOSIT_ACCOUNT_STATUS.ACTIVE ? <SuccessIcon /> : <RejectIcon />;

  return (
    <DepositAccountLinkWrapper>
      <StatusBlock>
        {currentStatusIcon}
        <Title>Reference Deposit Account</Title>
      </StatusBlock>
      <DepositAccountTitle title={depositAccount.name} onClick={navigateToDepositAccount}>
        {depositAccount.name}
      </DepositAccountTitle>
    </DepositAccountLinkWrapper>
  );
};
