import {
  getPaymentDetailsSchema,
  PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
  ProvidePaymentFormType,
} from '@/bundle/shared/components/ProvidePaymentDetailsForm/const/const';
import { REQUIRED_TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';
import { PaymentType } from '@/types/paymentDetailsTypes';
import * as Yup from 'yup';

export type CreteDepositAccountFormType = ProvidePaymentFormType & {
  name: string;
};

export const CREATE_DEPOSIT_ACCOUNT_INITIAL_VALUES: CreteDepositAccountFormType = {
  name: '',
  ...PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
};

export const getCreateDepositAccountSchema = (paymentType: PaymentType, hasIntermediary: boolean) => {
  const providePaymentDetailsSchema = getPaymentDetailsSchema(paymentType, hasIntermediary);

  return Yup.object()
    .shape({
      name: REQUIRED_TEXT_FIELD_VALIDATOR,
    })
    .concat(providePaymentDetailsSchema);
};
