import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { authTokenService } from '@/bundle/shared/services/authTokenService';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { sessionStorageService } from '@/helpers/storageHelpers';
import { REDIRECT_URL_KEY, TOKEN_REFRESHED_EVENT } from '@/const/shared';
import { ORG_ADMIN_ROLE, USER_ROLE_MAP, UserRoleType } from '@/const/user';
import { getOrganizationRole, getStorageCurrentRole, setStorageCurrentRole } from '@/helpers/userHelpers';
import { SelfUserDetailsType } from '@/api/v1/users/getSelfUserDetailsApi';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { loadSelfUserDetails } from '@/bundle/Auth/LoginFlow/LoginPage/api';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';

interface UserContextType {
  user: SelfUserDetailsType;
  loading: boolean;
  currentRole: UserRoleType;
  hasMultipleUserRoles: boolean;
  setRole: (role: UserRoleType) => void;
  refetchUser: () => void;
  isOrganizationAdminRole: boolean;
  isOrgAdminRole: boolean;
  isSiteAdminRole: boolean;
  isOpcoRole: boolean;
  isExecutorRole: boolean;
}

const UserContextProvider = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: { children?: ReactNode }) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<SelfUserDetailsType>(null);
  const [hasMultipleUserRoles, setHasMultipleUserRoles] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const tokens = authTokenService.getTokens();

  const { data: userData, isPending } = useQuery({
    queryKey: ['load_self_user'],
    queryFn: () => loadSelfUserDetails(),
    enabled: !!tokens?.user_id,
  });

  const setRole = (role: UserRoleType) => {
    setCurrentUserRole(role);
    setStorageCurrentRole(role);
  };

  const getRole = () => {
    if (currentUserRole) {
      return currentUserRole;
    }

    const role = getStorageCurrentRole();

    if (role) {
      setRole(role);
    }

    return role;
  };

  const refetchUser = () => {
    queryClient.invalidateQueries({ queryKey: ['load_self_user'] });
  };

  const organizationRole = getOrganizationRole(user);
  const isOrganizationAdminRole = currentUserRole === USER_ROLE_MAP.ORGANIZATION;
  const isOrgAdminRole = isOrganizationAdminRole && organizationRole === ORG_ADMIN_ROLE.ORG_ADMIN;
  const isSiteAdminRole = isOrganizationAdminRole && organizationRole === ORG_ADMIN_ROLE.SITE_ADMIN;
  const isOpcoRole = currentUserRole === USER_ROLE_MAP.OPCO;
  const isExecutorRole = currentUserRole === USER_ROLE_MAP.EXECUTOR;

  useEffect(() => {
    if (!tokens?.user_id) {
      sessionStorageService.set(REDIRECT_URL_KEY, location);

      return navigate(getLoginPath());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData?.body) {
      setUser(userData?.body);

      setHasMultipleUserRoles(userData?.body?.active_roles.length > 1);
      amplitudeService.setUser(userData.body, currentUserRole);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.body]);

  useEffect(() => {
    if (userData?.error) {
      navigate(getLoginPath());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.error]);

  useEffect(() => {
    // Load user details whenever access token updated to keep user roles up to date.
    window.addEventListener(TOKEN_REFRESHED_EVENT, () => {
      refetchUser();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContextProvider.Provider
      value={{
        user,
        loading: isPending,
        currentRole: getRole(),
        hasMultipleUserRoles,
        setRole,
        refetchUser,
        isOrganizationAdminRole,
        isOrgAdminRole,
        isSiteAdminRole,
        isOpcoRole,
        isExecutorRole,
      }}
    >
      {children ? children : <Outlet />}
    </UserContextProvider.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContextProvider);
};
