import { Box } from '@/components/Box/Box';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import InfoIcon from './images/InfoIcon.svg?react';
import { ReactNode, useId } from 'react';

type InfoTooltipBadgeType = {
  children: ReactNode;
};

export const InfoTooltipBadge = ({ children }: InfoTooltipBadgeType) => {
  const id = useId();

  return (
    <>
      <Tooltip id={id}>{children}</Tooltip>
      <Box focusable>
        <InfoIcon data-tooltip-id={id} />
      </Box>
    </>
  );
};
