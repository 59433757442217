import {
  EIN_SSN_CODE_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';
import { OrganizationStatusType } from '../../types/types';

export const STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'blocked', label: 'Blocked' },
];

type OptionType = {
  value: string;
  label: string;
};

export const CREATE_ORGANIZATION_INITIAL_VALUE = {
  name: '',
  ein: '',
  pricing_tier: null,
  is_kyc_allowed: false,
};

type OrganizationDataType = {
  name: string;
  ein: string;
  id: string;
  is_kyc_allowed: boolean;
  pricing_tier: { id: string; name: string };
  status: OrganizationStatusType;
};

export const CREATE_ORGANIZATION_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_TEXT_FIELD_VALIDATOR,
  ein: EIN_SSN_CODE_VALIDATOR,
  pricing_tier: REQUIRED_OBJECT_VALIDATOR,
});

export const EDIT_ORGANIZATION_VALIDATION_SCHEMA = CREATE_ORGANIZATION_VALIDATION_SCHEMA.shape({
  is_kyc_allowed: Yup.boolean(),
  status: REQUIRED_OBJECT_VALIDATOR,
});

export type FormValuesType = {
  ein: string;
  name: string;
  pricing_tier?: { label: string; value: string };
  status?: { label: string; value: OrganizationStatusType };
  is_kyc_allowed: boolean;
};

export const getEditOrganizationInitialValues = (
  pricingTierOptions: OptionType[],
  organizationData: OrganizationDataType
) => {
  const selectedPricingTier = pricingTierOptions?.find((option) => option?.value === organizationData?.pricing_tier.id);
  const currentStatus = STATUS_OPTIONS.find((option) => option.value === organizationData?.status);

  return {
    name: organizationData?.name,
    ein: organizationData?.ein,
    is_kyc_allowed: organizationData?.is_kyc_allowed,
    pricing_tier: selectedPricingTier,
    status: currentStatus as FormValuesType['status'],
  };
};

// TODO v13.0.0: fix pricing plans when BE will be ready
const SUPPORTED_PLANS = ['FREE', 'STARTER', 'PRO', 'CUSTOM'];

export const getPricingOptions = (pricingTiers: Array<{ id: string; name: string }> = []) => {
  return pricingTiers
    .filter((plan) => SUPPORTED_PLANS.includes(plan.name))
    .map((plan) => {
      return { value: plan.id, label: plan.name };
    });
};
