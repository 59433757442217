import { ClipboardEvent, ComponentPropsWithoutRef, ReactNode } from 'react';
import { Box } from '@/components/Box/Box';
import { CustomInput, BeforeAfterWrapper, InputSizeVariant, InputWrapper } from './styles';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { InputError } from '../InputError/InputError';
import ConfirmInputTicks from '@/images/ConfirmInputTicks.svg?react';

export type InputType = {
  label?: ReactNode;
  variant?: InputSizeVariant;
  labelHint?: ReactNode;
  before?: ReactNode;
  after?: ReactNode;
  hasConfirmTicks?: boolean;
  hasErrorComponent?: boolean;
  error?: string;
  apiError?: string;
  children?: ReactNode;
  isCopyPasteDisabled?: boolean;
} & ComponentPropsWithoutRef<'input'>;

export const Input = ({
  type = 'text',
  label,
  variant = 'medium',
  labelHint,
  before,
  after,
  hasConfirmTicks,
  hasErrorComponent = true,
  error,
  apiError,
  isCopyPasteDisabled,
  disabled,
  ...rest
}: InputType) => {
  const hasError = !!error || !!apiError;

  const onCopyPasteHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    if (isCopyPasteDisabled) {
      event.preventDefault();
    }
  };

  return (
    <Box width='100%'>
      <Box display='flex' alignItems='center' justifyContent='space-between' columnGap='10px'>
        {label && (
          <Typography variant='bodySmall' color={COLORS.black} mb='4px'>
            {label}
          </Typography>
        )}
        {labelHint && (
          <Typography variant='caption' color={COLORS.grey[700]} fontWeight='semibold' mb='4px'>
            {labelHint}
          </Typography>
        )}
      </Box>
      <InputWrapper
        variant={variant}
        disabled={disabled}
        hasError={hasError}
        hasAfter={!!after || hasConfirmTicks}
        hasBefore={!!before}
      >
        {!!before && <BeforeAfterWrapper>{before}</BeforeAfterWrapper>}
        <CustomInput
          variant={variant}
          onPaste={onCopyPasteHandler}
          onCopy={onCopyPasteHandler}
          disabled={disabled}
          type={type}
          hasAfter={!!after || hasConfirmTicks}
          hasBefore={!!before}
          {...rest}
        />
        {hasConfirmTicks && (
          <Box display='flex' alignItems='center'>
            <ConfirmInputTicks />
          </Box>
        )}
        {!!after && <BeforeAfterWrapper>{after}</BeforeAfterWrapper>}
      </InputWrapper>
      {hasErrorComponent && (
        <Box display='flex'>
          <InputError error={error} apiError={apiError} />
        </Box>
      )}
    </Box>
  );
};
