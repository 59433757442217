import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/Button/Button';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { AuthTitle } from '../../ui/AuthTitle/AuthTitle';
import { AuthMessage } from '../../ui/styles';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { Box } from '@/components/Box/Box';

export const ForgotPasswordEmailSentPage = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordCheckEmailLoginRedirect);

    navigate(getLoginPath());
  };

  return (
    <AuthLayout>
      <AuthStep>
        <AuthTitle>Please Check Your Email</AuthTitle>
        <Box display='flex' flexDirection='column' mb='36px' rowGap='12px'>
          <AuthMessage>We've sent a message with the instructions to set your new password.</AuthMessage>
          <AuthMessage>If you do not receive it soon, please check your spam folder.</AuthMessage>
        </Box>
        <Button onClick={navigateToLogin}>Return to Login</Button>
      </AuthStep>
    </AuthLayout>
  );
};
