import styled from 'styled-components';

export const ReelAnimatedValueContainer = styled.div`
  overflow: hidden;
  height: 20px;
`;

export const ReelAnimatedValueGroup = styled.div<{ move: boolean }>`
  height: 100%;
  ${({ move }) => move && `animation: move ease-in-out 1s infinite;`};

  @keyframes move {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-100%);
    }
  }
`;

export const ReelAnimatedValueWrapper = styled.div`
  height: 20px;
  width: 10px;
`;
