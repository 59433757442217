import { ReactNode, useEffect, useRef } from 'react';
import {
  DrawerWrapper,
  DrawerOverlay,
  DrawerHeader,
  DrawerSubheader,
  CloseButton,
  DrawerContent,
  DrawerContentWrapper,
} from './styles';
import { Box } from '../Box/Box';
import { CSSTransition } from 'react-transition-group';
import CloseDrawerIcon from './images/CloseDrawerIcon.svg?react';
import FocusLock from 'react-focus-lock';

type DrawerType = {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  header?: string;
  subHeader?: ReactNode;
  externalBlock?: ReactNode;
};

const ESCAPE_KEY = 'Escape';

export const Drawer = ({ children, isOpen, header, subHeader, onClose, externalBlock }: DrawerType) => {
  const nodeRef = useRef(null);
  const hasExternalBlock = !!externalBlock;

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === ESCAPE_KEY) {
        onClose();
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CSSTransition in={isOpen} nodeRef={nodeRef} timeout={{ enter: 225, exit: 300 }} classNames='fade' unmountOnExit>
      <>
        <DrawerOverlay isOpen={isOpen} />
        <FocusLock>
          <DrawerWrapper ref={nodeRef} hasExternalBlock={hasExternalBlock}>
            {externalBlock && <>{externalBlock}</>}
            <DrawerContentWrapper>
              <DrawerContent hasExternalBlock={hasExternalBlock}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  {header && <DrawerHeader>{header}</DrawerHeader>}
                  {onClose && (
                    <CloseButton onClick={onClose}>
                      <CloseDrawerIcon />
                    </CloseButton>
                  )}
                </Box>
                {subHeader && <DrawerSubheader>{subHeader}</DrawerSubheader>}
                {children}
              </DrawerContent>
            </DrawerContentWrapper>
          </DrawerWrapper>
        </FocusLock>
      </>
    </CSSTransition>
  );
};
