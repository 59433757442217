import { Route } from 'react-router-dom';
import { TransactionsPage } from '../../../TransactionsPage';
import { getTransactionsPath } from '../path/path';
import { getTransactionDetailsPath } from '../_Details/path/path';
import { TransactionDetailsPage } from '../_Details/TransactionDetailsPage';

export const TransactionRoutes = [
  <Route key={getTransactionsPath()} path={getTransactionsPath()} element={<TransactionsPage />} />,
  <Route key={getTransactionDetailsPath()} path={getTransactionDetailsPath()} element={<TransactionDetailsPage />} />,
];
