import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { AppErrorWrapper, ButtonWrapper, ErrorMessage, ErrorTitle } from './styles';
import ServerErrorMessage from '../../images/ServerErrorMessage/ServerErrorMessage.svg?react';
import { useErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { getDashboardRedirectPath } from '@/bundle/Auth/LoginFlow/LoginPage/const/const';
import { useUser } from '@/context/userContext';

interface AppErrorType {
  errorMessage?: string;
}

export const AppError = ({ errorMessage }: AppErrorType) => {
  const navigate = useNavigate();
  const { resetError } = useErrorBoundary();
  const { currentRole } = useUser();

  const navigateToRoot = () => {
    const dashboardPath = getDashboardRedirectPath(currentRole);

    resetError();
    navigate(dashboardPath);
  };

  return (
    <AppErrorWrapper>
      <ServerErrorMessage />
      <ErrorTitle>Server Error</ErrorTitle>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <ButtonWrapper>
        <Button width={260} onClick={navigateToRoot}>
          Go to Dashboard
        </Button>
      </ButtonWrapper>
    </AppErrorWrapper>
  );
};
