import { PIN_CODE_LENGTH } from '@/bundle/shared/components/PinCode/const/const';
import { IDENTICAL_DIGITS, SEQUENTIAL_DIGITS } from '@/const/regExpPatterns';

export const validatePinCode = (pinCode: string): string => {
  if (pinCode.length !== PIN_CODE_LENGTH) return;

  if (!IDENTICAL_DIGITS.test(pinCode)) {
    return `${PIN_CODE_LENGTH} identical digits are not allowed.`;
  }

  if (!SEQUENTIAL_DIGITS.test(pinCode)) return `${PIN_CODE_LENGTH} sequential digits are not allowed.`;
};

export const validateConfirmPinCode = (pinCode: string, confirmedPinCode: string): string => {
  if (confirmedPinCode.length !== PIN_CODE_LENGTH) return;

  if (pinCode !== confirmedPinCode) return 'PIN codes should match.';
};

export const createPinCodeNotification = 'In order to further secure this account please create a PIN Code.';
export const confirmPinCodeNotification = 'Please confirm your PIN code to ensure you entered it correctly.';

export const STEP_MAP = {
  CREATE_PIN_CODE: 'create_pin_code',
  CONFIRM_PIN_CODE: 'confirm_pin_code',
};
