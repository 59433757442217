import { Box } from '@/components/Box/Box';
import { BoldText, StepDescription, StepTitle } from './styles';
import LightBulbIcon from '../images/LightBulbIcon.svg?react';
import EyeGlassesIcon from '../images/EyeGlassesIcon.svg?react';
import RecordVideoIcon from '../images/RecordVideoIcon.svg?react';
import FaceIdIcon from '../images/FaceIdIcon.svg?react';

const DEFAULT_GAP = '8px';

export const RecordVideoStep = () => {
  return (
    <Box flexDirection='column' rowGap={DEFAULT_GAP}>
      <StepTitle>Step 2: Record a Video Selfie</StepTitle>
      <Box columnGap={DEFAULT_GAP} flexDirection='row'>
        <div>
          <RecordVideoIcon />
        </div>
        <Box flexDirection='column' rowGap={DEFAULT_GAP}>
          <Box flexDirection='row' columnGap={DEFAULT_GAP}>
            <Box>
              <LightBulbIcon />
            </Box>
            <StepDescription>
              <BoldText>Lighting:</BoldText> Ensure good frontal lighting.
            </StepDescription>
          </Box>
          <Box flexDirection='row' columnGap={DEFAULT_GAP}>
            <Box>
              <EyeGlassesIcon />
            </Box>
            <StepDescription>
              <BoldText>Remove Accessories:</BoldText> Take off glasses, masks, and hats. Remove glasses, masks and
              hats.
            </StepDescription>
          </Box>
          <Box flexDirection='row' columnGap={DEFAULT_GAP}>
            <Box>
              <FaceIdIcon />
            </Box>
            <StepDescription>
              {' '}
              <BoldText>Head Movement:</BoldText> Turn your head side to side. Swivel your head.
            </StepDescription>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
