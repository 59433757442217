import { Route } from 'react-router-dom';
import { getOpcoUsersPath } from '../path/path';
import { OpcoUsersPage } from '../OpcoUsersPage';
import { OpcoUserDetailsPage } from '../Details/OpcoUserDetailsPage';
import { getOpcoUserDetailsPath } from '../Details/path/path';

export const OpcoUserRoutes = [
  <Route key={getOpcoUsersPath()} path={getOpcoUsersPath()} element={<OpcoUsersPage />} />,
  <Route key={getOpcoUserDetailsPath()} path={getOpcoUserDetailsPath()} element={<OpcoUserDetailsPage />} />,
];
