import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { useTranslation } from 'react-i18next';

export const RequestResetAccountModal = () => {
  const { t } = useTranslation('login');

  return (
    <Box width='420px' rowGap='16px' flexDirection='column' alignItems='center'>
      <Typography color={COLORS.grey[600]} textAlign='center'>
        {t('login:resetRequestModalMessage')}
      </Typography>
      <div>{t('login:wantToContinueMessage')}</div>
      <Box columnGap='20px' flexDirection='row' width='100%'>
        <Button variant='secondary'>{t('login:noButtonLabel')}</Button>
        <Button variant='primary'>{t('login:yesButtonLabel')}</Button>
      </Box>
    </Box>
  );
};
