import styled from 'styled-components';
import { ellipsis } from '@/styles/common';
import { UserDetailsRow } from '@/bundle/_Opco/shared/styles';
import { flex } from '@/styles/layout';

export const InviteNotification = styled.div`
  max-width: 600px;
  width: 100%;
`;

export const UserDetailsEditableRow = styled.div`
  ${ellipsis};
`;

export const StatusDetailsRow = styled(UserDetailsRow)`
  ${flex({ flexDirection: 'column' })};
`;
