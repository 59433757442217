import { CSSObject } from 'styled-components';
import { BoxWrapperPropsType, PaddingSpacingPropsType, MarginSpacingPropsType } from './styles';

export const getSpacingStyle = (
  { mt, mr, mb, ml, m, pt, pr, pb, pl, p }: MarginSpacingPropsType & PaddingSpacingPropsType,
  variant: 'margin' | 'padding'
) => {
  const isPadding = variant === 'padding';
  const spacingVariant = isPadding ? p : m;

  // When spacingVariant(<m> or <p>) is defined it gets highest priority to parse, otherwise parse partial margins
  if (spacingVariant) {
    if (Array.isArray(spacingVariant)) {
      return { [variant]: spacingVariant.join(' ') };
    } else {
      return { [variant]: spacingVariant };
    }
  }

  return {
    [`${variant}Top`]: isPadding ? pt : mt,
    [`${variant}Right`]: isPadding ? pr : mr,
    [`${variant}Bottom`]: isPadding ? pb : mb,
    [`${variant}Left`]: isPadding ? pl : ml,
  };
};

const getDisplayStyles = ({ display, justifyContent, alignItems, flexDirection }: BoxWrapperPropsType) => {
  if (display) return display;

  return justifyContent || alignItems || flexDirection ? 'flex' : display;
};

const getEllipsisStyles = ({ ellipsis }: BoxWrapperPropsType) => {
  if (!ellipsis) return null;

  return {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
};

export const boxStyles = (props: BoxWrapperPropsType): CSSObject => {
  const {
    justifyContent,
    alignItems,
    flexDirection,
    flexWrap,
    flexGrow,
    flexShrink,
    rowGap,
    columnGap,
    width,
    position,
    height,
    cursor,
    textAlign,
    zIndex,
    wordBreak,
  } = props;
  const displayStyle = getDisplayStyles(props);
  const ellipsisStyles = getEllipsisStyles(props);
  const marginSpacingStyles = getSpacingStyle(props, 'margin');
  const paddingSpacingStyles = getSpacingStyle(props, 'padding');

  return {
    display: displayStyle,
    justifyContent,
    alignItems,
    flexDirection,
    flexWrap,
    flexGrow,
    flexShrink,
    rowGap,
    columnGap,
    width,
    position,
    height,
    cursor,
    textAlign,
    zIndex,
    wordBreak,
    ...ellipsisStyles,
    ...paddingSpacingStyles,
    ...marginSpacingStyles,
  };
};
