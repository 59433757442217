import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { loadOrganizationUser } from './api';
import { OrganizationUserPersonalDetails } from './ui/OrganizationUserPersonalDetails/OrganizationUserPersonalDetails';
import { OrganizationUserAccountDetails } from './ui/OrganizationUserAccountDetails/OrganizationUserAccountDetails';
import { Box } from '@/components/Box/Box';
import { getOpcoOrganizationUsersPath } from '../path/path';
import { UserDetailsHeader } from '@/bundle/shared/components/UserDetails/UserDetailsHeader/UserDetailsHeader';
import LogoSmallIcon from '../../../_OpcoUsersPage/Details/images/LogoSmall.svg?react';
import { InviteNotification } from '@/bundle/_Opco/ui/OrganizationUsers/Details/ui/OrganizationUserAccountDetails/styles';
import { Notification } from '@/components/Notification/Notification';
import { NotificationMessage, NotificationTitle } from '@/components/Notification/styles';
import { RESEND_INVITATION_NOTIFICATION } from '@/const/shared';
import { ORGANIZATION_USER_STATUS } from '@/bundle/_Opco/ui/OrganizationUsers/const/const';

export const OrganizationUserDetailsPage = () => {
  const navigate = useNavigate();
  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['load_organization_user', organizationId, userId],
    queryFn: () => loadOrganizationUser(organizationId, userId),
  });

  const refetchUser = () => {
    queryClient.invalidateQueries({ queryKey: ['load_organization_user', organizationId, userId] });
  };

  const navigateToOrganizationUsers = () => {
    navigate(getOpcoOrganizationUsersPath(organizationId));
  };

  const organizationUser = data?.body;
  const isInvited = organizationUser?.status === ORGANIZATION_USER_STATUS.INVITED;

  return (
    <ContentLayout
      title='org user details'
      backButton='Back to Organization Users'
      onBack={navigateToOrganizationUsers}
    >
      <PageBody>
        <Box>
          <UserDetailsHeader
            title='WireVault Account Information'
            icon={<LogoSmallIcon />}
            subTitle='You must enter your PIN code to edit these fields.'
          />
          <Box width='100%' display='flex' flexDirection='row' columnGap='28px'>
            {organizationUser && (
              <Box width='50%' display='flex' flexDirection='column' rowGap='64px'>
                <OrganizationUserAccountDetails user={organizationUser} onRefetchUser={refetchUser} />
                <OrganizationUserPersonalDetails user={organizationUser} onSave={refetchUser} />
              </Box>
            )}
            {isInvited && (
              <InviteNotification>
                <Notification>
                  <NotificationTitle>The invitation to WireVault has not been accepted.</NotificationTitle>
                  <NotificationMessage>
                    Please be sure the email address and mobile phone number are correct. You can change the information
                    for this account until the invitation is accepted.
                    <Box mt='12px'>{RESEND_INVITATION_NOTIFICATION}</Box>
                  </NotificationMessage>
                </Notification>
              </InviteNotification>
            )}
          </Box>
        </Box>
      </PageBody>
    </ContentLayout>
  );
};
