import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { headline4, poppinsSemiBoldFont } from '@/styles/typography';
import { media } from '@/styles/responsive';
import { THEME } from '@/styles/theme';
import { overlayBackground } from '@/styles/common';

export const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${THEME.modal.zIndex};
`;

export const ModalOverlay = styled.div`
  height: 100vh;
  position: fixed;
  inset: 0;
  z-index: ${THEME.modal.zIndex};
  ${overlayBackground};
`;

export const ModalContainer = styled.div`
  padding: 16px;
  height: 100%;
  position: relative;
  z-index: ${THEME.modal.zIndex};
  text-align: center;
  overflow: hidden auto;

  /* Note: this styles allows “vertical-align" to work for ModalBlock. */
  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
  }
`;

//Note: we’re using “inline-block” to fix proper vertical scroll for mobiles.
export const ModalBlock = styled.div<{ top?: number }>`
  position: relative;
  text-align: initial;
  vertical-align: middle;
  display: inline-block;
  padding: 42px;
  background: ${COLORS.white};
  box-shadow: 0 2px 20px rgb(63 77 90 / 25%);
  border-radius: 16px;
  will-change: transform;

  &.fade-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 150ms,
      transform 150ms;
  }

  ${media.mobile`
    width: 100%;
    max-width: 420px;
    padding: 20px 16px;
  `}
`;

export const ModalBlockHeader = styled.h4<{ isStartPosition?: boolean }>`
  ${headline4};
  ${poppinsSemiBoldFont};
  color: ${COLORS.grey[900]};
  margin: 0 0 24px;
  width: 100%;
  text-align: ${({ isStartPosition }) => (isStartPosition ? 'start' : 'center')};
`;
