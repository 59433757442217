import { AssignedWireType } from '@/types/wireTypes';
import { isRecipientChainProxy, isRecipientProxy } from '@/helpers/wireHelpers';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';
import { UserDetailsWidget } from '../UserDetailsWidget/UserDetailsWidget';
import { ReInvitationDetails } from '@/bundle/shared/components/UserDetailsWidget/ReInvitationDetails';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  reinviteRecipient,
  reinviteRecipientProxy,
} from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/api/recipientApi';
import { useState } from 'react';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { useTimer } from '@/hooks/useTimer';
import { RecipientChainProxyDetails } from '@/bundle/shared/components/RecipientDetails/ui/RecipientChainProxyDetails/RecipientChainProxyDetails';

type RecipientDetailsType = {
  wire: AssignedWireType;
  isRecipientEdit?: boolean;
  isProxyEditRecipient?: boolean;
  onEditRecipient?: () => void;
  onProxyEditRecipient?: () => void;
};

export const RecipientDetails = ({
  wire,
  isProxyEditRecipient,
  isRecipientEdit,
  onProxyEditRecipient,
  onEditRecipient,
}: RecipientDetailsType) => {
  const queryClient = useQueryClient();
  const isChainProxy = isRecipientChainProxy(wire);
  const proxyRecipientDetails = wire?.assigned_proxy_user;
  const recipientDetails = wire?.assigned_user;
  const isDocumentVerificationRequired = !!wire?.is_pd_provider_kyc_required;
  const isProxy = isRecipientProxy(wire);
  const userDetails = isProxy ? proxyRecipientDetails : recipientDetails;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const refetchOutboundWire = () => {
    queryClient.invalidateQueries({ queryKey: ['load_outbound_wire', wire?.id] });
  };

  const { seconds } = useTimer(userDetails?.invitation_data?.next_resend_available_in_sec, refetchOutboundWire);

  const { mutate: reinviteRecipientMutate } = useMutation({
    mutationKey: [isProxy ? 'reinvite_recipient_proxy' : 'reinvite_recipient', wire?.id],
    mutationFn: () => {
      return isProxy ? reinviteRecipientProxy(wire?.id) : reinviteRecipient(wire?.id);
    },
    onSuccess: (reinviteResponse) => {
      if (reinviteResponse.error) {
        const error = getResponseError(reinviteResponse.error);

        showSnackbar(error, { variant: 'error' });
        refetchOutboundWire();

        return setIsOpenModal(false);
      }

      refetchOutboundWire();
      setIsOpenModal(false);
    },
  });

  if (isChainProxy) {
    return (
      <RecipientChainProxyDetails
        wire={wire}
        isProxyEditRecipient={isProxyEditRecipient}
        onProxyEditRecipient={onProxyEditRecipient}
        refetchOutboundWire={refetchOutboundWire}
      />
    );
  }

  const header = isProxy ? 'Recipient Proxy' : 'Recipient';

  return (
    <UserDetailsWidget
      header={header}
      user={userDetails}
      action={isRecipientEdit && <DetailsEditButton onEdit={onEditRecipient} />}
      marker={isDocumentVerificationRequired && 'ID VERIFICATION'}
      hasInvitedNotification={isRecipientEdit}
    >
      {isRecipientEdit && (
        <ReInvitationDetails
          onConfirm={reinviteRecipientMutate}
          invitationDetails={userDetails?.invitation_data}
          onOpen={() => setIsOpenModal(true)}
          onClose={() => setIsOpenModal(false)}
          isOpen={isOpenModal}
          seconds={seconds}
        />
      )}
    </UserDetailsWidget>
  );
};
