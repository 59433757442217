import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

type CheckAuthPinCodePayloadType = {
  pin: string;
};

export const checkAuthPinCodeApi = (data: CheckAuthPinCodePayloadType): Promise<ApiResponseType<any>> => {
  const uri = '/users/auth/pin/';

  return authApiService.POST(uri, data);
};
