import { forwardRef, MouseEvent } from 'react';
import DatePickerIcon from '@/images/CalendarIcon.svg?react';
import { DatePickerInputWrapper } from './styles';
import { Input, InputType } from '../../Input/Input';
import { InputClearButton } from '../../Input/ui/InputClearButton/InputClearButton';
import { Box } from '@/components/Box/Box';

type DatePickerInputType = {
  value: string;
  onOpen: (event: MouseEvent<HTMLDivElement>) => void;
  onClear: () => void;
  isClearable?: boolean;
  error: string;
  apiError: string;
} & InputType;

export const DatePickerInput = forwardRef((props: DatePickerInputType, ref: React.Ref<HTMLDivElement>) => {
  const {
    label,
    placeholder,
    value,
    onOpen,
    onClear,
    variant = 'medium',
    isClearable = true,
    hasErrorComponent,
    error,
    apiError,
    ...rest
  } = props;

  const hasClearIcon = value && isClearable;

  const clearDate = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    onClear();
  };

  return (
    <>
      <DatePickerInputWrapper onClick={onOpen} ref={ref}>
        <Input
          placeholder={placeholder}
          value={value}
          readOnly
          hasErrorComponent={hasErrorComponent}
          error={error}
          apiError={apiError}
          label={label}
          variant={variant}
          after={
            <>
              {hasClearIcon && <InputClearButton onClear={clearDate} />}
              <Box focusable>
                <DatePickerIcon />
              </Box>
            </>
          }
          {...rest}
        />
      </DatePickerInputWrapper>
    </>
  );
});
