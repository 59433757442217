import { ReactNode, createContext, useContext, useState } from 'react';
import { RequiredAuthError } from '../Error/classes/RequiredAuthError';
import { authTokenService } from '@/bundle/shared/services/authTokenService';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/path/path';
import { useNavigate } from 'react-router-dom';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { BlockedUserError } from '../Error/classes/BlockedUserError';
import { CURRENT_ROLE_KEY } from '@/const/shared';
import { sessionStorageService } from '@/helpers/storageHelpers';
import { AccessDeniedError } from '../Error/classes/AccessDeniedError';
import { getAccessDeniedPath } from '../pages/AccessDeniedPage/path/path';

interface ErrorBoundaryType {
  children: ReactNode;
}

interface ErrorContextType {
  error: Error;
  setError: (error: Error) => void;
  resetError: () => void;
}

const ErrorBoundaryContext = createContext<ErrorContextType>({} as ErrorContextType);

export const ErrorBoundary = ({ children }: ErrorBoundaryType) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const resetUser = () => {
    amplitudeService.resetUser();
    authTokenService.removeTokens();
    sessionStorageService.remove(CURRENT_ROLE_KEY);
  };

  const setApplicationError = (e: Error) => {
    if (e instanceof RequiredAuthError) {
      resetUser();

      return navigate(getLoginPath(), { state: { sessionExpired: true } });
    }

    if (e instanceof BlockedUserError) {
      resetUser();

      return navigate(getLoginPath(), { state: { accountBlocked: true } });
    }

    if (e instanceof AccessDeniedError) {
      return navigate(getAccessDeniedPath());
    }

    setError(e);
  };

  const resetApplicationError = () => {
    setError(null);
  };

  return (
    <ErrorBoundaryContext.Provider value={{ error, setError: setApplicationError, resetError: resetApplicationError }}>
      {children}
    </ErrorBoundaryContext.Provider>
  );
};

export const useErrorBoundary = () => {
  return useContext(ErrorBoundaryContext);
};
