import { COLORS } from '@/styles/colors';
import { body, bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const Link = styled.a<{ variant: 'small' }>`
  ${poppinsMediumFont};
  color: ${COLORS.green[500]};
  text-decoration: none;
  ${body};

  ${({ variant }) =>
    variant === 'small' &&
    `
    ${bodySmall};
  `};
`;
