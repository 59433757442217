import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type BankNameResponseType = Array<{
  bank_name: string;
  routing_number: string;
}>;

export const getBankNameApi = (routingNumber: string): Promise<ApiResponseType<BankNameResponseType>> => {
  const uri = `/reference-books/banks/?routing_number=${routingNumber}`;

  return authApiService.GET(uri);
};
